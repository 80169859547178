import { MessageBarType } from '@fluentui/react'
import { createReducer } from 'typesafe-actions'
import {
  IFeeCategory,
  IFeeCharge,
  IFeeType,
  IFutureFeeCharge
} from '../api/types/feefilter.type'
import {
  IAssetLookupByRowResponse,
  IAssetLookupResponse,
  IEditOverrideResponse,
  IFeeAssignment,
  IFeeOverrideListItem,
  IFeeGroupListItem,
  // ITiredAumFeesForm,
  IGroupRow,
  IFeeAccount,
  IAccountRootModel,
  ITrustAssignmentListingModel
} from '../api/types/types'

import { IUpdateAccountFeeModel } from '../api/types/updateFeeType'
import {
  feeDataActions,
  feeFutureChargesActions,
  feeListActions,
  feePastChargesActions,
  FeesDataActionsTypes,
  feeTypeActions,
  feeFormActions,
  chargeFeeActions,
  exportScheduledFeeActions,
  feeCategoryTypeActions,
  setSelectedFeePlan,
  feeDashboardActions,
  setSelectedMonthYearActions,
  fetchAccrualsActions,
  exportFeePlansToExcelActions,
  pilotFetaureActions,
  UpdateAccrualsActions,
  viewInvoiceActions,
  generateInvoiceActions,
  closeMonthActions,
  getInvoicesActions,
  downloadD365ExcelActions,
  sendForCollectionActions,
  updateAmountActions,
  fetchInvoiceDetailItemsActions,
  feeGLStringActions,
  feeScheduleListActions,
  feeScheduleDataActions,
  insertUpdateFeeOverrideActions,
  fetchAssetSecurityDataActions,
  fetchAssetSecurityDataPerRowActions,
  fetchSecuritiesActions,
  fetchExistingOverrideByIdActions,
  insertUpdateFeeScheduleActions,
  fetchFeeOverridesDataActions,
  deleteFeeOverrideActions,
  fetchGroupByAccountIdActions,
  insertUpdateTiredAumFeesActions,
  fetchFeeAssignmentDataActions,
  fetchFeeGroupDataActions,
  deleteFeeGroupActions,
  insertUpdateFeeGroupActions,
  insertUpdateFeeBuildGroupActions,
  fetchExistingBuildGroupActions,
  deleteFeeAssignmentActions,
  fetchExistingTiredAumFeesActions,
  fetchAccountActions,
  getFlatFeeScheduleAction,
  updateFlatFeeScheduleAction,
  fetchTrustAssignmentDataActions,
  handleTrustActions,
  getAccountsForScheduleActions,
  feeBillingDashboardActions,
  setFeeBillingSelectedMonthYearActions,
  runBillingActions
} from './actions'
import { IFlatFeeScheduleModel } from './flatFeeScheduleTypes'
import {
  createNewFeeActions,
  fetchFeeAdvisorActions,
  fetchFeeBusinessSegmentTypeActions,
  fetchFeeCategoriesActions,
  fetchFeeCollectionTypeActions,
  fetchFeeFrequencyTypeActions,
  fetchAgencyFeeTypeActions,
  fetchFeeLegalEntitiesActions,
  fetchFeeServiceTypeActions,
  fetchInvoiceFeeTypeActions,
  updateNewFeeActions,
  fetchDepositAccountActions,
  fetchGLAccountActions
} from './scheduleFeeFormActions'
import {
  FeeDetailsItemsResponse,
  IFeeByProvider,
  IFeeDetails,
  IFeeError,
  IFeeInvoices,
  IFeeListDetail,
  IFeePlan,
  IFeeScheduleForm,
  IGLString,
  IInsertUpdateFeeBuildGroupRes,
  IPilotFeaturesList,
  MasterDataState,
  IFlagFeeSchedule,
  AgencyFeeTypeState,
  DepositAccountTypeState,
  IFeeBilling
} from './types'
import { IUpdateFeeScheduleResponseModel } from './updateFlatFeeScheduleTypes'
export interface IFeesState {
  scheduledFees: IFeeDetails[]
  fee: IFeeListDetail[]
  isFeeListLoaded: boolean
  feeTypes: IFeeType[]
  feeCategories: IFeeCategory[]
  error?: Error
  deleteFeeError?: string
  selectedFeeId?: string
  selectedFeeSchedule?: IFeeListDetail
  feestructureid?: string
  flagAvtive?: IFlagFeeSchedule
  feeFrom?: {
    error?: IFeeError
  }
  updateFeeState?: {
    inProgress: boolean
    error?: Error
    successMsg?: string
    status?: string
    funddetails?: IFeeByProvider
  }

  feeDetails?: {
    isLoaded: boolean
    data?: IFeeDetails
    error?: Error
  }

  pastCharges?: {
    charges?: IFeeCharge[]
    isLoaded: boolean
    error?: Error
  }

  flatFeeSchedule: {
    isLoading: boolean
    error?: Error
    data?: IFlatFeeScheduleModel[]
  }

  updateFlatFeeSchedule: {
    isLoading: boolean
    error?: Error
    data: IUpdateFeeScheduleResponseModel[]
  }

  updateFlatFeeScheduleValidation: {
    isLoading: boolean
    error?: Error
    data: IUpdateFeeScheduleResponseModel[]
  }

  futureCharges?: {
    charges?: IFutureFeeCharge[]
    isLoaded: boolean
    error?: Error
  }

  chargeFees?: {
    isLoaded: boolean
    error?: Error
  }

  exportScheduledFee?: {
    isLoaded?: boolean
    error?: Error
  }
  selectedFeePlan?: IFeePlan
  dashboardFees: {
    isLoading: boolean
    fees?: IFeePlan[]
    error?: Error
  }
  exportGwesReport?: {
    inProgress: boolean
    error?: Error
  }
  feeBillingDashboard: {
    isLoading: boolean
    feeBilling?: IFeeBilling
    error?: Error
  }
  runBillingState: {
    inProgress: boolean
    error?: Error
    successMsg?: string
  }
  selectedMonthYear?: string
  feeBillingSelectedMonthYear?: string
  accruals: {
    isLoading: boolean
    accruals?: IFeePlan[]
    error?: Error
  }
  exportFeePlan?: {
    inProgress: boolean
    error?: Error
  }
  pilotFeatureState?: {
    pilotFeatureList?: IPilotFeaturesList
    isPilotFeatureLoading?: boolean
  }
  updateAccruals?: {
    isLoading: boolean
    error?: Error
    isSuccess?: boolean
  }
  viewInvoice?: {
    inProgress: boolean
    error?: Error
  }
  generateInvoice?: {
    inProgress: boolean
    error?: Error
    isSuccess?: boolean
  }
  closeMonthState?: {
    inProgress: boolean
    error?: Error
    successMsg?: string
  }
  invoicesState?: {
    inProgress: boolean
    error?: Error
    invoices?: IFeeInvoices[]
  }
  downloadD365State?: {
    inProgress: boolean
    message?: string
    error?: Error
    messageBarType?: MessageBarType
  }
  sendForCollectionState?: {
    inProgress: boolean
    isSuccess?: boolean
    message?: string
    error?: Error
    messageBarType?: MessageBarType
  }
  updateAmountState?: {
    inProgress: boolean
    message?: string
    error?: Error
    messageBarType?: MessageBarType
  }
  fetchInvoiceDetailsItemsState?: {
    inProgress: boolean
    error?: Error
    details?: FeeDetailsItemsResponse
  }
  feeCategoriesState?: MasterDataState
  feeServiceTypeState?: MasterDataState
  feeInvoiceTypeState?: MasterDataState
  feeCollectionTypeState?: MasterDataState
  feeFrequencyTypeState?: MasterDataState
  feeAgencyTypeState?: AgencyFeeTypeState
  feeDepositAccountState?: DepositAccountTypeState
  feeBusinessSegmentTypeState?: MasterDataState
  feeAdvisorsState?: MasterDataState
  feeLegalEntitiesState?: MasterDataState
  glAccountState?: {
    glAccount: string
    index: number
    nestedIndex: number
    isNested: boolean
  }
  createFeeState?: {
    inProgress: boolean
    error?: Error
    message?: string
    messageType?: MessageBarType
    isSuccess?: boolean
  }
  feeGLStringState?: {
    isLoaded?: boolean
    data?: IGLString
    error?: Error
  }
  feeScheduleDetails?: {
    isLoaded: boolean
    data?: IFeeScheduleForm
    error?: Error
  }
  insertUpdateFeeOverrideState?: {
    inProgress: boolean
    error?: Error
    message?: string
    messageType?: MessageBarType
  }
  fetchAssetSecurityDataState?: {
    inProgress: boolean
    error?: Error
    data?: IAssetLookupResponse
  }
  fetchAssetSecurityDataByRowState?: IAssetLookupByRowResponse[]
  fetchSecuritiesState?: {
    inProgress: boolean
    error?: Error
    data?: IAssetLookupResponse
  }
  fetchOverrideDetailsById?: {
    inProgress: boolean
    error?: Error
    data?: IEditOverrideResponse
  }
  insertUpdateFeeScheduleState?: {
    inProgress: boolean
    error?: Error
    message?: string
    messageType?: MessageBarType
    feeData?: IFeeScheduleForm | null
  }
  fetchFeeOverrideListState?: {
    inProgress: boolean
    error?: Error
    data?: IFeeOverrideListItem[]
  }
  deleteOverrideState?: {
    inProgress: boolean
    error?: Error
  }
  fetchFeeAssignmentState?: {
    inProgress: boolean
    error?: Error
    data?: IFeeAssignment[]
  }
  fetchTrustCategoryAssignmentState?: {
    inProgress: boolean
    error?: Error
    data?: ITrustAssignmentListingModel[]
  }
  fetchTrustCategoryUpdateState?: {
    message?: string
    isSuccess?: boolean
    isError?: boolean
  }
  fetchTrustCategoryAssignmentExistState?: {
    inProgress: boolean
    error?: Error
    data?: ITrustAssignmentListingModel[]
  }
  deleteAssignmentState?: {
    inProgress: boolean
    error?: Error
  }
  fetchGroupByAccountId?: IGroupRow[]
  insertUpdateTiredAumFeesState?: {
    inProgress: boolean
    error?: Error
    message?: string
    messageType?: MessageBarType
    feedetails?: IUpdateAccountFeeModel | null
  }
  fetchFeeGroupListState?: {
    inProgress: boolean
    error?: Error
    data?: IFeeGroupListItem[]
  }
  deleteGroupState?: {
    inProgress: boolean
    error?: Error
  }
  insertUpdateFeeBuildGroupState?: {
    inProgress: boolean
    groupData?: IInsertUpdateFeeBuildGroupRes | null
    error?: Error
    message?: string
    messageType?: MessageBarType
  }
  fetchExistingFeeBuildGroupState?: {
    inProgress: boolean
    error?: Error
    data?: IInsertUpdateFeeBuildGroupRes
  }
  fetchExistingTiredAumFeesState?: {
    inProgress: boolean
    error?: Error
    data?: IAccountRootModel
  }
  accounts?: {
    inProgress?: boolean
    error?: Error
    data?: IFeeAccount[]
  }
  accountsForSchedule?: {
    inProgress?: boolean
    error?: Error
    data?: string[]
  }
}

const initialState: IFeesState = {
  scheduledFees: [],
  fee: [],
  feeTypes: [],
  feeCategories: [],
  isFeeListLoaded: false,
  dashboardFees: {
    isLoading: true,
    fees: []
  },
  feeBillingDashboard: {
    isLoading: true,
    feeBilling: undefined
  },
  runBillingState: {
    inProgress: false,
    error: undefined,
    successMsg: undefined
  },
  accruals: {
    isLoading: true,
    accruals: []
  },
  pilotFeatureState: {
    pilotFeatureList: undefined,
    isPilotFeatureLoading: undefined
  },
  generateInvoice: {
    inProgress: false,
    error: undefined,
    isSuccess: undefined
  },
  downloadD365State: {
    inProgress: false
  },
  sendForCollectionState: {
    inProgress: false
  },
  updateAmountState: {
    inProgress: false
  },
  flatFeeSchedule: {
    isLoading: true,
    data: undefined
  },
  updateFlatFeeSchedule: {
    isLoading: true,
    data: []
  },
  updateFlatFeeScheduleValidation: {
    isLoading: true,
    data: []
  }
}
export interface IFeeCommonState {
  updatePlanByProviderState?: {
    inProgress: boolean
    error?: Error
    successMsg?: string
    status?: string
    funddetails?: IFeeByProvider
  }
}
export const feesReducer = createReducer<IFeesState, FeesDataActionsTypes>(
  initialState
)
  .handleAction(feeListActions.request, (state) => ({
    ...state,
    isFeeListLoaded: true,
    error: undefined,
    chargeFees: undefined
  }))
  .handleAction(feeListActions.success, (state, action) => ({
    ...state,
    scheduledFees: action.payload,
    isFeeListLoaded: false
  }))
  .handleAction(feeListActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isFeeListLoaded: false
  }))
  .handleAction(feeListActions.setfeeid, (state, action) => ({
    ...state,
    selectedFeeId: action.payload
  }))
  .handleAction(feeTypeActions.success, (state, action) => ({
    ...state,
    feeTypes: action.payload
  }))
  .handleAction(feeCategoryTypeActions.success, (state, action) => ({
    ...state,
    feeCategories: action.payload
  }))
  .handleAction(feeDataActions.request, (state) => ({
    ...state,
    feeDetails: {
      ...state.feeDetails,
      isLoaded: false
    }
  }))
  .handleAction(feeDataActions.success, (state, action) => ({
    ...state,
    feeDetails: {
      ...state.feeDetails,
      isLoaded: true,
      data: action.payload
    }
  }))
  .handleAction(feeDataActions.failure, (state, action) => ({
    ...state,
    feeDetails: {
      ...state.feeDetails,
      isLoaded: true,
      data: undefined,
      error: action.payload
    }
  }))
  .handleAction(feeDataActions.reset, (state) => ({
    ...state,
    feeDetails: undefined,
    pastCharges: undefined,
    futureCharges: undefined,
    selectedFeeId: undefined
  }))
  .handleAction(feePastChargesActions.request, (state) => ({
    ...state,
    pastCharges: {
      ...state.pastCharges,
      isLoaded: false,
      error: undefined
    }
  }))
  .handleAction(feePastChargesActions.success, (state, action) => ({
    ...state,
    pastCharges: {
      ...state.pastCharges,
      isLoaded: true,
      charges: action.payload
    }
  }))
  .handleAction(feePastChargesActions.failure, (state, action) => ({
    ...state,
    pastCharges: {
      ...state.pastCharges,
      isLoaded: true,
      error: action.payload
    }
  }))
  .handleAction(feeFutureChargesActions.request, (state) => ({
    ...state,
    futureCharges: {
      ...state.futureCharges,
      isLoaded: false,
      error: undefined
    }
  }))
  .handleAction(feeFutureChargesActions.success, (state, action) => ({
    ...state,
    futureCharges: {
      ...state.futureCharges,
      isLoaded: true,
      charges: action.payload
    }
  }))
  .handleAction(feeFutureChargesActions.failure, (state, action) => ({
    ...state,
    futureCharges: {
      ...state.futureCharges,
      isLoaded: true,
      error: action.payload
    }
  }))
  .handleAction(feeFormActions.error, (state, action) => ({
    ...state,
    feeFrom: {
      ...state.feeFrom,
      error: action.payload
    }
  }))
  .handleAction(feeFormActions.addfee, (state) => ({
    ...state,
    feeFrom: {
      ...state.feeFrom,
      error: undefined
    }
  }))
  .handleAction(feeFormActions.reset, (state) => ({
    ...state,
    feeFrom: undefined
  }))
  .handleAction(chargeFeeActions.request, (state) => ({
    ...state,
    error: undefined,
    chargeFees: {
      ...state.chargeFees,
      error: undefined,
      isLoaded: false
    }
  }))
  .handleAction(chargeFeeActions.success, (state) => ({
    ...state,
    chargeFees: {
      ...state.chargeFees,
      error: undefined,
      isLoaded: true
    }
  }))
  .handleAction(chargeFeeActions.failure, (state, action) => ({
    ...state,
    chargeFees: {
      ...state.chargeFees,
      error: action.payload,
      isLoaded: true
    }
  }))
  .handleAction(exportScheduledFeeActions.request, (state) => ({
    ...state,
    chargeFees: undefined,
    error: undefined,
    exportScheduledFee: {
      isLoaded: false,
      error: undefined
    }
  }))
  .handleAction(exportScheduledFeeActions.success, (state) => ({
    ...state,
    chargeFees: undefined,
    error: undefined,
    exportScheduledFee: {
      isLoaded: true,
      error: undefined
    }
  }))
  .handleAction(exportScheduledFeeActions.failure, (state, action) => ({
    ...state,
    chargeFees: undefined,
    error: undefined,
    exportScheduledFee: {
      isLoaded: true,
      error: action.payload
    }
  }))
  .handleAction(setSelectedFeePlan.selectedFeePlan, (state, action) => ({
    ...state,
    selectedFeePlan: action.payload || undefined
  }))
  .handleAction(feeDashboardActions.request, (state) => ({
    ...state,
    dashboardFees: {
      ...state.dashboardFees,
      error: undefined,
      isLoading: true
    },
    closeMonthState: undefined,
    GenerateInvoice: undefined
  }))
  .handleAction(feeDashboardActions.success, (state, action) => ({
    ...state,
    dashboardFees: {
      ...state.dashboardFees,
      fees: action.payload || [],
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(feeDashboardActions.failure, (state, action) => ({
    ...state,
    dashboardFees: {
      ...state.dashboardFees,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(feeBillingDashboardActions.request, (state) => ({
    ...state,
    feeBillingDashboard: {
      ...state.feeBillingDashboard,
      error: undefined,
      isLoading: true
    }
  }))
  .handleAction(feeBillingDashboardActions.success, (state, action) => ({
    ...state,
    feeBillingDashboard: {
      ...state.feeBillingDashboard,
      feeBilling: action.payload || undefined,
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(feeBillingDashboardActions.failure, (state, action) => ({
    ...state,
    feeBillingDashboard: {
      ...state.feeBillingDashboard,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(runBillingActions.request, (state) => ({
    ...state,
    runBillingState: {
      inProgress: true,
      error: undefined,
      successMsg: undefined
    }
  }))
  .handleAction(runBillingActions.failure, (state, action) => ({
    ...state,
    runBillingState: {
      inProgress: false,
      error: action.payload,
      successMsg: undefined
    }
  }))
  .handleAction(runBillingActions.success, (state, action) => ({
    ...state,
    runBillingState: {
      inProgress: false,
      error: undefined,
      successMsg: action.payload || undefined
    }
  }))
  .handleAction(
    setSelectedMonthYearActions.selectedMonthYear,
    (state, action) => ({
      ...state,
      selectedMonthYear: action.payload || undefined,
      closeMonthState: undefined
    })
  )
  .handleAction(
    setFeeBillingSelectedMonthYearActions.FeeBillingSelectedMonthYear,
    (state, action) => ({
      ...state,
      feeBillingSelectedMonthYear: action.payload || undefined
    })
  )
  .handleAction(fetchAccrualsActions.request, (state) => ({
    ...state,
    accruals: {
      ...state.accruals,
      error: undefined,
      isLoading: true
    },
    closeMonthState: undefined,
    generateInvoice: undefined,
    updateAccruals: undefined
  }))
  .handleAction(fetchAccrualsActions.success, (state, action) => ({
    ...state,
    accruals: {
      ...state.accruals,
      accruals: action.payload || [],
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(fetchAccrualsActions.failure, (state, action) => ({
    ...state,
    accruals: {
      ...state.accruals,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(exportFeePlansToExcelActions.request, (state) => ({
    ...state,
    exportFeePlan: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(exportFeePlansToExcelActions.success, (state) => ({
    ...state,
    exportFeePlan: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(exportFeePlansToExcelActions.failure, (state, action) => ({
    ...state,
    exportFeePlan: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(pilotFetaureActions.request, (state) => ({
    ...state,
    pilotFeatureState: {
      isPilotFeatureLoading: true,
      dynamicsExportDetails: undefined
    }
  }))
  .handleAction(pilotFetaureActions.success, (state, action) => ({
    ...state,
    pilotFeatureState: {
      pilotFeatureList: action.payload,
      isPilotFeatureLoading: false
    }
  }))
  .handleAction(pilotFetaureActions.failure, (state, action) => ({
    ...state,
    pilotFeatureState: {
      error: action.payload,
      isPilotFeatureLoading: false
    }
  }))
  .handleAction(UpdateAccrualsActions.request, (state) => ({
    ...state,
    updateAccruals: {
      isLoading: true,
      error: undefined,
      isSuccess: undefined
    }
  }))
  .handleAction(UpdateAccrualsActions.success, (state, action) => ({
    ...state,
    updateAccruals: {
      isLoading: false,
      error: undefined,
      isSuccess: action.payload
    }
  }))
  .handleAction(UpdateAccrualsActions.failure, (state, action) => ({
    ...state,
    updateAccruals: {
      isLoading: false,
      error: action.payload,
      isSuccess: false
    }
  }))
  .handleAction(viewInvoiceActions.request, (state) => ({
    ...state,
    viewInvoice: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(viewInvoiceActions.success, (state) => ({
    ...state,
    viewInvoice: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(viewInvoiceActions.failure, (state, action) => ({
    ...state,
    viewInvoice: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(generateInvoiceActions.request, (state) => ({
    ...state,
    generateInvoice: {
      inProgress: true,
      error: undefined,
      isSuccess: undefined
    }
  }))
  .handleAction(generateInvoiceActions.success, (state, action) => ({
    ...state,
    generateInvoice: {
      inProgress: false,
      error: undefined,
      isSuccess: action.payload
    }
  }))
  .handleAction(generateInvoiceActions.failure, (state, action) => ({
    ...state,
    generateInvoice: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(generateInvoiceActions.clear, (state) => ({
    ...state,
    generateInvoice: undefined
  }))
  .handleAction(closeMonthActions.request, (state) => ({
    ...state,
    closeMonthState: {
      inProgress: true,
      error: undefined,
      successMsg: undefined
    }
  }))
  .handleAction(closeMonthActions.failure, (state, action) => ({
    ...state,
    closeMonthState: {
      inProgress: false,
      error: action.payload,
      successMsg: undefined
    }
  }))
  .handleAction(closeMonthActions.success, (state, action) => ({
    ...state,
    closeMonthState: {
      inProgress: false,
      error: undefined,
      successMsg: action.payload || undefined
    }
  }))
  .handleAction(getInvoicesActions.request, (state) => ({
    ...state,
    invoicesState: {
      ...state.invoicesState,
      error: undefined,
      inProgress: true
    }
  }))
  .handleAction(getInvoicesActions.success, (state, action) => ({
    ...state,
    invoicesState: {
      ...state.invoicesState,
      invoices: action.payload || [],
      error: undefined,
      inProgress: false
    }
  }))
  .handleAction(getInvoicesActions.failure, (state, action) => ({
    ...state,
    invoicesState: {
      ...state.invoicesState,
      error: action.payload,
      inProgress: false
    }
  }))
  .handleAction(downloadD365ExcelActions.request, (state) => ({
    ...state,
    downloadD365State: {
      inProgress: true,
      error: undefined,
      message: undefined,
      messageBarType: undefined
    }
  }))
  .handleAction(downloadD365ExcelActions.success, (state) => ({
    ...state,
    downloadD365State: {
      inProgress: false,
      error: undefined,
      message: 'D365 export has been initiated',
      messageBarType: MessageBarType.success
    }
  }))
  .handleAction(downloadD365ExcelActions.failure, (state, action) => ({
    ...state,
    downloadD365State: {
      inProgress: false,
      error: action.payload,
      message: 'Unable to export D365 report',
      messageBarType: MessageBarType.error
    }
  }))
  .handleAction(downloadD365ExcelActions.clear, (state) => ({
    ...state,
    downloadD365State: undefined
  }))
  .handleAction(sendForCollectionActions.request, (state) => ({
    ...state,
    sendForCollectionState: {
      inProgress: true,
      isSuccess: false,
      error: undefined,
      message: undefined,
      messageBarType: undefined
    }
  }))
  .handleAction(sendForCollectionActions.success, (state) => ({
    ...state,
    sendForCollectionState: {
      inProgress: false,
      isSuccess: true,
      error: undefined,
      message: 'Selected fees successfully send for collection.',
      messageBarType: MessageBarType.success
    }
  }))
  .handleAction(sendForCollectionActions.failure, (state, action) => ({
    ...state,
    sendForCollectionState: {
      inProgress: false,
      isSuccess: false,
      error: action.payload,
      message:
        'We are unable to send for collection, Please try after sometime.',
      messageBarType: MessageBarType.error
    }
  }))
  .handleAction(updateAmountActions.request, (state) => ({
    ...state,
    updateAmountState: {
      inProgress: true,
      error: undefined,
      message: undefined,
      messageBarType: undefined
    }
  }))
  .handleAction(updateAmountActions.success, (state, action) => ({
    ...state,
    updateAmountState: {
      inProgress: false,
      error: undefined,
      message: 'Amount Updated Successfully.',
      messageBarType: MessageBarType.success
    },
    scheduledFees: action.payload
  }))
  .handleAction(updateAmountActions.failure, (state, action) => ({
    ...state,
    updateAmountState: {
      inProgress: false,
      error: action.payload,
      message: 'Unable to update amount.',
      messageBarType: MessageBarType.error
    }
  }))
  .handleAction(updateAmountActions.clear, (state) => ({
    ...state,
    updateAmountState: {
      inProgress: false,
      error: undefined,
      message: undefined,
      messageBarType: undefined
    }
  }))
  .handleAction(fetchInvoiceDetailItemsActions.request, (state) => ({
    ...state,
    fetchInvoiceDetailsItemsState: {
      inProgress: true,
      error: undefined,
      details: undefined
    }
  }))
  .handleAction(fetchInvoiceDetailItemsActions.success, (state, action) => ({
    ...state,
    fetchInvoiceDetailsItemsState: {
      inProgress: false,
      error: undefined,
      details: action.payload
    }
  }))
  .handleAction(fetchInvoiceDetailItemsActions.failure, (state, action) => ({
    ...state,
    fetchInvoiceDetailsItemsState: {
      inProgress: false,
      error: action.payload,
      details: undefined
    }
  }))
  .handleAction(fetchFeeCategoriesActions.request, (state) => ({
    ...state,
    feeCategoriesState: {
      InProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchFeeCategoriesActions.success, (state, action) => ({
    ...state,
    feeCategoriesState: {
      InProgress: true,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchFeeCategoriesActions.failure, (state, action) => ({
    ...state,
    feeCategoriesState: {
      InProgress: true,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchFeeServiceTypeActions.request, (state) => ({
    ...state,
    feeServiceTypeState: {
      InProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchFeeServiceTypeActions.success, (state, action) => ({
    ...state,
    feeServiceTypeState: {
      InProgress: true,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchFeeServiceTypeActions.failure, (state, action) => ({
    ...state,
    feeServiceTypeState: {
      InProgress: true,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchInvoiceFeeTypeActions.request, (state) => ({
    ...state,
    feeInvoiceTypeState: {
      InProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchInvoiceFeeTypeActions.success, (state, action) => ({
    ...state,
    feeInvoiceTypeState: {
      InProgress: true,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchInvoiceFeeTypeActions.failure, (state, action) => ({
    ...state,
    feeInvoiceTypeState: {
      InProgress: true,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchFeeCollectionTypeActions.request, (state) => ({
    ...state,
    feeCollectionTypeState: {
      InProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchFeeCollectionTypeActions.success, (state, action) => ({
    ...state,
    feeCollectionTypeState: {
      InProgress: true,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchFeeCollectionTypeActions.failure, (state, action) => ({
    ...state,
    feeCollectionTypeState: {
      InProgress: true,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchFeeFrequencyTypeActions.request, (state) => ({
    ...state,
    feeFrequencyTypeState: {
      InProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchFeeFrequencyTypeActions.success, (state, action) => ({
    ...state,
    feeFrequencyTypeState: {
      InProgress: true,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchFeeFrequencyTypeActions.failure, (state, action) => ({
    ...state,
    feeFrequencyTypeState: {
      InProgress: true,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchGLAccountActions.request, (state) => ({
    ...state,
    glAccountState: undefined
  }))
  .handleAction(fetchGLAccountActions.success, (state, action) => ({
    ...state,
    glAccountState: {
      glAccount: action?.payload?.[0]?.glaccount,
      index: action?.payload?.[0]?.index,
      nestedIndex: action?.payload?.[0]?.nestedIndex,
      isNested: action?.payload?.[0]?.isNested
    }
  }))
  .handleAction(fetchGLAccountActions.failure, (state) => ({
    ...state,
    glAccountState: undefined
  }))
  .handleAction(fetchAgencyFeeTypeActions.request, (state) => ({
    ...state,
    feeAgencyTypeState: {
      InProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchAgencyFeeTypeActions.success, (state, action) => ({
    ...state,
    feeAgencyTypeState: {
      InProgress: true,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchAgencyFeeTypeActions.failure, (state, action) => ({
    ...state,
    feeAgencyTypeState: {
      InProgress: true,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchDepositAccountActions.request, (state) => ({
    ...state,
    feeDepositAccountState: {
      InProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchDepositAccountActions.success, (state, action) => ({
    ...state,
    feeDepositAccountState: {
      InProgress: true,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchDepositAccountActions.failure, (state, action) => ({
    ...state,
    feeDepositAccountState: {
      InProgress: true,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchFeeBusinessSegmentTypeActions.request, (state) => ({
    ...state,
    feeBusinessSegmentTypeState: {
      InProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(
    fetchFeeBusinessSegmentTypeActions.success,
    (state, action) => ({
      ...state,
      feeBusinessSegmentTypeState: {
        InProgress: true,
        error: undefined,
        data: action.payload
      }
    })
  )
  .handleAction(
    fetchFeeBusinessSegmentTypeActions.failure,
    (state, action) => ({
      ...state,
      feeBusinessSegmentTypeState: {
        InProgress: true,
        error: action.payload,
        data: undefined
      }
    })
  )
  .handleAction(fetchFeeAdvisorActions.request, (state) => ({
    ...state,
    feeAdvisorsState: {
      InProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchFeeAdvisorActions.success, (state, action) => ({
    ...state,
    feeAdvisorsState: {
      InProgress: true,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchFeeAdvisorActions.failure, (state, action) => ({
    ...state,
    feeAdvisorsState: {
      InProgress: true,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchFeeLegalEntitiesActions.request, (state) => ({
    ...state,
    feeLegalEntitiesState: {
      InProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchFeeLegalEntitiesActions.success, (state, action) => ({
    ...state,
    feeLegalEntitiesState: {
      InProgress: true,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchFeeLegalEntitiesActions.failure, (state, action) => ({
    ...state,
    feeLegalEntitiesState: {
      InProgress: true,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(createNewFeeActions.request, (state) => ({
    ...state,
    createFeeState: {
      inProgress: true,
      error: undefined,
      isSuccess: undefined,
      message: undefined,
      messageType: undefined
    }
  }))
  .handleAction(createNewFeeActions.success, (state) => ({
    ...state,
    createFeeState: {
      inProgress: false,
      error: undefined,
      isSuccess: true,
      message: 'Fee has been scheduled successfully.',
      messageType: MessageBarType.success
    }
  }))
  .handleAction(createNewFeeActions.failure, (state, action) => ({
    ...state,
    createFeeState: {
      inProgress: false,
      error: action.payload,
      isSuccess: false,
      message: 'Unable to schedule a fee.',
      messageType: MessageBarType.error
    }
  }))
  .handleAction(createNewFeeActions.clear, (state) => ({
    ...state,
    createFeeState: undefined
  }))
  .handleAction(updateNewFeeActions.request, (state) => ({
    ...state,
    createFeeState: {
      inProgress: true,
      error: undefined,
      isSuccess: undefined,
      message: undefined,
      messageType: undefined
    }
  }))
  .handleAction(updateNewFeeActions.success, (state) => ({
    ...state,
    createFeeState: {
      inProgress: false,
      error: undefined,
      isSuccess: true,
      message: 'Fee has been scheduled successfully.',
      messageType: MessageBarType.success
    }
  }))
  .handleAction(updateNewFeeActions.failure, (state, action) => ({
    ...state,
    createFeeState: {
      inProgress: false,
      error: action.payload,
      isSuccess: false,
      message: 'Unable to schedule a fee.',
      messageType: MessageBarType.error
    }
  }))
  .handleAction(updateNewFeeActions.clear, (state) => ({
    ...state,
    createFeeState: undefined
  }))
  .handleAction(feeGLStringActions.request, (state) => ({
    ...state,
    feeGLStringState: {
      InProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(feeGLStringActions.success, (state, action) => ({
    ...state,
    feeGLStringState: {
      InProgress: true,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(feeGLStringActions.failure, (state, action) => ({
    ...state,
    feeGLStringState: {
      InProgress: true,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(feeGLStringActions.clear, (state) => ({
    ...state,
    feeGLStringState: undefined
  }))
  .handleAction(feeScheduleListActions.request, (state) => ({
    ...state,
    isFeeListLoaded: true,
    error: undefined,
    chargeFees: undefined
  }))
  .handleAction(feeScheduleListActions.success, (state, action) => ({
    ...state,
    fee: action.payload,
    isFeeListLoaded: false
  }))
  .handleAction(feeScheduleListActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isFeeListLoaded: false
  }))
  .handleAction(feeScheduleListActions.deleteFailure, (state, action) => ({
    ...state,
    isFeeListLoaded: false,
    deleteFeeError: action.payload
  }))
  .handleAction(feeScheduleListActions.setfeeschedule, (state, action) => ({
    ...state,
    selectedFeeSchedule: action.payload
  }))
  .handleAction(feeScheduleListActions.updatefeeschedule, (state, action) => ({
    ...state,
    flagAvtive: action.payload
  }))
  .handleAction(feeScheduleDataActions.request, (state) => ({
    ...state,
    feeScheduleDetails: {
      ...state.feeScheduleDetails,
      isLoaded: false
    }
  }))
  .handleAction(feeScheduleDataActions.success, (state, action) => ({
    ...state,
    feeScheduleDetails: {
      ...state.feeScheduleDetails,
      isLoaded: true,
      data: action.payload
    }
  }))
  .handleAction(feeScheduleDataActions.failure, (state, action) => ({
    ...state,
    feeScheduleDetails: {
      ...state.feeScheduleDetails,
      isLoaded: true,
      data: undefined,
      error: action.payload
    }
  }))
  .handleAction(feeScheduleDataActions.reset, (state) => ({
    ...state,
    feeScheduleDetails: undefined,
    pastCharges: undefined,
    futureCharges: undefined,
    selectedFeeSchedule: undefined
  }))
  .handleAction(insertUpdateFeeOverrideActions.request, (state) => ({
    ...state,
    insertUpdateFeeOverrideState: {
      inProgress: true,
      error: undefined,
      message: undefined,
      messageType: undefined
    }
  }))
  .handleAction(insertUpdateFeeOverrideActions.success, (state) => ({
    ...state,
    insertUpdateFeeOverrideState: {
      inProgress: false,
      error: undefined,
      message: 'Successfully inserted/updated fee override.',
      messageType: MessageBarType.success
    }
  }))
  .handleAction(insertUpdateFeeOverrideActions.failure, (state, action) => ({
    ...state,
    insertUpdateFeeOverrideState: {
      inProgress: false,
      error: action.payload,
      message: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(insertUpdateFeeOverrideActions.clear, (state) => ({
    ...state,
    insertUpdateFeeOverrideState: undefined
  }))
  .handleAction(fetchAssetSecurityDataActions.request, (state) => ({
    ...state,
    fetchAssetSecurityDataState: {
      ...state.fetchAssetSecurityDataState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(fetchAssetSecurityDataActions.success, (state, action) => ({
    ...state,
    fetchAssetSecurityDataState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchAssetSecurityDataActions.failure, (state, action) => ({
    ...state,
    fetchAssetSecurityDataState: {
      ...state.fetchAssetSecurityDataState,
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(fetchAssetSecurityDataActions.clear, (state) => ({
    ...state,
    fetchAssetSecurityDataState: undefined
  }))
  .handleAction(
    fetchAssetSecurityDataPerRowActions.success,
    (state, action) => ({
      ...state,
      fetchAssetSecurityDataByRowState: action.payload
    })
  )
  .handleAction(fetchAssetSecurityDataPerRowActions.clear, (state) => ({
    ...state,
    fetchAssetSecurityDataByRowState: undefined
  }))
  .handleAction(fetchSecuritiesActions.request, (state) => ({
    ...state,
    fetchSecuritiesState: {
      data: undefined,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(fetchSecuritiesActions.success, (state, action) => ({
    ...state,
    fetchSecuritiesState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchSecuritiesActions.failure, (state, action) => ({
    ...state,
    fetchSecuritiesState: {
      data: undefined,
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(fetchSecuritiesActions.clear, (state) => ({
    ...state,
    fetchSecuritiesState: undefined
  }))
  .handleAction(fetchExistingOverrideByIdActions.request, (state) => ({
    ...state,
    fetchOverrideDetailsById: {
      data: undefined,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(fetchExistingOverrideByIdActions.success, (state, action) => ({
    ...state,
    fetchOverrideDetailsById: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchExistingOverrideByIdActions.failure, (state, action) => ({
    ...state,
    fetchOverrideDetailsById: {
      data: undefined,
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(fetchExistingOverrideByIdActions.clear, (state) => ({
    ...state,
    fetchOverrideDetailsById: undefined
  }))
  .handleAction(insertUpdateFeeScheduleActions.request, (state) => ({
    ...state,
    insertUpdateFeeScheduleState: {
      inProgress: true,
      error: undefined,
      message: undefined,
      messageType: undefined
    }
  }))
  .handleAction(insertUpdateFeeScheduleActions.success, (state, action) => ({
    ...state,
    insertUpdateFeeScheduleState: {
      inProgress: false,
      error: undefined,
      message: 'Successfully inserted/updated fee schedule.',
      messageType: MessageBarType.success,
      feeData: action.payload
    }
  }))
  .handleAction(insertUpdateFeeScheduleActions.failure, (state, action) => ({
    ...state,
    insertUpdateFeeScheduleState: {
      inProgress: false,
      error: action.payload,
      message:
        'Unable to insert/update fee schedule. Please try after sometime.',
      messageType: MessageBarType.error
    }
  }))
  .handleAction(insertUpdateFeeScheduleActions.clear, (state) => ({
    ...state,
    insertUpdateFeeScheduleState: undefined
  }))
  .handleAction(fetchFeeOverridesDataActions.request, (state) => ({
    ...state,
    fetchFeeOverrideListState: {
      data: undefined,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(fetchFeeOverridesDataActions.success, (state, action) => ({
    ...state,
    fetchFeeOverrideListState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchFeeOverridesDataActions.failure, (state, action) => ({
    ...state,
    fetchFeeOverrideListState: {
      data: undefined,
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(fetchFeeOverridesDataActions.clear, (state) => ({
    ...state,
    fetchFeeOverrideListState: undefined
  }))
  .handleAction(deleteFeeOverrideActions.request, (state) => ({
    ...state,
    deleteOverrideState: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(deleteFeeOverrideActions.success, (state) => ({
    ...state,
    deleteOverrideState: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(deleteFeeOverrideActions.failure, (state, action) => ({
    ...state,
    deleteOverrideState: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(deleteFeeOverrideActions.clear, (state) => ({
    ...state,
    deleteOverrideState: undefined
  }))
  .handleAction(fetchGroupByAccountIdActions.request, (state, action) => {
    const shallowCopy = [...(state.fetchGroupByAccountId || [])]
    const matchedIdx = shallowCopy.findIndex(
      (x) => x.rowId === action.payload.rowId
    )
    const newRow: IGroupRow = {
      inProgress: true,
      rowId: action.payload.rowId,
      data: undefined,
      error: undefined
    }
    if (matchedIdx > -1) {
      shallowCopy.splice(matchedIdx, 1, newRow)
    } else {
      shallowCopy.push(newRow)
    }

    return {
      ...state,
      fetchGroupByAccountId: shallowCopy
    }
  })
  .handleAction(fetchGroupByAccountIdActions.success, (state, action) => ({
    ...state,
    fetchGroupByAccountId: action.payload
  }))
  .handleAction(fetchGroupByAccountIdActions.failure, (state) => ({
    ...state,
    fetchGroupByAccountId: []
  }))
  .handleAction(fetchGroupByAccountIdActions.clear, (state) => ({
    ...state,
    fetchGroupByAccountId: undefined
  }))
  .handleAction(insertUpdateTiredAumFeesActions.request, (state) => ({
    ...state,
    insertUpdateTiredAumFeesState: {
      inProgress: true,
      error: undefined,
      message: undefined,
      messageType: undefined
    }
  }))
  .handleAction(insertUpdateTiredAumFeesActions.success, (state, action) => ({
    ...state,
    insertUpdateTiredAumFeesState: {
      inProgress: false,
      error: undefined,
      message: 'Successfully inserted/updated fee override.',
      messageType: MessageBarType.success,
      feedetails: action.payload
    }
  }))
  .handleAction(insertUpdateTiredAumFeesActions.failure, (state, action) => ({
    ...state,
    insertUpdateTiredAumFeesState: {
      inProgress: false,
      error: action.payload,
      message: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(insertUpdateTiredAumFeesActions.clear, (state) => ({
    ...state,
    insertUpdateTiredAumFeesState: undefined
  }))
  .handleAction(fetchFeeAssignmentDataActions.request, (state) => ({
    ...state,
    fetchFeeAssignmentState: {
      data: undefined,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(fetchFeeAssignmentDataActions.success, (state, action) => ({
    ...state,
    fetchFeeAssignmentState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchFeeAssignmentDataActions.failure, (state, action) => ({
    ...state,
    fetchFeeAssignmentState: {
      data: undefined,
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(fetchFeeAssignmentDataActions.clear, (state) => ({
    ...state,
    fetchFeeAssignmentState: undefined
  }))

  .handleAction(fetchTrustAssignmentDataActions.trustExist, (state) => ({
    ...state,
    fetchTrustCategoryAssignmentExistState: {
      data: undefined,
      inProgress: true,
      error: undefined
    }
  }))

  .handleAction(
    fetchTrustAssignmentDataActions.isTrustExistSuccess,
    (state, action) => ({
      ...state,
      fetchTrustCategoryAssignmentExistState: {
        inProgress: false,
        error: undefined,
        // error:
        //   action.payload?.length > 0
        //     ? 'Trust category already assigned'
        //     : undefined,
        data: action.payload
      }
    })
  )

  .handleAction(fetchTrustAssignmentDataActions.isTrustExistClear, (state) => ({
    ...state,
    fetchTrustCategoryAssignmentExistState: {
      inProgress: false,
      error: undefined,
      data: undefined
    }
  }))

  .handleAction(fetchTrustAssignmentDataActions.request, (state) => ({
    ...state,
    fetchTrustCategoryAssignmentState: {
      data: undefined,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(fetchTrustAssignmentDataActions.success, (state, action) => ({
    ...state,
    fetchTrustCategoryAssignmentState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchTrustAssignmentDataActions.failure, (state, action) => ({
    ...state,
    fetchTrustCategoryAssignmentState: {
      data: undefined,
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(fetchTrustAssignmentDataActions.clear, (state) => ({
    ...state,
    fetchTrustCategoryAssignmentState: undefined
  }))

  .handleAction(handleTrustActions.request, (state) => ({
    ...state,
    fetchTrustCategoryUpdateState: undefined
  }))
  .handleAction(handleTrustActions.clear, (state) => ({
    ...state,
    fetchTrustCategoryUpdateState: undefined
  }))
  .handleAction(handleTrustActions.success, (state, action) => ({
    ...state,
    fetchTrustCategoryUpdateState: {
      message: action.payload.message, // 'Trust category assigned',
      isSuccess: true,
      isError: false
    }
  }))
  .handleAction(handleTrustActions.failure, (state) => ({
    ...state,
    fetchTrustCategoryUpdateState: {
      message: 'something went wrong, please try again later',
      isSuccess: true,
      isError: true
    }
  }))

  .handleAction(fetchFeeGroupDataActions.request, (state) => ({
    ...state,
    fetchFeeGroupListState: {
      data: undefined,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(fetchFeeGroupDataActions.success, (state, action) => ({
    ...state,
    fetchFeeGroupListState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchFeeGroupDataActions.failure, (state) => ({
    ...state,
    fetchFeeGroupListState: {
      data: undefined,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(deleteFeeAssignmentActions.request, (state) => ({
    ...state,
    deleteAssignmentState: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(deleteFeeAssignmentActions.success, (state) => ({
    ...state,
    deleteAssignmentState: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(deleteFeeAssignmentActions.failure, (state, action) => ({
    ...state,
    deleteAssignmentState: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(deleteFeeAssignmentActions.clear, (state) => ({
    ...state,
    deleteAssignmentState: undefined
  }))
  .handleAction(fetchFeeGroupDataActions.clear, (state) => ({
    ...state,
    fetchFeeGroupListState: undefined
  }))
  .handleAction(deleteFeeGroupActions.request, (state) => ({
    ...state,
    deleteGroupState: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(deleteFeeGroupActions.success, (state) => ({
    ...state,
    deleteGroupState: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(deleteFeeGroupActions.failure, (state, action) => ({
    ...state,
    deleteGroupState: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(deleteFeeGroupActions.clear, (state) => ({
    ...state,
    deleteGroupState: undefined
  }))
  .handleAction(insertUpdateFeeGroupActions.request, (state) => ({
    ...state,
    insertUpdateFeeGroupState: {
      inProgress: true,
      error: undefined,
      message: undefined,
      messageType: undefined
    }
  }))
  .handleAction(insertUpdateFeeGroupActions.success, (state) => ({
    ...state,
    insertUpdateFeeGroupState: {
      inProgress: false,
      error: undefined,
      message: 'Successfully inserted/updated fee override.',
      messageType: MessageBarType.success
    }
  }))
  .handleAction(insertUpdateFeeGroupActions.failure, (state, action) => ({
    ...state,
    insertUpdateFeeGroupState: {
      inProgress: false,
      error: action.payload,
      message:
        'Unable to insert/update fee override. Please try after sometime.',
      messageType: MessageBarType.error
    }
  }))
  .handleAction(insertUpdateFeeGroupActions.clear, (state) => ({
    ...state,
    insertUpdateFeeGroupState: undefined
  }))
  .handleAction(insertUpdateFeeBuildGroupActions.request, (state) => ({
    ...state,
    insertUpdateFeeBuildGroupState: {
      inProgress: true,
      error: undefined,
      message: undefined,
      messageType: undefined
    }
  }))
  .handleAction(insertUpdateFeeBuildGroupActions.success, (state, action) => ({
    ...state,
    insertUpdateFeeBuildGroupState: {
      inProgress: false,
      error: undefined,
      groupData: action.payload,
      message: 'Successfully inserted/updated fee build group.',
      messageType: MessageBarType.success
    }
  }))
  .handleAction(insertUpdateFeeBuildGroupActions.failure, (state, action) => ({
    ...state,
    insertUpdateFeeBuildGroupState: {
      inProgress: false,
      error: action.payload,
      message: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(insertUpdateFeeBuildGroupActions.clear, (state) => ({
    ...state,
    insertUpdateFeeBuildGroupState: undefined
  }))
  .handleAction(fetchExistingBuildGroupActions.request, (state) => ({
    ...state,
    fetchExistingFeeBuildGroupState: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchExistingBuildGroupActions.success, (state, action) => ({
    ...state,
    fetchExistingFeeBuildGroupState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchExistingBuildGroupActions.failure, (state, action) => ({
    ...state,
    fetchExistingFeeBuildGroupState: {
      inProgress: false,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchExistingBuildGroupActions.clear, (state) => ({
    ...state,
    fetchExistingFeeBuildGroupState: undefined
  }))
  .handleAction(fetchExistingTiredAumFeesActions.request, (state) => ({
    ...state,
    fetchExistingTiredAumFeesState: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchExistingTiredAumFeesActions.success, (state, action) => ({
    ...state,
    fetchExistingTiredAumFeesState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchExistingTiredAumFeesActions.failure, (state, action) => ({
    ...state,
    fetchExistingTiredAumFeesState: {
      inProgress: false,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchExistingTiredAumFeesActions.clear, (state) => ({
    ...state,
    fetchExistingTiredAumFeesState: undefined
  }))
  .handleAction(fetchAccountActions.request, (state) => ({
    ...state,
    accounts: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchAccountActions.success, (state, action) => ({
    ...state,
    accounts: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchAccountActions.failure, (state, action) => ({
    ...state,
    accounts: {
      inProgress: false,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchAccountActions.clear, (state) => ({
    ...state,
    accounts: undefined
  }))
  .handleAction(getFlatFeeScheduleAction.request, (state) => ({
    ...state,
    flatFeeSchedule: {
      isLoading: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(getFlatFeeScheduleAction.success, (state, action) => ({
    ...state,
    flatFeeSchedule: {
      isLoading: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(getFlatFeeScheduleAction.failure, (state, action) => ({
    ...state,
    flatFeeSchedule: {
      isLoading: false,
      error: action.payload.error,
      data: undefined
    }
  }))
  .handleAction(getFlatFeeScheduleAction.clear, (state) => ({
    ...state,
    flatFeeSchedule: {
      isLoading: false,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(updateFlatFeeScheduleAction.request, (state) => ({
    ...state,
    updateFlatFeeSchedule: {
      isLoading: true,
      error: undefined,
      data: []
    }
  }))

  .handleAction(updateFlatFeeScheduleAction.success, (state, action) => ({
    ...state,
    updateFlatFeeSchedule: {
      isLoading: false,
      error: undefined,
      data: action.payload
    }
  }))

  .handleAction(updateFlatFeeScheduleAction.failure, (state, action) => ({
    ...state,
    updateFlatFeeSchedule: {
      isLoading: false,
      error: action.payload.error,
      data: []
    }
  }))

  .handleAction(updateFlatFeeScheduleAction.clear, (state) => ({
    ...state,
    updateFlatFeeSchedule: {
      isLoading: false,
      error: undefined,
      data: []
    },
    updateFlatFeeScheduleValidation: {
      isLoading: false,
      error: undefined,
      data: []
    }
  }))

  .handleAction(
    updateFlatFeeScheduleAction.validationError,
    (state, action) => ({
      ...state,
      updateFlatFeeScheduleValidation: {
        isLoading: false,
        error: undefined,
        data: action.payload
      }
    })
  )

  .handleAction(getAccountsForScheduleActions.success, (state, action) => ({
    ...state,
    accountsForSchedule: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(getAccountsForScheduleActions.failure, (state, action) => ({
    ...state,
    accountsForSchedule: {
      inProgress: false,
      error: action.payload.error,
      data: undefined
    }
  }))
  .handleAction(getAccountsForScheduleActions.clear, (state) => ({
    ...state,
    accountsForSchedule: undefined
  }))
