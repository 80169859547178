import { combineReducers } from 'redux'
import { accountSelectorFeatureReducer } from './AccountSelector'
import { deleteCustomGroupReducer } from './AccountSelector/features/DeleteCustomAccountGroup'
import { incomeFeatureReducer } from './Income'
import { manageAccountNicknamesFeatureReducer } from './ManageAccountNicknames/'
import { revenueDetailFilterReducer } from './Revenue/store'
import { sidePanelFeatureReducer } from './SidePanel'

export const rdot360FeaturesReducer = combineReducers({
  accountSelector: accountSelectorFeatureReducer,
  sidePanel: sidePanelFeatureReducer,
  income: incomeFeatureReducer,
  deleteCustomGroup: deleteCustomGroupReducer,
  manageAccountNicknames: manageAccountNicknamesFeatureReducer,
  revenueDetailFilter: revenueDetailFilterReducer
})
