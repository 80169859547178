import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import {
  DashboardPreference,
  IPaymentHubExportResponse,
  PaymentListResponse
} from '../store/types'

export const fetchPaymentList = (
  params: {
    role: string
    fromDate?: string
    toDate?: string
    useTrnDate?: string
    recurringPlan?: string
  },
  options: IApiOptions
) => {
  let url = `${options.apiRoot}/payment/api/payment/GetPaymentShortList?role=${params.role}&recurringPlan=${params.recurringPlan}`
  if (params?.fromDate) {
    url =
      url +
      `&useTrnDate=${params.useTrnDate || 'N'}&useDateRange=Y&fromDate=${
        params?.fromDate
      }&toDate=${params?.toDate}`
  }
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<PaymentListResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchDetailsByID = (
  MMInstId: string,

  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/GetPaymentList?tranIDFilter=${MMInstId}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<PaymentListResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchPaymentExcelService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/payment/getPaymentTransactions`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IPaymentHubExportResponse>(url, ['.'], {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res)
}

export const fetchPreference = (options: IApiOptions) => {
  const url = `${options.apiRoot}/homeoffice/api/UserConfig/Get`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const savePreference = (
  options: IApiOptions,
  payload: DashboardPreference
) => {
  const url = `${options.apiRoot}/homeoffice/api/UserConfig/save`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post(url, payload, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
