import { push } from 'connected-react-router'
import {
  takeLatest,
  put,
  call,
  select,
  delay,
  takeEvery,
  take,
  race,
  all
} from 'typed-redux-saga'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { getRdotUsername } from '../../../../../store/user/selectors'
import {
  fetchTrustDiscretionaryDistributionInfoSvc,
  submitTrustDiscretionaryDistributionInfoSvc,
  fetchTrustDiscretionaryDistributionEditSvc,
  fetchDiscretionaryDistributionDocListSvc,
  createDocumentForDistribution,
  createDocuSignEnvelop,
  updateDiscritionaryDistributionStatus,
  uploadAdditionalDistributionDocs,
  deleteAdditionalDocument,
  fetchCRMTrustDiscretionaryDistributionInfoSvc
} from '../api/newdistributionService'
import {
  ITrustDiscretionaryDistributionDoc,
  ITrustDiscretionaryDistributionEditInfoResponse
} from '../api/types'
import { mapDistributionEditInfoToTrustDistrbution } from '../utilities/Utilities'
import {
  discretionaryDistributionInfoDataActions,
  discretionaryDistributionSubmitActions,
  discretionaryDistributionEditActions,
  discretionaryDistributionDocActions,
  uploadAdditionalDocActions,
  deleteAdditionalDocActions,
  discretionaryDistributionInfoLegacyDataActions,
  discretionaryDistributionInfoCRMDataActions
} from './actions'

function* fetchDiscretionaryDistributionLegacy(
  action: ReturnType<
    typeof discretionaryDistributionInfoLegacyDataActions.request
  >
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchTrustDiscretionaryDistributionInfoSvc(action.payload, options)
    )
    if (data?.response_status === 0 && data?.response_data?.[0]) {
      data.response_data[0].entityNumber = action.payload
      yield put(
        discretionaryDistributionInfoLegacyDataActions.success(
          data?.response_data[0]
        )
      )
    } else {
      yield put(
        discretionaryDistributionInfoLegacyDataActions.failure(
          new Error('Unable to fetch trust discretionary distribution info')
        )
      )
    }
  } catch (err: any) {
    yield put(discretionaryDistributionInfoLegacyDataActions.failure(err))
  }
}

function* fetchDiscretionaryDistributionCrm(
  action: ReturnType<typeof discretionaryDistributionInfoCRMDataActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchCRMTrustDiscretionaryDistributionInfoSvc(action.payload, options)
    )
    if (data?.response_status === 0 && data?.response_data?.[0]) {
      data.response_data[0].entityNumber = action.payload
      yield put(
        discretionaryDistributionInfoCRMDataActions.success(
          data?.response_data[0]
        )
      )
    } else {
      yield put(
        discretionaryDistributionInfoCRMDataActions.failure(
          new Error('Unable to fetch trust discretionary distribution info')
        )
      )
    }
  } catch (err: any) {
    yield put(discretionaryDistributionInfoCRMDataActions.failure(err))
  }
}

function* handleFetchDistributionInfo(
  action: ReturnType<typeof discretionaryDistributionInfoDataActions.request>
) {
  try {
    yield put(
      discretionaryDistributionInfoLegacyDataActions.request(action.payload)
    )
    yield put(
      discretionaryDistributionInfoCRMDataActions.request(action.payload)
    )

    const { legacy, crm } = yield* all({
      legacy: race({
        legacyApiSuccess: take(
          discretionaryDistributionInfoLegacyDataActions.success
        ),
        legacyError: take(
          discretionaryDistributionInfoLegacyDataActions.failure
        )
      }),

      crm: race({
        crmApiSuccess: take(
          discretionaryDistributionInfoCRMDataActions.success
        ),
        crmdataError: take(discretionaryDistributionInfoCRMDataActions.failure)
      })
    })

    if (legacy?.legacyApiSuccess?.payload) {
      yield put(
        discretionaryDistributionInfoDataActions.success(
          legacy.legacyApiSuccess.payload
        )
      )
    } else if (crm?.crmApiSuccess?.payload) {
      yield put(
        discretionaryDistributionInfoDataActions.success(
          crm?.crmApiSuccess.payload
        )
      )
    } else {
      yield put(
        discretionaryDistributionInfoDataActions.failure(
          new Error('Unable to fetch trust discretionary distribution info')
        )
      )
    }
  } catch (e: any) {
    yield put(discretionaryDistributionInfoDataActions.failure(e))
  }
}

function* handleSubmitDiscretionaryDistribution(
  action: ReturnType<typeof discretionaryDistributionSubmitActions.request>
) {
  try {
    yield put(discretionaryDistributionSubmitActions.statusChange(-1))
    const options = yield* call(getRockefellerApiOptions)
    const email = yield* select(getRdotUsername)
    const payload = action.payload
    if (email && payload?.DiscritionDistribution) {
      payload.DiscritionDistribution.RequestorEmail = email
    }
    //check at least one approver should be present
    const includedApprovers =
      payload?.DiscritionDistribution?.Approvers?.filter(
        (a) => a.IndInclude === 'Y'
      )
    const isApproverIncluded =
      includedApprovers && includedApprovers.length > 0 ? true : false
    if (!isApproverIncluded) {
      throw new Error('At least one approver is required')
    }

    const data = yield* call(
      submitTrustDiscretionaryDistributionInfoSvc,
      payload,
      options
    )

    yield put(discretionaryDistributionSubmitActions.statusChange(1))
    const distributionId = data
    if (payload?.IsSubmit) {
      try {
        yield call(createDocumentForDistribution, distributionId, options)
        yield put(discretionaryDistributionSubmitActions.statusChange(2))
        try {
          yield call(createDocuSignEnvelop, data, options)
          yield put(discretionaryDistributionSubmitActions.statusChange(3))
          try {
            yield call(
              updateDiscritionaryDistributionStatus,
              {
                id: distributionId,
                status: 'PND'
              },
              options
            )

            yield put(discretionaryDistributionSubmitActions.statusChange(4))
          } catch (e) {
            yield put(
              discretionaryDistributionSubmitActions.failure(
                new Error(
                  'Failed to updated status, record has been created you can update later.'
                )
              )
            )
          }
        } catch (e) {
          yield put(
            discretionaryDistributionSubmitActions.failure(
              new Error('Failed to generate docsign envelopment')
            )
          )
        }
      } catch (e) {
        yield put(
          discretionaryDistributionSubmitActions.failure(
            new Error('Failed to create document')
          )
        )
      }
      yield delay(1000)
      yield put(discretionaryDistributionInfoDataActions.clear())
      yield put(push('/trusts'))
    } else {
      yield put(discretionaryDistributionSubmitActions.statusChange(4))
      yield delay(1000)
      yield put(discretionaryDistributionSubmitActions.success(data))
    }
  } catch (e: any) {
    yield put(discretionaryDistributionSubmitActions.failure(e))
  }
}

function* handleFetchDistributionDocs(
  action: ReturnType<typeof discretionaryDistributionDocActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: ITrustDiscretionaryDistributionDoc[] = yield call(() =>
      fetchDiscretionaryDistributionDocListSvc(action.payload, options)
    )
    yield put(discretionaryDistributionDocActions.success(data))
  } catch (e: any) {
    yield put(discretionaryDistributionDocActions.failure(e))
  }
}

function* handleFetchDistributionInfoEdit(
  action: ReturnType<typeof discretionaryDistributionEditActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: ITrustDiscretionaryDistributionEditInfoResponse = yield call(
      () => fetchTrustDiscretionaryDistributionEditSvc(action.payload, options)
    )
    yield put(
      discretionaryDistributionEditActions.success(
        mapDistributionEditInfoToTrustDistrbution(data)
      )
    )
  } catch (e: any) {
    yield put(discretionaryDistributionEditActions.failure(e))
  }
}

function* handleUploadAdditionalDocs(
  action: ReturnType<typeof uploadAdditionalDocActions.request>
) {
  const {
    distributionId,
    file,
    onUploadFail,
    onUploadSuccess,
    onUploadProgress
  } = action.payload
  try {
    const options = yield* call(getRockefellerApiOptions)
    const email = yield* select(getRdotUsername)

    if (!email) {
      throw new Error('Invalid State: email is undefined')
    }

    yield call(
      uploadAdditionalDistributionDocs,
      file,
      email,
      distributionId,
      options,
      onUploadProgress
    )

    onUploadSuccess(file)
  } catch (e) {
    onUploadFail(file)
  }
}

function* handleAdditionalDocument(
  action: ReturnType<typeof deleteAdditionalDocActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    yield call(() => deleteAdditionalDocument(action.payload, options))
    yield put(deleteAdditionalDocActions.success(action.payload))
  } catch (e: any) {
    yield put(deleteAdditionalDocActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(
      discretionaryDistributionInfoDataActions.request,
      handleFetchDistributionInfo
    ),
  () =>
    takeLatest(
      discretionaryDistributionSubmitActions.request,
      handleSubmitDiscretionaryDistribution
    ),
  () =>
    takeLatest(
      discretionaryDistributionEditActions.request,
      handleFetchDistributionInfoEdit
    ),
  () =>
    takeLatest(
      discretionaryDistributionDocActions.request,
      handleFetchDistributionDocs
    ),
  () =>
    takeEvery(uploadAdditionalDocActions.request, handleUploadAdditionalDocs),
  () => takeEvery(deleteAdditionalDocActions.request, handleAdditionalDocument),
  () =>
    takeLatest(
      discretionaryDistributionInfoLegacyDataActions.request,
      fetchDiscretionaryDistributionLegacy
    ),
  () =>
    takeLatest(
      discretionaryDistributionInfoCRMDataActions.request,
      fetchDiscretionaryDistributionCrm
    )
]
