import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { format } from 'date-fns'
import { range } from 'lodash'
import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { useEffect, useMemo, useState } from 'react'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { WidgetTableStyles } from './WidgetTableStyles'

export interface WidgetTableProps<T> {
  data?: T[]
  columns: any
  sort?: SortingState
  isUninitialized?: boolean
  isLoading?: boolean
  hideHeader?: boolean
  isFooter?: boolean
  emptyRowCount?: number
}

const noData: any[] = []

export const WidgetTable: React.FC<WidgetTableProps<unknown>> = ({
  data,
  columns,
  sort,
  isUninitialized,
  isLoading,
  hideHeader,
  emptyRowCount,
  isFooter
}) => {
  const [sorting, setSorting] = useState<SortingState>(sort || [])
  useEffect(() => {
    setSorting(sort || [])
  }, [sort])

  const table = useReactTable({
    data: data || noData,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })
  const headers = table.getFlatHeaders()
  const showNoData = useMemo(
    () => !isUninitialized && !data?.length && !isLoading,
    [data?.length, isLoading, isUninitialized]
  )
  const showEmptyRows = isUninitialized || (isLoading && !data?.length)
  return (
    <div
      css={[
        advisoryModuleStyles.fancyScroll,
        {
          height: '100%',
          fontSize: '12px',
          overflow: isUninitialized ? 'hidden' : undefined
        }
      ]}
    >
      <table css={WidgetTableStyles.widgetTable}>
        <thead css={{ verticalAlign: 'top' }}>
          <tr>
            {headers.map((header) => {
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                    height: 0,
                    padding: 0,
                    margin: 0
                  }}
                />
              )
            })}
          </tr>
          {!hideHeader && (
            <tr>
              {headers.map((header) => {
                return (
                  <th key={header.id}>
                    <div
                      {...{
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      css={{
                        cursor: 'pointer'
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  </th>
                )
              })}
            </tr>
          )}
        </thead>

        <tbody>
          {showEmptyRows &&
            range(emptyRowCount ? emptyRowCount : 10)?.map((x) => (
              <tr css={{ height: '30px', width: '100%' }} key={x} />
            ))}
          {!isUninitialized &&
            table?.getRowModel()?.rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
        {(showNoData || isFooter || showEmptyRows) && (
          <tfoot>
            {showNoData && (
              <tr css={{ height: '100%' }}>
                <td
                  css={{
                    height: '100%',
                    textAlign: 'center',
                    background: ' #f0f0f0',
                    border: '1px solid #f0f0f0'
                  }}
                  colSpan={columns.length}
                >
                  No data available
                </td>
              </tr>
            )}
            {(isFooter || showEmptyRows) && (
              <tr css={WidgetTableStyles.footer}>
                {headers?.map((x) => (
                  <td key={x.id}>
                    {x.column.columnDef.footer
                      ? flexRender(x.column.columnDef.footer, x.getContext())
                      : null}
                  </td>
                ))}
              </tr>
            )}
          </tfoot>
        )}
      </table>
    </div>
  )
}

export const DateCell: React.FC<{ value?: string; defaultValue?: string }> = ({
  value,
  defaultValue = ''
}) => {
  const date =
    value !== undefined && value !== null
      ? format(parseDateISOStringInLocalTimezone(value), 'yyyy-MM-dd')
      : defaultValue
  return (
    <div
      css={[
        WidgetTableStyles.ellipsis,
        {
          textAlign: 'right'
        }
      ]}
      title={date}
    >
      {date}
    </div>
  )
}

export const USDCell: React.FC<{ value?: number; defaultValue?: string }> = ({
  value,
  defaultValue = ''
}) => {
  const totalAmount =
    value !== undefined && value !== null
      ? value?.toLocaleString('en-US', {
          style: 'currency',
          currencySign: 'standard',
          maximumFractionDigits: 0,
          currency: 'USD'
        })
      : defaultValue

  const totalAmountTitle = value?.toLocaleString('en-US', {
    style: 'currency',
    currencySign: 'standard',
    maximumFractionDigits: 2,
    currency: 'USD'
  })
  return (
    <div
      css={[
        WidgetTableStyles.ellipsis,
        {
          textAlign: 'left'
        }
      ]}
      title={totalAmountTitle}
    >
      {totalAmount}
    </div>
  )
}

export const PercentCell: React.FC<{
  value?: number
  defaultValue?: string
}> = ({ value, defaultValue = '' }) => {
  const percent =
    value !== undefined && value !== null
      ? `${value?.toFixed(2)}%`
      : defaultValue
  return (
    percent && (
      <div
        css={[
          WidgetTableStyles.ellipsis,
          {
            textAlign: 'right'
          }
        ]}
        title={percent}
      >
        {percent}
      </div>
    )
  )
}

export const NumberCell: React.FC<{ value?: number }> = ({ value }) => {
  return (
    <div
      css={[
        WidgetTableStyles.ellipsis,
        {
          textAlign: 'right'
        }
      ]}
      title={value?.toLocaleString()}
    >
      {value?.toLocaleString()}
    </div>
  )
}

export const TextCell: React.FC<{ value?: string }> = ({ value }) => {
  return (
    <div
      css={[
        WidgetTableStyles.ellipsis,
        {
          textAlign: 'left'
        }
      ]}
      title={value}
    >
      {value}
    </div>
  )
}
