import { IColumn } from '@fluentui/react'
import { orderBy } from 'lodash'
import { IFailedPayment } from '../../Payments/features/PaymentsForAction/store/types'
import { IWirePayment } from '../../Payments/features/Wire/api/types'

class GenericClass {
  static genericToString<T>(value: T) {
    if (typeof value === 'string') {
      return value?.toLowerCase()
    }

    if (typeof value === 'number') {
      return value
    }
  }
}

export function _copyAndSort<T>(
  items: T[] | undefined,
  columnKey: string,
  isSortedDescending?: boolean
): T[] {
  const key = columnKey as keyof T
  return orderBy(
    items?.slice(0),
    [
      (item) =>
        typeof item[key] === 'string'
          ? GenericClass.genericToString(item[key])
          : item[key]
    ],
    isSortedDescending ? 'asc' : 'desc'
  )
}

export const SortColumn = (
  column: IColumn,
  items: any,
  columns: any
): { items: any; columns: any } => {
  const newColumns: IColumn[] = columns.slice()
  const currColumn: IColumn = newColumns.filter(
    (currCol) => column.key === currCol.key
  )[0]
  newColumns.forEach((newCol: IColumn) => {
    if (newCol === currColumn) {
      currColumn.isSortedDescending = !currColumn.isSortedDescending
      currColumn.isSorted = true
    } else {
      newCol.isSorted = false
      newCol.isSortedDescending = true
    }
  })

  if (!currColumn.fieldName) {
    throw new Error('fieldName is undefined')
  }

  const newItems = _copyAndSort(
    items,
    currColumn.fieldName,
    currColumn.isSortedDescending
  )

  return {
    items: newItems,
    columns: newColumns
  }
}

export const SortWireInstructionAmountColumn = (
  column: IColumn,
  items: any,
  columns: any
): { items: any; columns: any } => {
  const newColumns: IColumn[] = columns.slice()
  const currColumn: IColumn = newColumns.filter(
    (currCol) => column.key === currCol.key
  )[0]
  newColumns.forEach((newCol: IColumn) => {
    if (newCol === currColumn) {
      currColumn.isSortedDescending = !currColumn.isSortedDescending
      currColumn.isSorted = true
    } else {
      newCol.isSorted = false
      newCol.isSortedDescending = true
    }
  })

  if (!currColumn.fieldName) {
    throw new Error('fieldName is undefined')
  }

  const newItems = orderBy(
    items.slice(0),
    [(item: IFailedPayment) => item?.paymentItems?.[0]?.amount],
    currColumn.isSortedDescending ? 'asc' : 'desc'
  )

  return {
    items: newItems,
    columns: newColumns
  }
}

export const SortWireAmountColumn = (
  column: IColumn,
  items: any,
  columns: any
): { items: any; columns: any } => {
  const newColumns: IColumn[] = columns.slice()
  const currColumn: IColumn = newColumns.filter(
    (currCol) => column.key === currCol.key
  )[0]
  newColumns.forEach((newCol: IColumn) => {
    if (newCol === currColumn) {
      currColumn.isSortedDescending = !currColumn.isSortedDescending
      currColumn.isSorted = true
    } else {
      newCol.isSorted = false
      newCol.isSortedDescending = true
    }
  })

  if (!currColumn.fieldName) {
    throw new Error('fieldName is undefined')
  }

  const newItems = orderBy(
    items.slice(0),
    [(item: IWirePayment) => (item?.amount ? Number(item?.amount) : undefined)],
    currColumn.isSortedDescending ? 'asc' : 'desc'
  )

  return {
    items: newItems,
    columns: newColumns
  }
}

export function arrayFilterByValue<T extends object>(
  array: T[],
  searchText: string,
  searchkeylist: string[]
) {
  let filteredArr: T[] = []

  if (array && Array.isArray(array) && array.length > 0) {
    filteredArr = array.filter((o: T) => {
      return Object.keys(o).some((k: string) => {
        const columnkey = k as keyof T
        if (
          searchkeylist &&
          searchkeylist.includes(k) &&
          o &&
          o[columnkey] !== undefined &&
          o[columnkey] !== null &&
          (typeof o[columnkey] === 'string' ||
            typeof o[columnkey] === 'number') &&
          GenericClass.genericToString(o[columnkey])
        ) {
          // filter based on string or number
          return GenericClass.genericToString(o[columnkey])
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
        } else {
          return false
        }
      })
    })
  }

  return filteredArr
}
