import { useNotes_getNotesByContactsQuery } from 'features/Notes/api/dynamics'
import { useTaskPanel } from '../components/SecondaryHeader/features/Tasks/store/taskPanel'
import {
  useGetMostRecentInteractionByContactsQuery,
  useGetTaskCountsQuery
} from '../store/dynamics'
import { useRdot360AccountContext } from '../store/rdot360Context/useRdot360AccountContext'
import { useRdot360ClientContext } from '../store/rdot360Context/useRdot360ClientContext'
import { useRdot360HouseholdContext } from '../store/rdot360Context/useRdot360HouseholdContext'

const useSecondaryHeaderData = () => {
  const { isHouseholdFetching } = useRdot360HouseholdContext()
  const { isFetching } = useRdot360AccountContext()
  const { profileIds, isProfilesFetching: isContactsFetching } =
    useRdot360ClientContext()
  const { isLoading: isNotesByContactsFetching } =
    useNotes_getNotesByContactsQuery(
      { contactIds: profileIds },
      {
        skip: !profileIds?.length
      }
    )

  const { isLoading: isInteractionsLoading } =
    useGetMostRecentInteractionByContactsQuery(profileIds, {
      skip: !profileIds?.length
    })

  const { getEndDate, getStartDate } = useTaskPanel()

  const { isFetching: isImmediateFetching } = useGetTaskCountsQuery(
    {
      contactIds: profileIds,
      startDate: getStartDate('immediate'),
      endDate: getEndDate('immediate')
    },
    {
      skip: !profileIds?.length
    }
  )
  const { isFetching: isFutureFetching } = useGetTaskCountsQuery(
    {
      contactIds: profileIds,
      startDate: getStartDate('future'),
      endDate: getEndDate('future')
    },
    {
      skip: !profileIds?.length
    }
  )
  const { isFetching: isAllFetching } = useGetTaskCountsQuery(
    { contactIds: profileIds },
    {
      skip: !profileIds?.length
    }
  )

  const getLoadingStatus = () => {
    const isLoading =
      isHouseholdFetching ||
      isFetching ||
      isNotesByContactsFetching ||
      isContactsFetching ||
      isInteractionsLoading ||
      isAllFetching ||
      isFutureFetching ||
      isImmediateFetching

    return isLoading
  }

  return {
    isLoading: getLoadingStatus()
  }
}

export default useSecondaryHeaderData
