import { ICalendarDayProps, IDropdownOption } from '@fluentui/react'
import { IAccount } from 'api/account.types'
import { format, isFuture } from 'date-fns'
import { isEmpty } from 'lodash'
import { IFeeAccount } from 'modules/Fees/api/types/types'
import {
  Dictionary,
  IDataFilter,
  NoData,
  RangeType
} from 'shared/components/DataTableFilters/DataTableFilters'
import {
  PaymentMM,
  PaymentRow
} from '../../Dashboard/modules/Dashboard/store/types'
import { IMMSummarySI, IStandingInstruction } from '../api/types'
import {
  ReceiveorDisbursement,
  RecipientType,
  SIMMDirection,
  TransactionType
} from '../EFT/Constants'
import {
  FieldNamesPattern,
  SpecialCharacterPattern
} from '../shared/Constants/SpecialCharacterConstants'
import { IJournalInitiationForm, IMMConfig } from '../store/Journal/types'
import { IPeriodicPayment } from '../store/PeriodicPayment/types'
import {
  IWireRequest,
  IWireInitiationForm,
  IPaymentVerificationForm,
  IMMValidateRequest,
  IPastPayment,
  IPaymentReviewRequest,
  IOMTApproverForm,
  ITransInfo,
  IFurtherCredit,
  IBeneficiaryDetails,
  IMMValidateResponse,
  IApproveDetail,
  IHolidayItem,
  IVerificationDetails,
  IAuthUserList,
  IAccountDetails
} from '../store/types'
import {
  getAccountType,
  getrecipient,
  isReceiptorisDisbursement
} from './UtilitiesEFT'
import { createJournalRequest, prepareJournalDataForEdit } from './UtilitiesJNL'
import {
  getPeriodicPayment,
  getPeriodicPaymentDataForEdit
} from './UtilitiesPeriodicPmt'

export const getPayTermText = (
  options: IDropdownOption[],
  key?: string
): string => {
  let payTermText = ''
  if (options && options.length > 0) {
    payTermText = options.filter((item) => item.key === key)?.[0]?.text
  }

  return payTermText ? payTermText : ''
}

export const formatPhoneNumber = (value: string | undefined): string => {
  if (!value) {
    return ''
  }
  if (value === 'Other') {
    return value
  }
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length
  if (cvLength < 4) {
    return currentValue
  }
  if (cvLength === 10) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`
  }

  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, cvLength)}`
}

export const USDEquivalent = (
  amount?: string | number | undefined,
  rate?: string | number | undefined
) => {
  try {
    if (amount && rate) {
      return Number(
        parseFloat((Number(amount) * Number(rate))?.toString()).toFixed(2)
      )
    }
  } catch (e) {
    return undefined
  }
  return undefined
}

export const getAmountForMMValidate = (wireData: IWireInitiationForm) => {
  if (wireData?.wireAmount?.accountCloseout) {
    return wireData?.senderDetails?.availablecashtowithdraw || 1
  }
  return wireData?.wireAmount?.currency !== 'USD'
    ? wireData?.wireAmount?.rate
      ? USDEquivalent(
          wireData?.wireAmount?.amount,
          wireData?.wireAmount?.rate
        ) || 1
      : 1 // if rate is empty, pass 1$ to mmvalidate as the api fails for 0$ usd
    : wireData?.wireAmount?.amount
    ? Number(wireData?.wireAmount?.amount)
    : 0
}

const mmProcessTaxWithholdingsDistribution = (
  wireInfo: IWireInitiationForm
) => {
  const taxWithholdings: ITransInfo = {
    canfedwithhold: wireInfo?.senderDetails?.isRetirementAccount
      ? wireInfo?.taxWithholdings?.fedTaxOption ?? undefined
      : undefined,
    canstatewithhold: wireInfo?.senderDetails?.isRetirementAccount
      ? wireInfo?.taxWithholdings?.stateTaxOption ?? undefined
      : undefined,
    fedwithholdpct: wireInfo?.senderDetails?.isRetirementAccount
      ? Number(wireInfo?.taxWithholdings?.fedTaxRate)
      : undefined,
    statewithholdpct: wireInfo?.senderDetails?.isRetirementAccount
      ? Number(wireInfo?.taxWithholdings?.stateTaxRate)
      : undefined,
    istrnamtincludetaxwithhold: wireInfo?.senderDetails?.isRetirementAccount
      ? !(wireInfo?.taxWithholdings?.includeTax ?? false)
      : false,
    reasoncode: wireInfo?.senderDetails?.isRetirementAccount
      ? wireInfo?.taxWithholdings?.retDistCode
      : undefined,
    reasondesc: wireInfo?.senderDetails?.isRetirementAccount
      ? wireInfo?.taxWithholdings?.retDistReason
      : undefined,
    fedwithholdmaxpct: wireInfo?.taxWithholdings?.fedwithholdmaxpct
      ? wireInfo?.taxWithholdings?.fedwithholdmaxpct
      : undefined,
    fedwithholdminpct: wireInfo?.taxWithholdings?.fedwithholdminpct
      ? wireInfo?.taxWithholdings?.fedwithholdminpct
      : undefined,
    statewithholdminpct: wireInfo?.taxWithholdings?.statewithholdminpct
      ? wireInfo?.taxWithholdings?.statewithholdminpct
      : undefined,
    statewithholdmaxpct: wireInfo?.taxWithholdings?.statewithholdmaxpct
      ? wireInfo?.taxWithholdings?.statewithholdmaxpct
      : undefined
  }
  return taxWithholdings
}

const mmProcessTaxWithholdingsContribution = (
  wireInfo: IWireInitiationForm,
  currentYear: string,
  prevYear: string
) => {
  const getDistribution: ITransInfo =
    mmProcessTaxWithholdingsDistribution(wireInfo)

  Object.keys(getDistribution).forEach((colkey: string) => {
    const columnkey = colkey as keyof ITransInfo
    getDistribution[columnkey] = undefined
  })
  const contribution = {
    ...getDistribution,
    conreasoncode: wireInfo?.taxWithholdings?.conReasonCode,
    conreasonsubcode: wireInfo?.taxWithholdings?.conReasonSubcode,
    conpromptyearind: wireInfo?.taxWithholdings?.conPromptYearInd,
    conreasondesc: wireInfo?.taxWithholdings?.conReasonDesc,

    currentyear:
      wireInfo?.taxWithholdings?.conPromptYearInd === 'Y' &&
      wireInfo?.taxWithholdings?.year === currentYear
        ? currentYear
        : '',
    prioryear:
      wireInfo?.taxWithholdings?.conPromptYearInd === 'Y' &&
      wireInfo?.taxWithholdings?.year === prevYear
        ? prevYear
        : undefined
  }
  return contribution
}

const getApprovalDetails = (
  verificationInfo: IPaymentVerificationForm,
  wireInfo: IWireInitiationForm
) => {
  const approvalDetail: IVerificationDetails = {
    instMethod: verificationInfo?.methodOfInstruction || '',
    instDate: verificationInfo?.dateOfInstruction
      ? format(verificationInfo?.dateOfInstruction, 'MM/dd/yyyy')
      : undefined,
    instPerson:
      verificationInfo?.authorizedPerson === 'Other'
        ? 'Other: ' + verificationInfo?.authorizedPersonCustom ?? ''
        : verificationInfo?.authorizedPerson ?? '',
    isEauth: verificationInfo?.clientAuthorization ? 'Y' : undefined,
    ClnDetail: verificationInfo?.clientAuthMethods?.includes('ClnDetail')
      ? 'Y'
      : undefined,
    ClnRelationship: verificationInfo?.clientAuthMethods?.includes(
      'ClnRelationship'
    )
      ? 'Y'
      : undefined,
    DoB: verificationInfo?.clientAuthMethods?.includes('DoB') ? 'Y' : undefined,
    Last4SSN: verificationInfo?.clientAuthMethods?.includes('Last4SSN')
      ? 'Y'
      : undefined,
    RecentAct: verificationInfo?.clientAuthMethods?.includes('RecentAct')
      ? 'Y'
      : undefined,
    inPerson: verificationInfo?.clientAuthMethods?.includes('inPerson')
      ? 'Y'
      : undefined,
    VoiceRcgn: verificationInfo?.clientAuthMethods?.includes('VoiceRcgn')
      ? 'Y'
      : undefined,
    PhoneNo:
      verificationInfo?.phoneNumber === 'Other'
        ? verificationInfo?.manualPhoneNo?.replace(/[^\d]/g, '') ?? ''
        : verificationInfo?.phoneNumber?.replace(/[^\d]/g, '') ?? '',
    PhoneSr: verificationInfo?.phoneNumberSource ?? '',
    instCallType:
      verificationInfo?.phoneNumber === 'Other' ? 'Other' : 'CustodianProvided',
    Purpose:
      verificationInfo?.purposeOfFund === 'Other'
        ? verificationInfo?.purposeOfFundCustom ?? ''
        : verificationInfo?.purposeOfFund ?? '',
    PWAcomment: verificationInfo?.additinalComment ?? '',
    insufFundSolution: wireInfo?.wireAmount?.hasInsufFund
      ? wireInfo?.wireAmount?.insufFundSolutionId
      : '',
    insufFund: wireInfo?.wireAmount?.hasInsufFund
      ? wireInfo?.wireAmount?.insufFundComments
      : '',
    authenticateEmployee: verificationInfo?.authenticateEmployee,
    AccountHolderSelected: wireInfo?.accountHolder?.taxID
  }

  return {
    ...approvalDetail,
    recurringReq: getPeriodicPayment(wireInfo?.periodicPayment)
  }
}

export type createWireRequestProps = {
  wireInfo: IWireInitiationForm
  verificationInfo?: IPaymentVerificationForm
  status?: string
  ismarginpaperworkrequired?: boolean
  paymentType?: string
}

export const createWireRequest = ({
  wireInfo,
  verificationInfo = {},
  status = 'DRFT',
  ismarginpaperworkrequired = false,
  paymentType = TransactionType.Wire
}: createWireRequestProps) => {
  const today = new Date()
  const currentYear = format(today, 'yyyy')
  const prevYear = (Number(currentYear) - 1).toString()
  const approvalDetail = getApprovalDetails(verificationInfo, wireInfo)
  if (paymentType === TransactionType.Journal) {
    return createJournalRequest({
      journalInfo: wireInfo,
      approvalDetail,
      status,
      ismarginpaperworkrequired
    })
  }
  let request: IWireRequest = {
    mmInstID: wireInfo?.wirePaymentId,
    ExecutingCustodian: wireInfo?.executingCustodian,
    tranInfo: {
      SrvTrnID: 0,
      WireTypeCondition: wireInfo.wireType,
      availablecashtowithdraw:
        wireInfo?.senderDetails?.availablecashtowithdraw ?? undefined,
      UseExistingSI: wireInfo.wireAmount?.useExistingSI ?? false,
      StandingInstructionId: wireInfo.wireAmount?.standingInstructionId,
      IsThirdParty: wireInfo?.wireAmount?.recipientType === 'Third Party',
      BankAcctFromAcctId: wireInfo?.senderDetails?.account ?? '',
      BankAcctFromName: wireInfo?.senderDetails?.name ?? '',
      BeneficiaryBankRoutingType: wireInfo.wireType === 'A' ? 'A' : 'S',
      BeneficiaryBankRoutingId:
        wireInfo?.beneficiary?.bankIdentifierNumber?.replace(/\s+/g, '') || '',
      BeneficiaryBankName: wireInfo?.beneficiary?.bankName ?? '',
      BeneficiaryAcctId: wireInfo?.beneficiary?.account ?? '',
      BeneficiaryName: wireInfo?.beneficiary?.name ?? '',
      BeneficiaryNameAddrLine1: wireInfo?.beneficiary?.line1 ?? '',
      BeneficiaryNameAddrLine2: wireInfo?.beneficiary?.line2 ?? '',
      BeneficiaryNameAddrLine3: wireInfo?.beneficiary?.line3 ?? '',
      CreateNewSI: wireInfo?.beneficiary?.createNewSI ?? false,
      Ismarginpaperworkrequired: ismarginpaperworkrequired,
      InternalMessage: wireInfo?.beneficiary?.createNewSI
        ? wireInfo?.beneficiary?.maxDistAmt?.toString()
        : '',
      WireDisbursementDetail1: wireInfo?.details?.line1 ?? '',
      WireDisbursementDetail2: wireInfo?.details?.line2 ?? '',
      WireDisbursementDetail3: wireInfo?.details?.line3 ?? '',
      WireDisbursementDetail4: wireInfo?.details?.line4 ?? '',
      sendDisbDetail: wireInfo.wireAmount?.useExistingSI
        ? wireInfo?.details?.sendDisbDetail
        : true,
      FurtherCredit1_AccountNumber: wireInfo?.furtherCredit1?.hasFC
        ? wireInfo?.furtherCredit1?.account
        : '',
      FurtherCredit1_NameLine1: wireInfo?.furtherCredit1?.hasFC
        ? wireInfo?.furtherCredit1?.name
        : '',
      FurtherCredit1_NameLine2: wireInfo?.furtherCredit1?.hasFC
        ? wireInfo?.furtherCredit1?.line1 ?? ''
        : '',
      FurtherCredit1_NameLine3: wireInfo?.furtherCredit1?.hasFC
        ? wireInfo?.furtherCredit1?.line2 ?? ''
        : '',
      FurtherCredit1_NameLine4: wireInfo?.furtherCredit1?.hasFC
        ? wireInfo?.furtherCredit1?.line3 ?? ''
        : '',
      FurtherCredit2_AccountNumber: wireInfo?.furtherCredit2?.hasFC
        ? wireInfo?.furtherCredit2?.account ?? ''
        : '',
      FurtherCredit2_NameLine1: wireInfo?.furtherCredit2?.hasFC
        ? wireInfo?.furtherCredit2?.name ?? ''
        : '',
      FurtherCredit2_NameLine2: wireInfo?.furtherCredit2?.hasFC
        ? wireInfo?.furtherCredit2?.line1 ?? ''
        : '',
      FurtherCredit2_NameLine3: wireInfo?.furtherCredit2?.hasFC
        ? wireInfo?.furtherCredit2?.line2 ?? ''
        : '',
      FurtherCredit2_NameLine4: wireInfo?.furtherCredit2?.hasFC
        ? wireInfo?.furtherCredit2?.line3 ?? ''
        : '',
      DestinationCountry: wireInfo?.wireAmount?.country ?? 'US',
      isfuturedated: false,
      isIRA: wireInfo?.senderDetails?.isRetirementAccount,

      TrnAmt: !wireInfo?.wireAmount?.accountCloseout
        ? parseFloat(wireInfo?.wireAmount?.amount ?? '0')
        : undefined,
      AMAacknowledgement:
        wireInfo?.taxWithholdings?.retDistCode !== '3' &&
        wireInfo?.taxWithholdings?.retDistCode !== 'G'
          ? wireInfo?.taxWithholdings?.AMAacknowledgement
          : undefined,
      Memo: wireInfo?.details?.memo,
      TrnDateTime: wireInfo?.wireAmount?.tranDate
        ? format(wireInfo?.wireAmount?.tranDate, 'MM/dd/yyyy')
        : '',
      UseType9Income: wireInfo?.wireAmount?.useType9Income,
      AccountCloseout: wireInfo?.wireAmount?.accountCloseout,
      RecipientBankAcceptUSD:
        wireInfo?.wireAmount?.currency === 'USD' &&
        wireInfo?.wireAmount?.country !== 'US'
          ? wireInfo?.wireAmount?.acceptsUSD
          : undefined,
      userEditedTransaction: wireInfo?.userEditedTransaction,
      ExecutingCustodian: wireInfo?.executingCustodian,
      isRecurringPaymentPlan:
        wireInfo?.periodicPayment?.isRecurringPaymentPlan ?? undefined
    },
    expirationDate:
      wireInfo?.wireAmount?.tranDate &&
      isFuture(wireInfo?.wireAmount?.tranDate) &&
      wireInfo?.wireAmount?.expirationDate
        ? format(wireInfo?.wireAmount?.expirationDate, 'MM/dd/yyyy')
        : '',
    apprDetail: approvalDetail,

    intlMMinfo: {
      toCountry: wireInfo?.wireAmount?.country || '',
      toCurrency: wireInfo?.wireAmount?.currency || 'USD',
      xchngRate:
        wireInfo?.wireAmount?.currency !== 'USD'
          ? wireInfo?.wireAmount?.rate
          : undefined,
      USDAmount:
        wireInfo?.wireAmount?.currency !== 'USD'
          ? USDEquivalent(
              wireInfo?.wireAmount?.amount,
              wireInfo?.wireAmount?.rate
            )
          : Number(wireInfo?.wireAmount?.amount)
    },
    mmStatus: status,
    xferType: 'WD'
  }
  const taxWithholdings = mmProcessTaxWithholdingsDistribution(wireInfo)
  //const periodicPayment = getPeriodicPayment(wireInfo?.periodicPayment ?? {})

  request.tranInfo = {
    ...request.tranInfo,
    ...taxWithholdings
    //...periodicPayment
  }
  if (wireInfo.wireType === 'C') {
    request = {
      ...request,
      tranInfo: { ...request.tranInfo, ...getWireTypeCDetails(wireInfo) }
    }
  }

  // Update Request for EFT
  if (paymentType === TransactionType.EFT) {
    const eftRequest = mmProcessEFTRequest(
      request,
      wireInfo,
      currentYear,
      prevYear
    )
    return eftRequest
  }
  return request
}

const getWireTypeCDetails = (wireInfo: IWireInitiationForm) => {
  const request: ITransInfo = {
    BeneficiaryBankRoutingType:
      wireInfo?.wireAmount?.currency !== 'USD' ? 'S' : 'A',
    BeneficiaryAcctId:
      wireInfo?.wireAmount?.currency !== 'USD'
        ? wireInfo.beneficiary?.account
        : wireInfo.beneficiary?.swiftCode ?? '',
    BeneficiaryBankRoutingId:
      wireInfo?.wireAmount?.currency !== 'USD'
        ? wireInfo?.beneficiary?.swiftCode
        : wireInfo?.beneficiary?.bankIdentifierNumber ?? '',
    BeneficiaryBankName: wireInfo?.beneficiary?.swiftbankName || ''
  }
  return request
}

const mmProcessEFTRequest = (
  request: IWireRequest,
  wireInfo: IWireInitiationForm,
  currentYear: string,
  prevYear: string
) => {
  let eftRequest: ITransInfo = {
    ...request.tranInfo,
    BankAcctFromAcctType:
      wireInfo?.wireAmount?.isexternal === 'Disbursement'
        ? undefined
        : getAccountType(wireInfo?.beneficiary?.accountType || ''),
    BeneficiaryAcctType:
      wireInfo?.wireAmount?.isexternal === 'Disbursement'
        ? getAccountType(wireInfo?.beneficiary?.accountType || '')
        : undefined,
    isReceive:
      wireInfo?.wireAmount?.isexternal === ReceiveorDisbursement.Receipt,
    BeneficiaryBankRoutingType: 'A',
    isThirdPartyCommon: wireInfo?.wireAmount?.isThirdPartyCommon,
    // isThirdPartyCommon:                    // uncomment after NFS fix isthirdpartycommon issue
    //   wireInfo?.wireAmount?.recipientType === RecipientType.ThirdPartyCommon
    //     ? true
    //     : false,
    EWSResult: wireInfo?.EWSResult,
    isewsverified: wireInfo?.isewsverified,
    //isSIOnly: wireInfo?.isSIOnly,
    SIMMDirection: getSIMMIDirection(wireInfo)
  }

  if (
    wireInfo?.senderDetails?.isRetirementAccount &&
    wireInfo?.wireAmount?.isexternal === ReceiveorDisbursement.Receipt
  ) {
    eftRequest = {
      ...eftRequest,
      ...mmProcessTaxWithholdingsContribution(wireInfo, currentYear, prevYear)
    }
  }
  return {
    ...request,
    intlMMinfo: {
      ...request.intlMMinfo,
      USDAmount: Number(wireInfo?.wireAmount?.amount)
    },
    tranInfo: eftRequest,
    xferType: TransactionType.EFT,
    paymentType: TransactionType.EFT
  }
}

const getSIMMIDirection = (wireInfo: IWireInitiationForm | undefined) => {
  if (
    wireInfo?.beneficiary?.isSIBoth ||
    (wireInfo?.wireAmount?.useExistingSI &&
      wireInfo?.beneficiary?.SIMMDirection === SIMMDirection.Both)
  ) {
    return SIMMDirection.Both
  } else if (
    wireInfo?.wireAmount?.isexternal === ReceiveorDisbursement.Disbursement
  ) {
    return SIMMDirection.Disbursement
  } else if (
    wireInfo?.wireAmount?.isexternal === ReceiveorDisbursement.Receipt
  ) {
    return SIMMDirection.Receive
  }
  return undefined
}

const updateMappingsBeforeValidate = (
  request: IMMValidateRequest,
  wireInfo: IWireInitiationForm
): IMMValidateRequest => {
  if (!wireInfo?.furtherCredit1?.hasFC && !wireInfo?.furtherCredit2?.hasFC) {
    return request
  }
  // updating field mapping if fc1 / fc2 are entered
  request.FurtherCredit1_AccountNumber = request?.toaccount?.accountnumber || ''
  request.FurtherCredit1_NameLine1 = wireInfo?.beneficiary?.name || ''
  if (!wireInfo?.furtherCredit2?.hasFC) {
    if (request.toaccount) {
      request.toaccount.accountnumber = wireInfo?.furtherCredit1?.account || ''
      request.toaccount.accountname = wireInfo?.furtherCredit1?.name || ''
    }
  } else {
    if (request.toaccount) {
      request.toaccount.accountnumber = wireInfo?.furtherCredit2?.account || ''
      request.toaccount.accountname = wireInfo?.furtherCredit2?.name || ''
    }
    request.FurtherCredit2_AccountNumber =
      wireInfo?.furtherCredit1?.account || ''
    request.FurtherCredit2_NameLine1 = wireInfo?.furtherCredit1?.name || ''
  }
  return request
}

const switchBene = (item: IStandingInstruction) => {
  let FFC1: IFurtherCredit = {
    account: '',
    name: '',
    line1: '',
    line2: '',
    line3: '',
    hasFC: false
  }

  let FFC2: IFurtherCredit = {
    account: '',
    name: '',
    line1: '',
    line2: '',
    line3: '',
    hasFC: false
  }

  let beneficiary: IBeneficiaryDetails = {
    bankIdentifierNumber: item?.routeId || '',
    bankName: item?.MMBeneficiaryAccount?.BankName || '',
    account:
      item?.DestinationCountry !== 'US' &&
      item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber
        ? item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber
        : item?.MMBeneficiaryAccount?.BeneficiaryAccountNumber || '',
    swiftCode:
      item?.DestinationCountry !== 'US'
        ? item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber
        : item?.MMBeneficiaryAccount?.BeneficiaryAccountNumber,
    name:
      item?.DestinationCountry !== 'US' &&
      item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber
        ? item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine1
        : item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine1 || '',
    line1: '',
    line2: '',
    line3: ''
  }

  if (item?.FurtherCreditAccountNumber2?.FurtherCreditAccountNumber) {
    FFC2 = {
      account: item?.MMBeneficiaryAccount?.BeneficiaryAccountNumber,
      name: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine1,
      line1: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine2,
      line2: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine3,
      line3: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine4,
      hasFC: true
    }

    FFC1 = {
      account:
        item?.FurtherCreditAccountNumber2?.FurtherCreditAccountNumber || '',
      name:
        item?.FurtherCreditAccountNumber2.FurtherCredit1NameLine?.NameLine1 ||
        '',
      line1:
        item?.FurtherCreditAccountNumber2?.FurtherCredit1NameLine?.NameLine2 ||
        '',
      line2:
        item?.FurtherCreditAccountNumber2?.FurtherCredit1NameLine?.NameLine3 ||
        '',
      line3:
        item?.FurtherCreditAccountNumber2?.FurtherCredit1NameLine?.NameLine4 ||
        '',
      hasFC: true
    }

    beneficiary = {
      ...beneficiary,
      account: item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber,
      name: item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine
        ?.NameLine1,
      line1:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine2,
      line2:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine3,
      line3:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine4
    }
  } else if (item?.FurtherCreditAccountNumber1?.FurtherCreditAccountNumber) {
    FFC1 = {
      account: item?.MMBeneficiaryAccount?.BeneficiaryAccountNumber || '',
      name: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine1 || '',
      line1: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine2 || '',
      line2: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine3 || '',
      line3: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine4 || '',
      hasFC: true
    }

    beneficiary = {
      ...beneficiary,
      account: item?.FurtherCreditAccountNumber1.FurtherCreditAccountNumber,
      name: item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine
        ?.NameLine1,
      line1:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine2,
      line2:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine3,
      line3:
        item?.FurtherCreditAccountNumber1?.FurtherCredit1NameLine?.NameLine4
    }
  } else {
    beneficiary = {
      ...beneficiary,
      name: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine1 || '',
      line1: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine2 || '',
      line2: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine3 || '',
      line3: item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine4 || ''
    }
  }

  return { bene: beneficiary, FFC1: FFC1, FFC2: FFC2 }
}

export const prepareWireDataFromSI = (
  item: IStandingInstruction,
  clientName: string | undefined,
  isRetirementAccount: boolean | undefined,
  registrationtype: string | undefined,
  periodicPayment?: IPeriodicPayment,
  configData?: IMMConfig,
  wireType?: string,
  stateMinPct?: number,
  stateMaxPct?: number,
  fedMinPct?: number,
  fedMaxPct?: number
): IWireInitiationForm => {
  const { bene, FFC1, FFC2 } = switchBene(item)
  return {
    beneficiary: { ...bene },
    periodicPayment: periodicPayment,
    details: {
      line1:
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail1 ||
        '',
      line2:
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail2 ||
        '',
      line3:
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail3 ||
        '',
      line4:
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail4 ||
        '',
      sendDisbDetail: !(
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail1 ||
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail2 ||
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail3 ||
        item?.WireStandingInstructionDetail?.WireStandingInstructionDetail4
      )
    },
    furtherCredit1: { ...FFC1 },
    furtherCredit2: { ...FFC2 },
    senderDetails: {
      account: item?.accountnumber || '',
      name: clientName || '',
      isRetirementAccount: isRetirementAccount,
      registrationtype: registrationtype,
      custodianName: configData?.custodianName ?? undefined
    },
    wireAmount: {
      currency: 'USD',
      country: item?.DestinationCountry || '',
      useExistingSI: true,
      useExistingPP: false,
      standingInstructionId: item?.standinginstructionid,
      recipientType: item?.isthirdparty ? 'Third Party' : 'First Party'
    },
    taxWithholdings: {
      statewithholdminpct: stateMinPct,
      statewithholdmaxpct: stateMaxPct,
      fedwithholdminpct: fedMinPct,
      fedwithholdmaxpct: fedMaxPct
    },
    custodianName: configData?.custodianID ?? '',
    wireType: wireType
  }
}
const getSwiftCodePP = (item: IPastPayment) => {
  if (item?.tranInfo?.destinationCountry !== 'US') {
    if (item?.intlMMinfo?.toCurrency === 'USD') {
      return item?.tranInfo?.furtherCredit1_AccountNumber
    }
    return item?.tranInfo?.beneficiaryBankRoutingId || ''
  }

  return item?.tranInfo?.beneficiaryAcctId
}
const switchBenePP = (item: IPastPayment) => {
  let FFC1: IFurtherCredit = {
    account: '',
    name: '',
    line1: '',
    line2: '',
    line3: '',
    hasFC: false
  }

  let FFC2: IFurtherCredit = {
    account: '',
    name: '',
    line1: '',
    line2: '',
    line3: '',
    hasFC: false
  }

  let beneficiary: IBeneficiaryDetails = {
    bankIdentifierNumber: item?.tranInfo?.beneficiaryBankRoutingId || '',
    bankName: item?.tranInfo?.beneficiaryBankName || '',
    account:
      item?.tranInfo?.destinationCountry !== 'US' &&
      item?.tranInfo?.furtherCredit1_AccountNumber
        ? item?.tranInfo?.furtherCredit1_AccountNumber
        : item?.tranInfo?.beneficiaryAcctId || '',
    swiftCode: getSwiftCodePP(item),
    name:
      item?.tranInfo?.destinationCountry !== 'US' &&
      item?.tranInfo?.furtherCredit1_AccountNumber
        ? item?.tranInfo?.furtherCredit1_NameLine1
        : item?.tranInfo?.beneficiaryName || '',
    accountType: item?.tranInfo?.beneficiaryAcctType || '',
    line1: '',
    line2: '',
    line3: ''
  }

  if (item?.tranInfo?.furtherCredit2_AccountNumber) {
    FFC2 = {
      account: item?.tranInfo?.beneficiaryAcctId,
      name: item?.tranInfo?.beneficiaryName,
      line1: item?.tranInfo?.beneficiaryNameAddrLine2,
      line2: item?.tranInfo?.beneficiaryNameAddrLine3,
      line3: item?.tranInfo?.beneficiaryNameAddrLine4,
      hasFC: item?.tranInfo?.furtherCredit2_AccountNumber ? true : false
    }

    FFC1 = {
      account: item?.tranInfo?.furtherCredit2_AccountNumber || '',
      name: item?.tranInfo?.furtherCredit2_NameLine1 || '',
      line1: item?.tranInfo?.furtherCredit2_NameLine2 || '',
      line2: item?.tranInfo?.furtherCredit2_NameLine3 || '',
      line3: item?.tranInfo?.furtherCredit2_NameLine4 || '',
      hasFC: item?.tranInfo?.furtherCredit1_AccountNumber ? true : false
    }

    beneficiary = {
      ...beneficiary,
      account: item?.tranInfo?.furtherCredit1_AccountNumber,
      name: item?.tranInfo?.furtherCredit1_NameLine1,
      line1: item?.tranInfo?.furtherCredit1_NameLine2,
      line2: item?.tranInfo?.furtherCredit1_NameLine3,
      line3: item?.tranInfo?.furtherCredit1_NameLine4
    }
  } else if (item?.tranInfo?.furtherCredit1_AccountNumber) {
    FFC1 = {
      account: item?.tranInfo?.beneficiaryAcctId ?? '',
      name: item?.tranInfo?.beneficiaryName ?? '',
      line1: item?.tranInfo?.beneficiaryNameAddrLine2 ?? '',
      line2: item?.tranInfo?.beneficiaryNameAddrLine3 ?? '',
      line3: item?.tranInfo?.beneficiaryNameAddrLine4 ?? '',
      hasFC: true
    }

    beneficiary = {
      ...beneficiary,
      account: item?.tranInfo?.furtherCredit1_AccountNumber,
      name: item?.tranInfo?.furtherCredit1_NameLine1,
      line1: item?.tranInfo?.furtherCredit1_NameLine2,
      line2: item?.tranInfo?.furtherCredit1_NameLine3,
      line3: item?.tranInfo?.furtherCredit1_NameLine4
    }
  } else {
    beneficiary = {
      ...beneficiary,
      name: item?.tranInfo?.beneficiaryName ?? '',
      line1: item?.tranInfo?.beneficiaryNameAddrLine2 ?? '',
      line2: item?.tranInfo?.beneficiaryNameAddrLine3 ?? '',
      line3: item?.tranInfo?.beneficiaryNameAddrLine4 ?? ''
    }
  }

  return { bene: beneficiary, FFC1: FFC1, FFC2: FFC2 }
}
export const prepareWireDataFromPastPaymnet = (
  item: IPastPayment,
  clientName: string | undefined,
  account: string | undefined,
  isRetirementAccount: boolean | undefined,
  registrationtype: string | undefined,
  periodicPayment?: IPeriodicPayment,
  configData?: IMMConfig,
  wireType?: string,
  stateMinPct?: number,
  stateMaxPct?: number,
  fedMinPct?: number,
  fedMaxPct?: number
): IWireInitiationForm => {
  const { bene, FFC1, FFC2 } = switchBenePP(item)
  console.log({ bene, FFC1, FFC2 })
  return {
    beneficiary: { ...bene },
    details: {
      line1: item?.tranInfo?.wireDisbursementDetail1 ?? '',
      line2: item?.tranInfo?.wireDisbursementDetail2 ?? '',
      line3: item?.tranInfo?.wireDisbursementDetail3 ?? '',
      line4: item?.tranInfo?.wireDisbursementDetail4 ?? ''
    },
    furtherCredit1: { ...FFC1 },
    furtherCredit2: { ...FFC2 },
    senderDetails: {
      account: account ?? item?.tranInfo?.bankAcctFromAcctId ?? '',
      name: clientName ?? '',
      isRetirementAccount: isRetirementAccount,
      registrationtype: registrationtype,
      custodianName: configData?.custodianName ?? ''
    },
    wireAmount: {
      currency: item?.intlMMinfo?.toCurrency,
      country: item?.tranInfo?.destinationCountry || '',
      recipientType: item?.tranInfo?.isThirdParty
        ? 'Third Party'
        : 'First Party',
      useExistingPP: true,
      useExistingSI: false,
      isexternal: ReceiveorDisbursement.Disbursement
    },
    periodicPayment: periodicPayment,
    executingCustodian: configData?.custodianID ?? '',
    wireType: wireType,
    taxWithholdings: {
      statewithholdminpct: stateMinPct,
      statewithholdmaxpct: stateMaxPct,
      fedwithholdminpct: fedMinPct,
      fedwithholdmaxpct: fedMaxPct
    }
  }
}

export const prepareEJDataFromSI = (
  item: IMMSummarySI,
  clientName: string | undefined,
  isRetirementAccount: boolean | undefined,
  registrationtype: string | undefined,
  periodicPayment?: IPeriodicPayment,
  configData?: IMMConfig
): IWireInitiationForm => {
  return {
    beneficiary: {
      bankIdentifierNumber: item?.abanumber?.replace(/\s+/g, '') || '',
      bankName: item?.bankname || '',
      account: item?.bankaccountnumber || '',
      name: replaceCharacterOnName(item?.bankaccountname),
      line1: item?.bankaddress || '',
      line2: '',
      line3: '',
      accountType: item?.bankaccounttype,
      SIMMDirection: isReceiptorisDisbursement(item).siimiDirection,
      isSIBoth: !item.isthirdparty
    },
    details: {
      line1: item?.memo ?? ''
    },

    senderDetails: {
      account: item?.accountnumber ?? '',
      name: clientName ?? '',
      isRetirementAccount: isRetirementAccount,
      registrationtype: registrationtype,
      custodianName: configData?.custodianName ?? ''
    },
    wireAmount: {
      currency: 'USD',
      country: item?.destinationcountry ?? '',
      useExistingSI: true,
      useExistingPP: false,
      standingInstructionId: item?.standinginstructionid,
      recipientType: item?.isthirdparty
        ? RecipientType.ThirdParty
        : RecipientType.FirstParty,
      isThirdPartyCommon: item?.isthirdpartycommon,
      isexternal: isReceiptorisDisbursement(item).isExternal,
      amount: undefined
    },
    taxWithholdings: {
      fedTaxOption: ''
    },
    executingCustodian: configData?.custodianID ?? '',
    periodicPayment: periodicPayment
  }
}

export const filterPaymentsByFilterData = (
  feePlans: PaymentRow[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData = [...feePlans]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.searchText) {
          if (
            (x[key as keyof PaymentRow] as string)
              ?.toLowerCase()
              ?.includes(data?.searchText.toLocaleLowerCase())
          ) {
            return true
          }
        }
        if (key === 'action') {
          if (data.value === 'Show Actionable') {
            return [
              'edit',
              'review',
              'reprocess',
              'reject',
              'close',
              'updfedrefid',
              'disclose',
              'acknowledge'
            ].some((action) => x?.actions?.toLowerCase().includes(action))
          } else {
            return true
          }
        }
        if (key === 'isRecurringPayment') {
          if (
            Array.isArray(filterBy?.isRecurringPayment?.value) &&
            filterBy?.isRecurringPayment?.value?.includes('No') &&
            !!x.isRecurringPayment
          ) {
            return false
          } else if (
            Array.isArray(filterBy?.isRecurringPayment?.value) &&
            filterBy?.isRecurringPayment?.value?.includes('Yes') &&
            !x.isRecurringPayment
          ) {
            return false
          }
          return true
        } else if ((data?.value as RangeType)?.rangeKey && key === 'amount') {
          const filterValue = data.value as RangeType
          const rangeKey = filterValue?.rangeKey
          const min = isNaN(parseFloat(filterValue?.minValue?.toString() || ''))
            ? null
            : parseFloat(filterValue?.minValue?.toString() || '')
          const max = isNaN(parseFloat(filterValue?.maxValue?.toString() || ''))
            ? null
            : parseFloat(filterValue?.maxValue?.toString() || '')
          const amt = parseFloat(x?.amount?.toString() || '0')
          switch (rangeKey) {
            case '=':
              return min && amt === min
            case '>':
              return min && amt > min
            case '<':
              return min && amt < min
            case 'customNumber':
              return min && max && amt >= min && amt <= max
            default:
              return false
          }
        } else if (data?.value && !(data?.value as RangeType)?.rangeKey) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof PaymentRow] as string | number) ||
              (key === 'assignToId' ? 'Unassigned' : NoData)
          )
        } else {
          if (data?.searchText) {
            return false
          }
          return true
        }
      })
    })
  }

  return filteredData
}

export const filterPaymentsBySearchText = (
  paymentList: PaymentRow[] | undefined,
  searchText: string | undefined
): PaymentRow[] | [] => {
  if (!searchText) {
    return paymentList || []
  }
  let filtered = paymentList || []
  const lowerCaseText = searchText.toLowerCase()
  filtered =
    paymentList?.filter((item) => {
      for (const objKey in item) {
        const key = objKey as keyof typeof item
        if (
          key !== 'rowData' &&
          item[key]?.toString()?.toLowerCase().includes(lowerCaseText)
        ) {
          return true
        }
      }
      return false
    }) || []
  return filtered
}

export const filterPastPaymentBySearchText = (
  paymentList: IPastPayment[],
  searchText: string
): IPastPayment[] | [] => {
  if (!searchText) {
    return paymentList
  }
  //let filtered = paymentList || []
  const lowerCaseText = searchText.toLowerCase()
  const filtered = paymentList.filter((item) => {
    if (
      item?.tranInfo?.beneficiaryBankRoutingId
        ?.toLocaleLowerCase()
        .includes(lowerCaseText) ||
      item?.tranInfo?.beneficiaryBankName
        ?.toLocaleLowerCase()
        .includes(lowerCaseText) ||
      item?.tranInfo?.beneficiaryAcctId
        ?.toLocaleLowerCase()
        .includes(lowerCaseText) ||
      item?.tranInfo?.beneficiaryName
        ?.toLocaleLowerCase()
        .includes(lowerCaseText) ||
      item?.tranInfo?.trnAmt?.toString().includes(lowerCaseText) ||
      item?.tranInfo?.trnDateTime
        ?.toLocaleLowerCase()
        .includes(lowerCaseText) ||
      item?.tranInfo?.destinationCountry
        ?.toLocaleLowerCase()
        .includes(lowerCaseText)
    ) {
      return true
    } else {
      return false
    }
  })
  return filtered
}

export const filterStandingInstBySearchText = (
  paymentList: IStandingInstruction[],
  searchText: string
): IStandingInstruction[] | [] => {
  if (!searchText) {
    return paymentList
  }
  let filtered = paymentList || []
  const lowerCaseText = searchText.toLowerCase()
  filtered = paymentList.filter((item) => {
    if (
      item?.routeId?.toLocaleLowerCase().includes(lowerCaseText) ||
      item?.MMBeneficiaryAccount?.BankName?.toLocaleLowerCase().includes(
        lowerCaseText
      ) ||
      item?.MMBeneficiaryAccount?.BeneficiaryAccountNumber?.toLocaleLowerCase().includes(
        lowerCaseText
      ) ||
      item?.MMBeneficiaryAccount?.BeneficiaryNameLine?.NameLine1?.toLocaleLowerCase().includes(
        lowerCaseText
      ) ||
      item?.DestinationCountry?.toLocaleLowerCase().includes(lowerCaseText)
    ) {
      return true
    } else {
      return false
    }
  })
  return filtered
}

export const filterEJStandingInstBySearchText = (
  paymentList: IMMSummarySI[],
  searchText: string
): IMMSummarySI[] => {
  if (!searchText) {
    return paymentList
  }
  const lowerCaseText = searchText.toLowerCase()
  const filtered = paymentList.filter((item) => {
    if (
      (lowerCaseText === 'disbursement' && item.isrecepientonly === false) ||
      (lowerCaseText === 'receipt' && item.isrecepientonly === true) ||
      ((lowerCaseText === 'third party' || lowerCaseText === 'thirdparty') &&
        item.isthirdparty === true) ||
      ((lowerCaseText === 'first party' || lowerCaseText === 'firstparty') &&
        item.isthirdparty === false) ||
      (lowerCaseText === 'third party common' &&
        item.isthirdpartycommon === true) ||
      item?.RoutingNo?.toLocaleLowerCase().includes(lowerCaseText) ||
      item?.bankname?.toLocaleLowerCase().includes(lowerCaseText) ||
      item?.bankaccountnumber?.toLocaleLowerCase().includes(lowerCaseText) ||
      item?.bankaccountname?.toLocaleLowerCase().includes(lowerCaseText) ||
      item?.destinationcountry?.toLocaleLowerCase().includes(lowerCaseText)
    ) {
      return true
    }
  })

  return filtered || paymentList
}

const SwiftCodeorBankidentifier = (paymentMM: PaymentMM | undefined) => {
  if (
    paymentMM?.tranInfo?.wireTypeCondition === 'C' &&
    paymentMM?.intlMMinfo?.toCurrency !== 'USD'
  ) {
    return paymentMM?.tranInfo?.beneficiaryBankRoutingId || ''
  } else {
    return paymentMM?.tranInfo?.beneficiaryAcctId
  }
}
export const getReceiptorDisbursement = (paymentMM: PaymentMM | undefined) => {
  if (paymentMM?.xferType === 'AD') {
    if (paymentMM.tranInfo?.isReceive) {
      return ReceiveorDisbursement.Receipt
    } else {
      return ReceiveorDisbursement.Disbursement
    }
  }
  return undefined
}

const getEwsSelection = (paymentMM: PaymentMM | undefined) => {
  if (paymentMM?.xferType === TransactionType.EFT) {
    if (paymentMM?.tranInfo?.isSIOnly) {
      return 'Option2'
    } else {
      return 'Option1'
    }
  }
  return ''
}

export const prepareWireDataForEdit = (
  paymentMM: PaymentMM | undefined
): IWireInitiationForm | IJournalInitiationForm => {
  if (paymentMM?.xferType === TransactionType.Journal) {
    const jnlFormData = prepareJournalDataForEdit(paymentMM)
    if (paymentMM?.tranInfo?.isRecurringPaymentPlan) {
      const periordicPaymentData = getPeriodicPaymentDataForEdit(paymentMM)
      return {
        ...jnlFormData,
        periodicPayment: periordicPaymentData,
        wireAmount: { ...jnlFormData.wireAmount, tranDate: new Date() }
      }
    }
    return jnlFormData
  }
  const formData = {
    wirePaymentId: paymentMM?.mmInstID,
    hasRecurringPaymentToday: paymentMM?.hasRecurringPaymentToday,
    paymentType: paymentMM?.tranType,
    wireType: paymentMM?.tranInfo?.wireTypeCondition,
    executingCustodian: paymentMM?.tranInfo?.executingCustodian,
    docInfo: paymentMM?.docInfo,
    amaLevel: paymentMM?.amaLevel,
    mmStatusDescription: paymentMM?.mmStatusDescription,
    mmStatus: paymentMM?.mmStatus,
    ewsSelection: getEwsSelection(paymentMM),
    accountHolder: {
      taxID: paymentMM?.apprDetail?.accountHolderSelected
    },
    beneficiary: {
      bankIdentifierNumber:
        paymentMM?.tranInfo?.beneficiaryBankRoutingType === 'A' ||
        (paymentMM?.tranInfo?.beneficiaryBankRoutingType === 'S' &&
          paymentMM?.tranInfo?.wireTypeCondition === 'B')
          ? paymentMM?.tranInfo?.beneficiaryBankRoutingId
          : '',
      bankName: paymentMM?.tranInfo?.beneficiaryBankName || '',
      account:
        paymentMM?.tranInfo?.wireTypeCondition === 'C' &&
        paymentMM?.intlMMinfo?.toCurrency === 'USD'
          ? '--'
          : paymentMM?.tranInfo?.beneficiaryAcctId || '',
      swiftCode:
        paymentMM?.tranInfo?.beneficiaryBankRoutingType === 'S' ||
        (paymentMM?.tranInfo?.beneficiaryBankRoutingType === 'A' &&
          paymentMM?.tranInfo?.wireTypeCondition === 'C')
          ? SwiftCodeorBankidentifier(paymentMM)
          : '',
      name: paymentMM?.tranInfo?.beneficiaryName || '',
      line1: paymentMM?.tranInfo?.beneficiaryNameAddrLine1 || '',
      line2: paymentMM?.tranInfo?.beneficiaryNameAddrLine2 || '',
      line3: paymentMM?.tranInfo?.beneficiaryNameAddrLine3 || '',
      createNewSI: paymentMM?.tranInfo?.createNewSI || false,
      maxDistAmt: paymentMM?.tranInfo?.internalMessage
        ? paymentMM?.tranInfo?.internalMessage
        : undefined,
      accountType: paymentMM?.tranInfo?.bankAcctFromAcctType
        ? getAccountType(paymentMM?.tranInfo?.bankAcctFromAcctType)
        : getAccountType(paymentMM?.tranInfo?.beneficiaryAcctType || ''),
      SIMMDirection: paymentMM?.tranInfo?.simmDirection,
      isSIBoth: paymentMM?.tranInfo?.simmDirection === SIMMDirection.Both
    },
    details: {
      memo: paymentMM?.tranInfo?.memo,
      line1: paymentMM?.tranInfo?.wireDisbursementDetail1 || '',
      line2: paymentMM?.tranInfo?.wireDisbursementDetail2 || '',
      line3: paymentMM?.tranInfo?.wireDisbursementDetail3 || '',
      line4: paymentMM?.tranInfo?.wireDisbursementDetail4 || '',
      sendDisbDetail: paymentMM?.tranInfo?.sendDisbDetail
    },
    furtherCredit1: {
      account: paymentMM?.tranInfo?.furtherCredit1_AccountNumber || '',
      name: paymentMM?.tranInfo?.furtherCredit1_NameLine1 || '',
      line1: paymentMM?.tranInfo?.furtherCredit1_NameLine2 || '',
      line2: paymentMM?.tranInfo?.furtherCredit1_NameLine3 || '',
      line3: paymentMM?.tranInfo?.furtherCredit1_NameLine4 || '',
      hasFC: paymentMM?.tranInfo?.furtherCredit1_AccountNumber ? true : false
    },
    furtherCredit2: {
      account: paymentMM?.tranInfo?.furtherCredit2_AccountNumber || '',
      name: paymentMM?.tranInfo?.furtherCredit2_NameLine1 || '',
      line1: paymentMM?.tranInfo?.furtherCredit2_NameLine2 || '',
      line2: paymentMM?.tranInfo?.furtherCredit2_NameLine3 || '',
      line3: paymentMM?.tranInfo?.furtherCredit2_NameLine4 || '',
      hasFC: paymentMM?.tranInfo?.furtherCredit2_AccountNumber ? true : false
    },
    senderDetails: {
      account: paymentMM?.tranInfo?.bankAcctFromAcctId || '',
      name: paymentMM?.tranInfo?.bankAcctFromName,
      isRetirementAccount: paymentMM?.tranInfo?.isIRA,
      availablecashtowithdraw: paymentMM?.tranInfo?.availablecashtowithdraw,
      registrationtype: paymentMM?.accountRegType
    },
    wireAmount: {
      country: paymentMM?.tranInfo?.destinationCountry || '',
      useExistingSI: paymentMM?.tranInfo?.useExistingSI,
      standingInstructionId: paymentMM?.tranInfo?.standingInstructionId,
      currency: paymentMM?.intlMMinfo?.toCurrency || 'USD',
      recipientType: getrecipient(paymentMM),
      amount: paymentMM?.tranInfo?.trnAmt?.toString() || '',
      rate: paymentMM?.intlMMinfo?.xchngRate,
      tranDate: paymentMM?.tranDate ? new Date(paymentMM?.tranDate) : undefined,
      expirationDate: paymentMM?.expirationDate
        ? new Date(paymentMM?.expirationDate)
        : undefined,
      useType9Income: paymentMM?.tranInfo?.useType9Income,
      accountCloseout: paymentMM?.tranInfo?.accountCloseout,
      acceptsUSD: paymentMM?.tranInfo?.recipientBankAcceptUSD,
      insufFundSolutionId: paymentMM?.apprDetail?.insufFundSolution,
      insufFundComments: paymentMM?.apprDetail?.insufFund,
      isexternal: getReceiptorDisbursement(paymentMM),
      transactionType: getReceiptorDisbursement(paymentMM)
    },
    taxWithholdings: {
      retDistCode: paymentMM?.tranInfo?.reasoncode,
      retDistReason: paymentMM?.tranInfo?.reasondesc,
      conReasonCode: paymentMM?.tranInfo?.conreasoncode,
      conReasonDesc: paymentMM?.tranInfo?.conreasondesc,
      conReasonSubcode: paymentMM?.tranInfo?.conreasonsubcode,
      conPromptYearInd: paymentMM?.tranInfo?.conpromptyearind,
      year: paymentMM?.tranInfo?.currentyear || paymentMM?.tranInfo?.prioryear,
      fedTaxOption: paymentMM?.tranInfo?.canfedwithhold,
      stateTaxOption: paymentMM?.tranInfo?.canstatewithhold,
      fedTaxPct: paymentMM?.tranInfo?.fedwithholdpct,
      stateTaxPct: paymentMM?.tranInfo?.statewithholdpct,
      fedTaxRate: paymentMM?.tranInfo?.fedwithholdpct,
      stateTaxRate: paymentMM?.tranInfo?.statewithholdpct,
      includeTax: !(paymentMM?.tranInfo?.istrnamtincludetaxwithhold || false),
      AMAacknowledgement: paymentMM?.tranInfo?.amAacknowledgement,
      statewithholdminpct: paymentMM?.tranInfo?.statewithholdminpct,
      statewithholdmaxpct: paymentMM?.tranInfo?.statewithholdmaxpct,
      fedwithholdminpct: paymentMM?.tranInfo?.fedwithholdminpct,
      fedwithholdmaxpct: paymentMM?.tranInfo?.fedwithholdmaxpct
    }
  }
  if (paymentMM?.tranInfo?.isRecurringPaymentPlan) {
    const periordicPaymentData = getPeriodicPaymentDataForEdit(paymentMM)
    return {
      ...formData,
      periodicPayment: periordicPaymentData,
      wireAmount: { ...formData.wireAmount, tranDate: new Date() }
    }
  }
  return formData
}

export const prepareAccountSearchItem = (
  accountData: IFeeAccount
): IAccount => {
  return {
    CustodyAccount: accountData.custodyAccount,
    LegalEntityName: accountData.legalEntityName,
    registrationtype: accountData.registrationtype,
    AdvisorAddedNickName: accountData.AdvisorAddedNickName,
    Shortname: accountData.shortname,
    ClientAdvisorID: accountData.clientAdvisorID,
    CustodianName: accountData.custodianName,
    registrationDesc: accountData.registrationDesc,
    gfoCustodyAccount: accountData.gfoCustodyAccount,
    id: accountData.id,
    retirementAccount: accountData.retirementAccount,
    householdId: accountData.householdId
  }
}

export const prepareDisbursmentApprovalForReview = (
  paymentMM: PaymentMM | undefined
): IPaymentVerificationForm => {
  const sanitizeNameLength =
    paymentMM?.apprDetail?.instPerson?.split('Other:').length

  const otherPerson = sanitizeNameLength
    ? paymentMM?.apprDetail?.instPerson
        ?.split('Other:')
        [sanitizeNameLength - 1].trimLeft()
    : paymentMM?.apprDetail?.instPerson ?? ''

  return {
    methodOfInstruction: paymentMM?.apprDetail?.instMethod || '',
    dateOfInstruction: paymentMM?.apprDetail?.instDate
      ? new Date(paymentMM?.apprDetail?.instDate)
      : undefined,
    clientAuthMethods: getClientAuthMethodsArray(paymentMM),
    clientAuthorization: paymentMM?.apprDetail?.isEauth === 'Y',
    phoneNumber:
      paymentMM?.apprDetail?.instCallType === 'Other'
        ? paymentMM?.apprDetail?.instCallType
        : paymentMM?.apprDetail?.phoneNo ?? '',
    manualPhoneNo:
      paymentMM?.apprDetail?.instCallType === 'Other'
        ? paymentMM?.apprDetail?.phoneNo
        : '',
    phoneNumberSource: paymentMM?.apprDetail?.phoneSr ?? '',
    additinalComment: paymentMM?.apprDetail?.pwAcomment ?? '',
    authorizedPerson: paymentMM?.apprDetail?.instPerson ?? '',
    authorizedPersonCustom: otherPerson,
    purposeOfFund: paymentMM?.apprDetail?.purpose,
    purposeOfFundCustom: paymentMM?.apprDetail?.purpose,
    authenticateEmployee: paymentMM?.apprDetail?.authenticateEmployee,
    callBackNeeded: paymentMM?.callBackNeeded
  }
}

const getClientAuthMethodsArray = (
  paymentMM: PaymentMM | undefined
): string[] => {
  const authMethods = []
  if (paymentMM?.apprDetail?.clnDetail === 'Y') {
    authMethods.push('ClnDetail')
  }
  // if (paymentMM?.apprDetail?.isEauth === 'Y') {
  //   authMethods.push('isEauth')
  // }
  if (paymentMM?.apprDetail?.doB === 'Y') {
    authMethods.push('DoB')
  }
  if (paymentMM?.apprDetail?.inPerson === 'Y') {
    authMethods.push('inPerson')
  }
  if (paymentMM?.apprDetail?.last4SSN === 'Y') {
    authMethods.push('Last4SSN')
  }
  if (paymentMM?.apprDetail?.recentAct === 'Y') {
    authMethods.push('RecentAct')
  }

  if (paymentMM?.apprDetail?.clnRelationship === 'Y') {
    authMethods.push('ClnRelationship')
  }
  if (paymentMM?.apprDetail?.voiceRcgn === 'Y') {
    authMethods.push('VoiceRcgn')
  }
  if (paymentMM?.apprDetail?.clientPasscode) {
    authMethods.push('ClnPasscode')
  }
  return authMethods
}

export const clientAuthMethods: {
  key: string
  text: string
  oltKey: string
}[] = [
  // { key: 'isEauth', text: 'E-Auth', oltKey: 'oltReviewEAuth' },

  { key: 'inPerson', text: 'In-Person', oltKey: 'oltReviewInPerson' },
  { key: 'DoB', text: 'Date of Birth', oltKey: 'oltReviewDOB' },
  {
    key: 'Last4SSN',
    text: 'Last 4 digits of SSN/TIN',
    oltKey: 'oltReviewLast4SSN'
  },
  {
    key: 'RecentAct',
    text: 'Recent Account Activity',
    oltKey: 'oltReviewAcctActivity'
  },
  {
    key: 'ClnDetail',
    text: 'Client Personal Details',
    oltKey: 'oltReviewClientDetail'
  },
  {
    key: 'ClnRelationship',
    text: 'Client Household Relationship Details',
    oltKey: 'oltReviewClientRelationship'
  },
  {
    key: 'VoiceRcgn',
    text: 'Voice Recognition',
    oltKey: 'oltReviewVocieRecognition'
  }
  // {
  //   key: 'ClnPasscode',
  //   text: 'Client Passcode',
  //   oltKey: 'oltReviewClientPasscode'
  // }
]

export const clientInfoReviewed: { key: string; text: string; name: string }[] =
  [
    {
      key: 'ICPDetails',
      text: 'Transaction Details',
      name: 'oltReviewICPDetail'
    },
    {
      key: 'ClientLOAReview',
      text: 'Client LOA / Review',
      name: 'oltReviewClientLOA'
    },
    {
      key: 'Phone#Source',
      text: 'Phone # Source',
      name: 'oltReviewPhoneSrcCheck'
    },
    {
      key: 'ClientDataChanges',
      text: 'Client Data Changes (30 Days)',
      name: 'oltReviewClientDataChange'
    },
    {
      key: 'InLineWithPlannedPriorAct',
      text: 'In line with planned / Prior Act',
      name: 'oltReviewAsPlanned'
    },
    {
      key: 'FraudReleatedRedFlags',
      text: 'Fraud releated red flags',
      name: 'oltReviewFraudRedFlag'
    }
  ]

export const generateOLTReviewRequest = (
  reviewRequest: IPaymentReviewRequest,
  formData: IOMTApproverForm
): IPaymentReviewRequest => {
  return {
    RCMTranRefId: reviewRequest.RCMTranRefId,
    QueueId: reviewRequest.QueueId,
    Status: reviewRequest.Status,
    Comment: reviewRequest.Comment,
    oltReviewBy: formData?.loggedInUser,
    oltReviewWaiveCallback: formData?.waiveCallback || false,
    oltReviewCallBackPhone:
      formData?.phoneNumber === 'Other'
        ? formData?.manualPhoneNo?.replace(/[^\d]/g, '') || ''
        : formData?.phoneNumber?.replace(/[^\d]/g, '') || '',
    oltReviewPhoneNumberSrc:
      formData?.phoneNumber === 'Other'
        ? formData?.phoneSource || ''
        : undefined,
    oltReviewCallBackDate: formData?.dateOfCallback
      ? format(formData?.dateOfCallback, 'MM/dd/yyyy')
      : undefined,
    oltReviewEAuth: formData?.clientAuthenticationMethods?.includes('isEauth')
      ? 'Y'
      : undefined,
    oltReviewInPerson: formData?.clientAuthenticationMethods?.includes(
      'inPerson'
    )
      ? 'Y'
      : undefined,
    oltReviewDOB: formData?.clientAuthenticationMethods?.includes('DoB')
      ? 'Y'
      : undefined,
    oltReviewLast4SSN: formData?.clientAuthenticationMethods?.includes(
      'Last4SSN'
    )
      ? 'Y'
      : undefined,
    oltReviewAcctActivity: formData?.clientAuthenticationMethods?.includes(
      'RecentAct'
    )
      ? 'Y'
      : undefined,
    oltReviewClientDetail: formData?.clientAuthenticationMethods?.includes(
      'ClnDetail'
    )
      ? 'Y'
      : undefined,
    oltReviewClientRelationship:
      formData?.clientAuthenticationMethods?.includes('ClnRelationship')
        ? 'Y'
        : undefined,
    oltReviewVocieRecognition: formData?.clientAuthenticationMethods?.includes(
      'VoiceRcgn'
    )
      ? 'Y'
      : undefined,
    oltReviewICPDetail: formData?.clientInformationInstructions?.includes(
      'ICPDetails'
    )
      ? 'Y'
      : undefined,
    oltReviewClientLOA: formData?.clientInformationInstructions?.includes(
      'ClientLOAReview'
    )
      ? 'Y'
      : undefined,
    oltReviewPhoneSrcCheck: formData?.clientInformationInstructions?.includes(
      'Phone#Source'
    )
      ? 'Y'
      : undefined,
    oltReviewClientDataChange:
      formData?.clientInformationInstructions?.includes('ClientDataChanges')
        ? 'Y'
        : undefined,
    oltReviewAsPlanned: formData?.clientInformationInstructions?.includes(
      'InLineWithPlannedPriorAct'
    )
      ? 'Y'
      : undefined,
    oltReviewFraudRedFlag: formData?.clientInformationInstructions?.includes(
      'FraudReleatedRedFlags'
    )
      ? 'Y'
      : undefined,
    oltReviewInstrProvidedBy:
      formData?.authorizedPerson === 'Other'
        ? formData?.authorizedPersonCustom || ''
        : formData?.authorizedPerson || ''
  }
}

export const getLocalDateTime = (value: string) => {
  const offset = new Date().getTimezoneOffset()
  const utcDate = new Date(value)
  return new Date(utcDate.setMinutes(utcDate.getMinutes() - offset))
}
export const getCommonValidateRequest = (
  account?: string,
  custodianID?: string
): IMMValidateRequest => {
  return {
    moneymovementid: guid(),
    ismodeone: true,
    fromaccount: {
      accountnumber: account,
      isira: undefined,
      accounttype: '',
      abanumber: '',
      isexternal: false,
      bankname: ''
    },
    executingCustodian: custodianID,
    toaccount: {
      accountnumber: account,
      isthirdparty: false,
      isexternal: false,
      isira: undefined
    },
    iseft: false,
    isjnl: false,
    isira: undefined,
    iswire: false,
    loi: false,
    portalid: undefined,
    OvernightCheck: false,
    iscancel: false,
    isfuturedated: false
  }
}
export const getMMValidateReguestForHoldings = (
  account: string,
  custodianID: string,
  isRetirementAccount?: boolean,
  paymentType?: string,
  isExternal?: string,
  jnlParams?: {
    fromAccount?: { accountnumber?: string; isira?: boolean }
    toAccount?: { accountnumber?: string; isira?: boolean }
  }
): IMMValidateRequest => {
  const request = getCommonValidateRequest(account, custodianID)

  if (paymentType === TransactionType.Wire) {
    return {
      ...request,
      iswire: paymentType === TransactionType.Wire,
      isira: isRetirementAccount,
      fromaccount: {
        ...request.fromaccount,
        isira: isRetirementAccount
      }
    }
  } else if (paymentType === TransactionType.EFT) {
    const eftRequest = {
      ...request,
      isira: isRetirementAccount,
      iseft: paymentType === TransactionType.EFT,
      fromaccount: {
        ...request.fromaccount,
        accountnumber:
          isExternal === ReceiveorDisbursement.Receipt ? '' : account,
        isexternal: isExternal === ReceiveorDisbursement.Receipt,
        isira: isExternal === ReceiveorDisbursement.Disbursement
      },
      toaccount: {
        ...request.toaccount,
        accountnumber:
          isExternal === ReceiveorDisbursement.Disbursement ? '' : account,
        isexternal: isExternal === ReceiveorDisbursement.Disbursement,
        isira: isExternal === ReceiveorDisbursement.Receipt
      }
    }
    return eftRequest
  } else if (paymentType === TransactionType.Journal) {
    const jnlRequest = {
      ...request,
      isjnl: paymentType === TransactionType.Journal,
      isira: jnlParams?.fromAccount?.isira || jnlParams?.toAccount?.isira,
      fromaccount: {
        ...request.fromaccount,
        accountnumber: jnlParams?.fromAccount?.accountnumber,
        isira: jnlParams?.fromAccount?.isira,
        accounttype: '',
        abanumber: '',
        bankname: ''
      },
      toaccount: {
        ...request.toaccount,
        accountnumber: jnlParams?.toAccount?.accountnumber,
        isira: jnlParams?.toAccount?.isira
      }
    }
    return jnlRequest
  }

  return {}
}

export const getMMValidateRequestForCountryList = (
  account: string
): IMMValidateRequest => {
  const request = getCommonValidateRequest(account, '')
  return {
    ...request,
    iswire: true,
    retriveCountryList: true
  }
}
export const getRetirementDistribution = (wireData: IWireInitiationForm) => {
  return wireData?.senderDetails?.isRetirementAccount &&
    wireData?.taxWithholdings?.retDistReason
    ? [
        {
          reasoncode: wireData?.taxWithholdings?.retDistCode,
          reasondesc: wireData?.taxWithholdings?.retDistReason
        }
      ]
    : undefined
}

export const generateMMValidateRequestForWire = (
  wireData: IWireInitiationForm,
  wireTypeCondition?: string
): IMMValidateRequest => {
  const wireType =
    wireData.wireType && !isEmpty(wireData.wireType)
      ? wireData.wireType
      : wireTypeCondition

  const request: IMMValidateRequest = {
    ...getCommonValidateRequest(
      wireData?.senderDetails?.account,

      wireData.executingCustodian
    ),
    ismodeone: false,
    iswire: true,
    fromaccount: {
      accountnumber: wireData?.senderDetails?.account ?? undefined,
      isexternal: false,
      isira: wireData?.senderDetails?.isRetirementAccount ?? false
    },
    toaccount: {
      accountnumber: wireData?.beneficiary?.account ?? undefined,
      accountname: wireData?.beneficiary?.name ?? undefined,
      bankidtype: wireType === 'A' ? 'F' : 'S',
      abanumber:
        wireData?.beneficiary?.bankIdentifierNumber?.replace(/\s+/g, '') ||
        undefined,
      swiftcode:
        wireData?.wireAmount?.country !== 'US'
          ? wireData?.beneficiary?.bankIdentifierNumber?.replace(/\s+/g, '') ??
            undefined
          : undefined,
      bankname: wireData?.beneficiary?.bankName || undefined,
      isthirdparty: wireData?.wireAmount?.recipientType === 'Third Party',
      isexternal: true
    },
    BenNameAddrLine1: wireData?.beneficiary?.name ?? undefined,
    BenNameAddrLine2: wireData?.beneficiary?.line1 ?? undefined,
    BenNameAddrLine3: wireData?.beneficiary?.line2 ?? undefined,
    BenNameAddrLine4: wireData?.beneficiary?.line3 ?? undefined,
    FurtherCredit1_AccountNumber: wireData?.furtherCredit1?.hasFC
      ? wireData?.furtherCredit1?.account ?? undefined
      : undefined,
    FurtherCredit1_NameLine1: wireData?.furtherCredit1?.hasFC
      ? wireData?.furtherCredit1?.name ?? undefined
      : undefined,
    FurtherCredit1_NameLine2: wireData?.furtherCredit1?.hasFC
      ? wireData?.furtherCredit1?.line1 ?? undefined
      : undefined,
    FurtherCredit1_NameLine3: wireData?.furtherCredit1?.hasFC
      ? wireData?.furtherCredit1?.line2 ?? undefined
      : undefined,
    FurtherCredit1_NameLine4: wireData?.furtherCredit1?.hasFC
      ? wireData?.furtherCredit1?.line3 ?? undefined
      : undefined,
    FurtherCredit2_AccountNumber: wireData?.furtherCredit2?.hasFC
      ? wireData?.furtherCredit2?.account ?? undefined
      : undefined,
    FurtherCredit2_NameLine1: wireData?.furtherCredit2?.hasFC
      ? wireData?.furtherCredit2?.name ?? undefined
      : undefined,
    FurtherCredit2_NameLine2: wireData?.furtherCredit2?.hasFC
      ? wireData?.furtherCredit2?.line1 ?? undefined
      : undefined,
    FurtherCredit2_NameLine3: wireData?.furtherCredit2?.hasFC
      ? wireData?.furtherCredit2?.line2 ?? undefined
      : undefined,
    FurtherCredit2_NameLine4: wireData?.furtherCredit2?.hasFC
      ? wireData?.furtherCredit2?.line3 ?? undefined
      : undefined,
    wiredisbursementdetail1: wireData?.details?.line1 ?? undefined,
    wiredisbursementdetail2: wireData?.details?.line2 ?? undefined,
    wiredisbursementdetail3: wireData?.details?.line3 ?? undefined,
    wiredisbursementdetail4: wireData?.details?.line4 ?? undefined,
    amount: getAmountForMMValidate(wireData),
    closeout: wireData?.wireAmount?.accountCloseout ? 'Y' : undefined,
    destinationcountry: wireData?.wireAmount?.country ?? undefined,
    isira: wireData?.senderDetails?.isRetirementAccount,
    retdistreason: getRetirementDistribution(wireData),
    AMAacknowledgement:
      wireData?.taxWithholdings?.retDistCode !== '3' &&
      wireData?.taxWithholdings?.retDistCode !== 'G'
        ? wireData?.taxWithholdings?.AMAacknowledgement
        : undefined,
    transactiondate: processDate(wireData),

    fedwithholdpct: wireData?.senderDetails?.isRetirementAccount
      ? Number(wireData?.taxWithholdings?.fedTaxRate)
      : undefined,
    statewithholdpct: wireData?.senderDetails?.isRetirementAccount
      ? Number(wireData?.taxWithholdings?.stateTaxRate)
      : undefined,
    istrnamtincludetaxwithhold: wireData?.senderDetails?.isRetirementAccount
      ? !(wireData?.taxWithholdings?.includeTax || false)
      : false,
    fedwithholdmaxpct: wireData?.senderDetails?.isRetirementAccount
      ? wireData?.taxWithholdings?.fedwithholdmaxpct ?? 0
      : undefined,
    fedwithholdminpct: wireData?.senderDetails?.isRetirementAccount
      ? wireData?.taxWithholdings?.fedwithholdminpct ?? 0
      : undefined,
    statewithholdmaxpct: wireData?.senderDetails?.isRetirementAccount
      ? wireData?.taxWithholdings?.statewithholdmaxpct ?? 0
      : undefined,
    statewithholdminpct: wireData?.senderDetails?.isRetirementAccount
      ? wireData?.taxWithholdings?.statewithholdminpct ?? 0
      : undefined
  }
  if (wireData.wireType === 'C') {
    if (request.toaccount) {
      request.toaccount.bankidtype = 'F'
      request.toaccount.swiftcode = wireData?.beneficiary?.swiftCode || ''
      request.toaccount.abanumber =
        wireData?.beneficiary?.bankIdentifierNumber?.replace(/\s+/g, '') ||
        undefined
      request.toaccount.accountnumber = wireData?.beneficiary?.swiftCode || ''
    }
  }

  return updateMappingsBeforeValidate(request, wireData)
}

export const processDate = (wireData: IWireInitiationForm) => {
  const planStartDate = wireData?.periodicPayment?.dateInfo?.StartDate
  if (wireData?.periodicPayment?.isRecurringPaymentPlan && planStartDate) {
    return format(planStartDate, 'yyyy-MM-dd')
  } else if (wireData?.wireAmount?.tranDate) {
    return format(wireData?.wireAmount?.tranDate, 'yyyy-MM-dd')
  }
  return format(new Date(), 'yyyy-MM-dd')
}

export const guid = () => {
  const uuidFormat = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  const nums = window.crypto.getRandomValues(
    new Uint8ClampedArray(uuidFormat.split(/[xy]/).length - 1)
  )
  let pointer = 0
  return uuidFormat.replace(/[xy]/g, function (c) {
    const r = nums[pointer++] % 16,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const checkObjectPropertiesHasData = (object: any) => {
  let result = false
  if (object) {
    const keys = Object.keys(object)
    const properyCheck = keys?.map((key) => {
      return (
        object[key] !== null && object[key] !== undefined && object[key] !== ''
      )
    })
    result = properyCheck.includes(true)
  }
  return result
}
export const truncateFFCFields = (
  value: string | undefined,
  len?: number | 34
) => {
  if (value) {
    return value.substring(0, len)
  }
  return ''
}

export const getConfigData = (
  configData?: IMMConfig[],
  custodian?: string
): IMMConfig[] => {
  const data: IMMConfig[] =
    configData?.filter(
      (x: IMMConfig) =>
        x.custodianName === custodian || x.custodianID === custodian
    ) ?? []
  return data
}

export const checkInValidCharacter = (
  value: string | undefined,
  fieldName?: string
) => {
  if (!value) {
    return
  }

  const getPattern =
    fieldName === FieldNamesPattern.DFDisclosure
      ? '[' + SpecialCharacterPattern(fieldName ?? '') + ']'
      : '[^' + SpecialCharacterPattern(fieldName ?? '') + ']'

  const pattern = new RegExp(getPattern, 'gi')

  let foundArr: RegExpExecArray | null
  const unwantedChars = new Set()

  while ((foundArr = pattern.exec(value)) !== null) {
    unwantedChars.add(foundArr[0])
  }
  return Array.from(unwantedChars)
}

export const isValidCharacter = (
  value: string | undefined,
  fieldName?: string
) => {
  const getPattern = '[' + SpecialCharacterPattern(fieldName ?? '') + ']'
  const pattern = new RegExp(getPattern, 'gi')
  if (value) {
    if (pattern.test(value.substring(value.length - 1))) {
      return {
        allowedValue: value,
        notAllowedValue: checkInValidCharacter(value, fieldName)
      }
    }
  }

  return {
    allowedValue: value,
    notAllowedValue: checkInValidCharacter(value, fieldName)
  }
}
export const isNumberWithNoDecimal = () => {
  const maskFormat: { [key: string]: RegExp } = {
    '*': /[0-9]/
  }
  return maskFormat
}

export const replaceCharacterOnName = (value: string | undefined) => {
  return value && value.replaceAll('/', ' ').replaceAll('&', '+')
}

export const updatedRequest = (
  payload: IWireInitiationForm,
  data?: IMMValidateResponse,
  iseft?: boolean
): IWireInitiationForm => {
  if (
    data?.toaccount?.isexternal &&
    iseft &&
    payload.wireAmount?.useExistingSI
  ) {
    return {
      ...payload,
      wireAmount: {
        ...payload.wireAmount,
        isThirdPartyCommon: data?.toaccount?.isthirdpartycommon
      }
    }
  } else if (
    data?.fromaccount?.isexternal &&
    iseft &&
    payload.wireAmount?.useExistingSI
  ) {
    return {
      ...payload,
      wireAmount: {
        ...payload.wireAmount,
        isThirdPartyCommon: data?.fromaccount?.isthirdpartycommon
      }
    }
  } else {
    return payload
  }
}

export const isProcessDate = (
  isCustodianTypeJPM?: boolean,
  cutOffWarning?: string
) => {
  if (!cutOffWarning) {
    return
  }
  const utcDate = getUTCTimetoLocal()
  const timeParts = getTimeParts(cutOffWarning)
  if (
    isCustodianTypeJPM &&
    (utcDate.getHours() > parseInt(timeParts.Hour) ||
      (utcDate.getHours() === parseInt(timeParts.Hour) &&
        utcDate.getMinutes() >= parseInt(timeParts.Minutes)))
  ) {
    if (utcDate.getDay() === 5 || utcDate.getDay() === 6) {
      if (utcDate.getDay() === 6) {
        return new Date(utcDate.setDate(utcDate.getDate() + 2))
      }
      return new Date(utcDate.setDate(utcDate.getDate() + 3))
    }
    return new Date(utcDate.setDate(new Date().getDate() + 1))
  } else {
    return undefined
  }
}

export const nfsWarningMsg = (
  showCurrencyMessage?: boolean,
  currency?: string,
  cutOffWarning?: string
) => {
  if (!showCurrencyMessage || !currency || !cutOffWarning) {
    return
  }
  const date = getUTCTimetoLocal()
  const timeParts = getTimeParts(cutOffWarning)

  if (
    (showCurrencyMessage &&
      currency === 'USD' &&
      date.getHours() > parseInt(timeParts.Hour)) ||
    (date.getHours() === parseInt(timeParts.Hour) &&
      date.getMinutes() >= parseInt(timeParts.Minutes))
  ) {
    const time =
      (parseInt(timeParts.Hour) % 12).toString() + ':' + timeParts.Minutes
    return `Past daily processing cut off time of ${time}PM EST. This transaction will be processed on a best-efforts basis`
  } else if (currency !== 'USD') {
    return 'Transaction needs to be fully approved by 3:00 PM for next day execution.'
  } else {
    return undefined
  }
}
export const getUTCTimetoLocal = () => {
  const date = new Date()

  const utcDate = new Date(
    Date.parse(
      date.toLocaleString('en-US', {
        timeZone: 'America/New_York'
      })
    )
  )

  return utcDate
}

export const getTimeParts = (time: string) => {
  const timeParts = time.trim().split(':')
  return { Hour: timeParts[0], Minutes: timeParts[1] }
}

export const getFilteredApprovalList = (
  paymentApprovalDetails?: IApproveDetail[]
): IApproveDetail[] => {
  const approvalList =
    paymentApprovalDetails?.filter(
      (item) =>
        item.status?.toLowerCase() !== 'pending' &&
        item.queueId?.toLowerCase() !== 'ofac'
    ) ?? []
  return approvalList
}

export const getRestrictedDateList = (holidayList: {
  [x: string]: IHolidayItem[]
}) => {
  const dates: Date[] = []

  Object.keys(holidayList)?.forEach((x) => {
    holidayList[x]?.forEach((item: { date: string | number | Date }) => {
      if (item.date) {
        dates.push(new Date(item.date))
      }
    })
  })
  return dates
}

export const checkAddressLines = (addressLines?: string, lineNo?: string) => {
  if (!addressLines) {
    return
  }
  if (addressLines.trim() === '') {
    return `Please remove space from Address Line ${lineNo}`
  }

  return true
}
export const getSwiftorAbaNumber = (
  bankRoutingNumber?: string,
  swiftCode?: string
) => {
  if (!isEmpty(bankRoutingNumber)) {
    return bankRoutingNumber
  } else {
    return swiftCode
  }
}

export const disableNextButtonInitiation = (
  ...args: (boolean | undefined)[]
) => {
  const val = args.some((x) => x === true)
  return val
}

export const getPhoneNumbers = (
  isLoadingAuthList?: boolean,
  selectedAuthUser?: string,
  authUsers?: IAuthUserList[],
  accountPhNo?: IAccountDetails
) => {
  if (isLoadingAuthList) {
    return [{ key: '0', text: 'Please wait...' }]
  }
  let options: IDropdownOption[] = []
  const otr = { key: 'Other', text: 'Other', telNum: '' }
  if (selectedAuthUser !== 'Other') {
    const contact =
      authUsers
        ?.filter((x) => !isEmpty(x?.contact?.firstNm || x?.contact?.lastNm))
        .find(
          (x) =>
            x?.contact?.firstNm + ' ' + x?.contact?.lastNm === selectedAuthUser
        )?.contact || {}
    options =
      contact?.tels?.map(
        (x) =>
          ({
            ...x,
            key: (x?.telCntryCd || '') + (x?.telNum || ''),
            text: formatPhoneNumber(
              x?.telCntryCd
                ? x?.telCntryCd + '-' + (x?.telNum || '')
                : x?.telNum || ''
            )
          } as IDropdownOption)
      ) ?? []
    if (options.length === 0) {
      options =
        accountPhNo?.contact?.tels?.tel
          ?.filter((x) => !isEmpty(x?.telNum))
          .map(
            (x) =>
              ({
                key: x?.telNum ?? '',
                text: formatPhoneNumber(x?.telNum ?? '')
              } as IDropdownOption)
          ) ?? []
    }
  }

  options.push(otr)
  return options
}

export const getAuthUserList = (
  isLoadingAuthList?: boolean,
  authUsers?: IAuthUserList[]
) => {
  if (isLoadingAuthList) {
    return [{ key: '0', text: 'Please wait...' }]
  }
  const options =
    authUsers
      ?.filter((x) => !isEmpty(x?.contact?.firstNm || x?.contact?.lastNm))
      .map(
        (x) =>
          ({
            ...x,
            key: x?.contact?.firstNm + ' ' + x?.contact?.lastNm,
            text: x?.contact?.firstNm + ' ' + x?.contact?.lastNm
          } as IDropdownOption)
      ) || []
  options.push({ key: 'Other', text: 'Other' })
  return options
}

export const calendarDayProps: Partial<ICalendarDayProps> = {
  customDayCellRef: (element, date, classNames) => {
    if (element) {
      if (date.getDay() === 0 || date.getDay() === 6) {
        if (classNames?.dayOutsideBounds) {
          element?.classList?.add(classNames.dayOutsideBounds)
        }
        ;(element.children[0] as HTMLButtonElement).disabled = true
      }
    }
  }
}

export const shouldShowComponents = (
  isRecurring?: boolean,
  redirection?: boolean
) => {
  if (isRecurring) {
    if (redirection) {
      return false
    }
    return true
  }
  return true
}

export const planStartDate = (planStartDate?: Date) => {
  if (!planStartDate) {
    return
  }
  const now = new Date(new Date().setHours(0, 0, 0, 0))
  if (planStartDate < now) {
    return 'Start Date cannot be in the past'
  } else {
    return true
  }
}

export const getTextLimit = (configData?: IMMConfig, paymentType?: string) => {
  if (paymentType === 'Journal') {
    return configData?.jnl?.opsPrePayComment ?? configData?.commentsTextLimit
  }
  return configData?.commentsTextLimit
}
export const getCustodian = (
  custodian?: string,
  executingCustodian?: string
) => {
  const typeOfCustodian =
    custodian && !isEmpty(custodian) ? custodian : executingCustodian
  return typeOfCustodian
}
