import { Label, MessageBar, Separator, Stack, TextField } from '@fluentui/react'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { IClientInviteForm } from './ClientInvitePanel'
import { MoreEmailSettings } from './MoreEmailSettings'
import { IClientInvitePanelType } from './store/clientInvitePanel'

export const ClientInviteForm: React.FC<{
  mode?: IClientInvitePanelType
  isThirdParty?: boolean
}> = ({ mode, isThirdParty }) => {
  const register = useFormContext<IClientInviteForm>()
  const { fields } = useFieldArray<IClientInviteForm>({
    name: 'invite.phones'
  })

  return (
    <>
      <Stack tokens={{ childrenGap: 5 }}>
        <Stack>
          <Label required>Email</Label>
          <Controller
            name="invite.email"
            control={register.control}
            rules={{ required: 'Enter Email' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  value={value || ''}
                  errorMessage={error && error.message}
                  disabled={mode === 'resend'}
                  onChange={(_e, name) => {
                    onChange(name)
                  }}
                />
              )
            }}
          />
        </Stack>
        <Stack>
          <Label>First Name</Label>
          <Controller
            name="invite.firstName"
            control={register.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  value={value || ''}
                  disabled={true}
                  errorMessage={error && error.message}
                  onChange={(_e, name) => {
                    onChange(name)
                  }}
                />
              )
            }}
          />
        </Stack>
        <Stack>
          <Label>Last Name</Label>
          <Controller
            name="invite.lastName"
            control={register.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  value={value || ''}
                  disabled={true}
                  errorMessage={error && error.message}
                  onChange={(_e, name) => {
                    onChange(name)
                  }}
                />
              )
            }}
          />
        </Stack>
        <Stack>
          <Label>SSN</Label>
          <Controller
            name="invite.ssn"
            control={register.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  value={value ? `XXX-XX-${value?.slice(-4)}` : ''}
                  disabled={true}
                  errorMessage={error && error.message}
                  onChange={(_e, name) => {
                    onChange(name)
                  }}
                />
              )
            }}
          />
        </Stack>
        <Separator />
        <Label>Phones</Label>
        {fields?.map((item, index) => (
          <Stack key={item.id} horizontal={true}>
            <Stack.Item grow={1}>
              <Controller
                name={`invite.phones.${index}.Phone` as const}
                control={register.control}
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <TextField
                      value={value || ''}
                      disabled={true}
                      errorMessage={error && error.message}
                      onChange={(_e, name) => {
                        onChange(name)
                      }}
                    />
                  )
                }}
              />
            </Stack.Item>
          </Stack>
        ))}
        {fields?.length === 0 && <MessageBar>No Phones Found</MessageBar>}
        <Separator />
        <Stack>
          <Label required>
            {isThirdParty ? 'Third Party Name' : 'Client Name'}
          </Label>
          <Controller
            name="invite.invitee"
            control={register.control}
            rules={{ required: 'Enter Client Name' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  value={value || ''}
                  errorMessage={error && error.message}
                  onChange={(_e, name) => {
                    onChange(name)
                  }}
                />
              )
            }}
          />
        </Stack>
        {isThirdParty && (
          <Stack>
            <Label required>On Behalf Of Client Name</Label>
            <Controller
              name="invite.onBehalfOf"
              control={register.control}
              rules={{ required: 'Enter on Behalf of Client Name' }}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                return (
                  <TextField
                    value={value || ''}
                    errorMessage={error && error.message}
                    onChange={(_e, name) => {
                      onChange(name)
                    }}
                  />
                )
              }}
            />
          </Stack>
        )}
        <Stack>
          <Label required>Cc</Label>
          <Controller
            name="invite.cc"
            control={register.control}
            rules={{ required: 'Enter Cc' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  value={value || ''}
                  errorMessage={error && error.message}
                  onChange={(_e, name) => {
                    onChange(name)
                  }}
                />
              )
            }}
          />
        </Stack>
        <Separator />
        <MoreEmailSettings />
      </Stack>
    </>
  )
}
