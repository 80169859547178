import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { flow } from 'lodash/fp'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { AppState } from 'store/shared'
import { getRockefellerApiConfig } from 'store/system'
import { axiosBaseQuery } from './shared'

export interface IInsightByHouseholdId {
  householdIds: string[]
  insType?: string[]
}

export interface IInsightParameter {
  parameter: string
  value: string
  value_raw: string
}

export interface IInsightStatement {
  order: number
  statement: string
  type: string
}

export interface IHouseholdInsight {
  createdDate: string
  householdId: string
  householdName: string
  ins_details_url: string
  ins_type: string
  ins_desc: string
  ins_priority: string
  ins_level: string
  ins_order: number
  ins_active: string
  parameters: IInsightParameter[]
  statements: IInsightStatement[]
}

type InsightsApiTagType = 'insightsByHouseholdId'

const insightsApiTagTypes: InsightsApiTagType[] = ['insightsByHouseholdId']

const getApiBaseUrl = (state: AppState) => {
  const base = flow(getRockefellerApiConfig, (x) => x?.root)(state)
  const v1 = new URL('insights', base)
  return v1.href
}

const getApiAuthToken = (state: AppState) => {
  const scopes = flow(getRockefellerApiConfig, (x) => x?.scopes)(state)

  if (!scopes) {
    throw new Error('')
  }

  return tryAcquireAccessToken(scopes)
}

export const insightsApiSliceKey = 'api.insights'
export type InsightsApiReducerState = {
  [insightsApiSliceKey]: ReturnType<typeof insightsApi.reducer>
}

export const insightsApi = createApi({
  reducerPath: insightsApiSliceKey,
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getApiBaseUrl(state),
    getAuthToken: (state) => getApiAuthToken(state)
  }),
  endpoints: (builder) => ({
    getInsightByHouseholdId: builder.query<
      IHouseholdInsight[],
      IInsightByHouseholdId
    >({
      query: ({ householdIds }) => {
        return {
          url: `api/Insight/InsightByHouseholdId`,
          method: 'POST',
          data: { householdIds }
        }
      },
      providesTags: ['insightsByHouseholdId']
    })
  }),
  tagTypes: insightsApiTagTypes
})

export const { useGetInsightByHouseholdIdQuery } = insightsApi
