import { css } from '@emotion/react'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEnvironmentConfigApps } from 'store/system'
import { navigationActions } from 'store/ui/actions'
import { IndeterminateProgressIndicator } from '../../../components/shared'
import TileHeading, { BaseHeaderProps } from '../../../components/TileHeading'
import { Icon } from '../../../features/Icons/Icon'
import { useInsightsByHouseholdId } from './useInsightsByHouseholdId'

const classes = {
  button: css({
    border: 'solid 1px #D9CFBB',
    backgroundColor: '#FBFBF9',
    color: '#2A565B',
    cursor: 'pointer',
    padding: '8px 12px'
  }),
  buttonContainer: css({
    textAlign: 'right'
  }),
  container: css({
    display: 'flex',
    gridColumnGap: 25,
    justifyContent: 'center',
    paddingBottom: '10px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  }),
  dot: css({
    borderRadius: '50%',
    backgroundColor: '#D9D9D9',
    display: 'inline-block',
    height: '8px',
    width: '8px'
  }),
  dotContainer: css({
    alignItems: 'center',
    display: 'flex',
    gridColumnGap: 5,
    justifyContent: 'center'
  }),
  dotSelected: css({
    backgroundColor: '#0E687B'
  }),
  loader: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }),
  title: css({
    color: '#06262D'
  })
}

export const Insights: React.FC<BaseHeaderProps> = () => {
  const appsConfig = useSelector(getEnvironmentConfigApps)
  const dispatch = useDispatch()
  const { data, isFetching } = useInsightsByHouseholdId()
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const totalInsights = data?.length ?? 0
  const selectedInsight = data?.[selectedIndex]
  const shouldDisableNext = selectedIndex >= totalInsights - 1
  const shouldDisablePrevious = selectedIndex <= 0
  const sortedStatements = useMemo(() => {
    const statements = selectedInsight?.statements
    if (!statements) {
      return
    }
    return [...statements].sort((a, b) => a.order - b.order)
  }, [selectedInsight])

  const onNextClicked = useCallback(() => {
    if (shouldDisableNext) {
      return
    }
    setSelectedIndex((prev) => prev + 1)
  }, [shouldDisableNext, setSelectedIndex])

  const onPreviousClicked = useCallback(() => {
    if (shouldDisablePrevious) {
      return
    }
    setSelectedIndex((prev) => prev - 1)
  }, [shouldDisablePrevious, setSelectedIndex])

  const onViewDetailsClicked = useCallback(() => {
    if (!appsConfig || !selectedInsight) {
      return
    }
    dispatch(
      navigationActions.launchUrl({
        url: `${appsConfig.insightsDashboard?.url}${selectedInsight.ins_details_url}`
      })
    )
  }, [appsConfig, dispatch, selectedInsight])

  return (
    <>
      <TileHeading showIcons={false}>
        <span css={classes.title}>Insights</span>
      </TileHeading>
      {sortedStatements?.map((s) => (
        <p
          key={s.order}
          dangerouslySetInnerHTML={{
            __html: s.statement
          }}
        />
      ))}
      {selectedInsight?.ins_details_url && (
        <div css={classes.buttonContainer}>
          <button css={classes.button} onClick={onViewDetailsClicked}>
            View Details
          </button>
        </div>
      )}
      {!!totalInsights && (
        <div css={classes.container}>
          <div
            css={{
              opacity: shouldDisablePrevious ? 0.5 : 1,
              cursor: shouldDisablePrevious ? 'default' : 'pointer',
              width: 18,
              height: 18
            }}
            onClick={onPreviousClicked}
            title="Previous"
          >
            <Icon type="Previous" color="#0F65BB" />
          </div>
          <div css={classes.dotContainer}>
            {data?.map((_, index) => (
              <span
                key={index}
                css={[
                  classes.dot,
                  index === selectedIndex ? classes.dotSelected : null
                ]}
              />
            ))}
          </div>
          <div
            css={{
              opacity: shouldDisableNext ? 0.5 : 1,
              cursor: shouldDisableNext ? 'default' : 'pointer',
              width: 18,
              height: 18
            }}
            onClick={onNextClicked}
            title="Next"
          >
            <Icon type="Next" color="#0F65BB" />
          </div>
        </div>
      )}
      {isFetching && (
        <div css={classes.loader}>
          <IndeterminateProgressIndicator />
        </div>
      )}
    </>
  )
}
