import { TagDescription } from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {
  IActivity,
  ICdsBatchRequestItem,
  IContact,
  IDynamicsApiResult,
  IOrganization,
  ISystemUser
} from 'api/dynamics'
import { escapeAndEncodeQuery, tokenizeQuery } from 'api/search'
import { chunk, orderBy, partition, sum, trim } from 'lodash'
import { stringify } from 'query-string'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import {
  BatchResponse,
  createCdsBatchPayload,
  dynamicsApi,
  executeBatchRequest
} from 'store/api/dynamics'
import { AxiosBaseArgs, arrayCommaParamsSerializer } from 'store/api/shared'
import { apiConstants } from './apis'
import {
  ActivityPointerActivityMimeAttachment,
  InteractionAttachment
} from './types'

export interface ICustomAccountGroup {
  rcm_customaccountgroupid?: string
  rcm_name?: string
  _rcm_household_value?: string
  rcm_cag_rcm_financialaccount?: IFinancialAccount[]
}

export interface IFinancialAccount {
  rcm_accountnumber?: string
  rcm_cdmaccountid?: string
}

export interface IAddTaskPayload {
  actualdurationminutes?: number
  description?: string
  subject?: string
  'ownerid_task@odata.bind'?: string
  'regardingobjectid_contact_task@odata.bind'?: string
  'regardingobjectid_account_task@odata.bind'?: string
  prioritycode?: number
  scheduledend?: string
  statecode?: number
  statuscode?: number
  rcm_sendemail?: boolean
}

export interface IReassignTaskPayload {
  'ownerid_task@odata.bind'?: string
}

export interface ICreateInteractionPayload {
  aka_type: number
  subject: string
  scheduledend: string
  ram_sentiment?: number
  'regardingobjectid_contact_aka_meetingnote@odata.bind'?: string
  'regardingobjectid_account_aka_meetingnote@odata.bind'?: string
  rpm_annualreview?: boolean
  rcm_city?: string
  rcm_stateprovince?: string
  rcm_countryregion?: string
  description?: string
  mimeType?: string
  filename?: string
  documentbody?: string
  isdocument?: boolean
}

export interface IInteraction {
  'aka_type@OData.Community.Display.V1.FormattedValue': string
  'scheduledend@OData.Community.Display.V1.FormattedValue': string
  '_regardingobjectid_value@OData.Community.Display.V1.FormattedValue': string
  _regardingobjectid_value: string
  subject: string
  description: string
  'modifiedon@OData.Community.Display.V1.FormattedValue'?: string
  '_modifiedby_value@OData.Community.Display.V1.FormattedValue'?: string
  activityid?: string
  '_ownerid_value@OData.Community.Display.V1.FormattedValue'?: string
  rpm_annualreview: boolean
  rcm_city: string
  rcm_stateprovince: string
  rcm_countryregion: string
  'ram_sentiment@OData.Community.Display.V1.FormattedValue'?: string
  ram_sentiment?: number
  rcm_attachment_name?: string
}

export interface IAdvisorTeamPayload {
  'rcm_primaryadvisor@odata.bind'?: string | null
  'rcm_primaryclientassociate@odata.bind'?: string | null
}

export const InteractionTypeOptions = [
  'Email',
  'Phone Call',
  'Meeting - Onsite',
  'Meeting - Offsite',
  'Meal / Social',
  'Conference / Event',
  'Encounter',
  'Conference Call',
  'Finals Pitch',
  'Video Call'
] as const

export type IInteractionType = (typeof InteractionTypeOptions)[number]

export interface IDynamicsConsolidation {
  '_rcm_primaryadvisor_value@OData.Community.Display.V1.FormattedValue'?: string
  '_rcm_primaryclientassociate_value@OData.Community.Display.V1.FormattedValue'?: string
  '_owningbusinessunit_value@OData.Community.Display.V1.FormattedValue'?: string
  rcm_householdid?: string
  aka_name?: string
  aka_rollupid?: string
  _rcm_primaryadvisor_value?: string
  _rcm_primaryclientassociate_value?: string
  _owningbusinessunit_value?: string
  rcm_primaryadvisor?: RcmPrimaryadvisorAndCA
  rcm_primaryclientassociate?: RcmPrimaryadvisorAndCA
}
export interface RcmPrimaryadvisorAndCA {
  azureactivedirectoryobjectid?: string
  systemuserid?: string
  ownerid?: string
}
export interface ITask {
  subject?: string
  description?: string
  'scheduledend@OData.Community.Display.V1.FormattedValue'?: string
  scheduledend?: string
  '_regardingobjectid_value@OData.Community.Display.V1.FormattedValue'?: string
  _regardingobjectid_value?: string
  '_ownerid_value@OData.Community.Display.V1.FormattedValue'?: string
  _ownerid_value?: string
  rcm_sendemail?: boolean
  prioritycode?: number
  actualdurationminutes?: number
  statuscode?: number
  activityid?: string
  'statecode@OData.Community.Display.V1.FormattedValue'?: string
  statecode?: number
  'modifiedon@OData.Community.Display.V1.FormattedValue'?: string
}
export interface IActivityParty {
  '_partyid_value@OData.Community.Display.V1.FormattedValue'?: string
  _partyid_value?: string
}
export interface IGroupTask extends ITask {
  fax_activity_parties?: IActivityParty[]
}
export interface IGroupTaskPayload {
  scheduledend?: string
  description?: string
  subject?: string
  rcm_sendemail?: boolean
  prioritycode?: number
  statuscode?: number
  statecode?: number
  fax_activity_parties?: IGroupTaskPartyPayload[]
  'regardingobjectid_contact_fax@odata.bind'?: string
  'regardingobjectid_account_fax@odata.bind'?: string
}
export interface IReassignGroupTaskPayload {
  fax_activity_parties?: IGroupTaskPartyPayload[]
}
interface IGroupTaskPartyPayload {
  'partyid_systemuser@odata.bind'?: string
  participationtypemask?: number
}

export interface IActivityTask {
  activitytypecode?: string
  activity_pointer_fax?: IGroupTask[]
  activity_pointer_task?: ITask[]
}

export function convertInteractionTypeToFilter(type: IInteractionType) {
  switch (type) {
    case 'Email':
      return 964110005
    case 'Phone Call':
      return 964110003
    case 'Meeting - Onsite':
      return 964110000
    case 'Meeting - Offsite':
      return 964110001
    case 'Meal / Social':
      return 964110002
    case 'Conference / Event':
      return 804820001
    case 'Encounter':
      return 964110004
    case 'Conference Call':
      return 804820000
    case 'Finals Pitch':
      return 804820002
    case 'Video Call':
      return 412290001
  }
}

export function convertInteractionTypeToActivityFilter(
  type?: IInteractionType
) {
  switch (type) {
    case 'Email':
      return 'email'
    case 'Phone Call':
      return 'phonecall'
    default:
      return undefined
  }
}

const { cacheTime } = apiConstants

type DynamicsApiTagType =
  | 'rdot360'
  | 'Notes'
  | 'Tasks'
  | 'Interactions'
  | 'CAGs'
  | 'AdvisorTeam'
  | 'contacts'
const datahubApiTags: DynamicsApiTagType[] = [
  'rdot360',
  'Notes',
  'Tasks',
  'Interactions',
  'CAGs',
  'AdvisorTeam',
  'contacts'
]

const paramsSerializer = (params: any) => {
  return stringify(params, { arrayFormat: 'comma' })
}

const dynamicsApiWithRdot360Tags = dynamicsApi.enhanceEndpoints({
  addTagTypes: datahubApiTags
})

const fullNameTokenSearchFilter = (search: string): string =>
  tokenizeQuery(search)
    .map(escapeAndEncodeQuery)
    .map(trim)
    .filter(isNotNullOrEmpty)
    .map((token) => `contains(fullname, '${token}')`)
    .join(' and ')

export const rdot360DynamicsApi = dynamicsApiWithRdot360Tags.injectEndpoints({
  endpoints: (builder) => ({
    getSystemUsers: builder.query<
      ISystemUser[] | undefined,
      string | undefined
    >({
      query: (search) => ({
        url: '/systemusers',
        params: {
          $select: 'systemuserid, fullname',
          $filter: [
            search && fullNameTokenSearchFilter(search),
            'systemuserid ne null',
            'fullname ne null'
          ]
            .filter(Boolean)
            .join(' and '),
          $top: 10
        },
        paramsSerializer,
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IContact>) => x.value,
      providesTags: ['rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getTasksAndGroupTasksByContacts: builder.query<
      IGroupTask[] | undefined,
      {
        contactIds: string[]
        startDate?: string
        endDate?: string
        order?: 'asc' | 'desc'
        searchText?: string
      }
    >({
      queryFn: async (
        { contactIds, startDate, endDate, order, searchText },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const chunkedIds = chunk(contactIds, 20)
        const requests = chunkedIds.map((ids) => ({
          method: 'GET',
          url: '/activitypointers',
          params: {
            $filter: [
              `Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${ids
                .map((x) => `'${x}'`)
                .join(',')}])`,
              startDate && `scheduledend ge '${startDate}'`,
              endDate && `scheduledend le '${endDate}'`,
              searchText &&
                `(contains(description, '${searchText}') or contains(subject, '${searchText}'))`
            ]
              .filter(Boolean)
              .join(' and '),
            $orderby: `scheduledend ${order || 'desc'}`,
            $select: 'activitytypecode',
            $expand: `activity_pointer_task($select=${[
              'subject',
              'scheduledend',
              '_regardingobjectid_value',
              '_ownerid_value',
              'rcm_sendemail',
              'prioritycode',
              'actualdurationminutes',
              'statuscode',
              'activityid',
              'statecode',
              'modifiedon',
              'description'
            ].join(',')}),activity_pointer_fax($select=${[
              'subject',
              'scheduledend',
              '_regardingobjectid_value',
              '_ownerid_value',
              'rcm_sendemail',
              'prioritycode',
              'statuscode',
              'activityid',
              'statecode',
              'modifiedon',
              'description'
            ]};$expand=fax_activity_parties($select=_partyid_value))`
          },
          paramsSerializer,
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
          }
        }))
        const responses = await Promise.all(
          requests.map(
            async (x) =>
              baseQuery(x) as Promise<
                QueryReturnValue<IDynamicsApiResult<IActivityTask>>
              >
          )
        )
        const orderedResponses = orderBy(
          responses
            .flatMap((x) => x.data?.value || [])
            ?.map((x) =>
              x?.activitytypecode === 'fax'
                ? x?.activity_pointer_fax?.[0]
                : x?.activity_pointer_task?.[0]
            )
            ?.filter(isNotNullOrUndefined),
          'scheduledend',
          order || 'desc'
        )
        return { data: orderedResponses }
      },
      providesTags: ['Tasks', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getTaskCounts: builder.query<
      number | undefined,
      { contactIds: string[]; startDate?: string; endDate?: string }
    >({
      queryFn: async (
        { contactIds, startDate, endDate },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const chunkedIds = chunk(contactIds, 20)
        const requests = chunkedIds.map(
          (ids) =>
            ({
              method: 'GET',
              url: `/api/data/v9.2/activitypointers?$filter=${[
                `Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${ids
                  .map((x) => `'${x}'`)
                  .join(',')}]) and activitytypecode eq 'task'`,
                startDate && `scheduledend ge '${startDate}'`,
                endDate && `scheduledend le '${endDate}'`
              ]
                .filter(Boolean)
                .join(' and ')}&$count=true&$top=1&$select=activityid`,
              headers: {
                Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`,
                Accept: 'application/json, text/plain, */*'
              }
            } as ICdsBatchRequestItem)
        )
        const responses = await executeBatchRequest(requests, baseQuery)
        const response = responses?.[0] as BatchResponse<IActivityTask>
        return {
          data: sum(
            response?.data
              ?.map((x) => x['@odata.count'])
              .filter(isNotNullOrUndefined)
          )
        }
      },
      providesTags: ['Tasks', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getActivitiesByContacts: builder.query<
      IActivity[] | undefined,
      {
        contactIds: string[]
        type?: IInteractionType
        search?: string
        order?: 'asc' | 'desc'
      }
    >({
      queryFn: async (
        { contactIds, type, search, order },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const request = {
          method: 'GET',
          url: [
            `/api/data/v9.2/activitypointers?$filter=Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${contactIds
              .map((x) => `'${x}'`)
              .join(',')}])${
              convertInteractionTypeToActivityFilter(type)
                ? ` and activitytypecode eq '${convertInteractionTypeToActivityFilter(
                    type
                  )}'`
                : ''
            } ${
              search
                ? `and (contains(description, '${search}') or contains(subject, '${search}'))`
                : ''
            } &$expand=regardingobjectid_contact($select=fullname),activity_pointer_activity_mime_attachment($select=activitymimeattachmentid,attachmentcontentid,filename,mimetype)&$orderby=modifiedon ${
              order || 'desc'
            } &$select=${[
              'activitytypecode',
              'modifiedon',
              'description',
              'subject',
              '_regardingobjectid_value',
              '_modifiedby_value',
              '_ownerid_value'
            ].join(',')}`
          ]
            .filter(Boolean)
            .join(''),
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",odata.continue-on-error`,
            Accept: 'application/json, text/plain, */*'
          }
        } as ICdsBatchRequestItem
        const responses = await executeBatchRequest([request], baseQuery)
        const response = responses?.[0] as BatchResponse<IActivity>
        return {
          data: response?.data?.[0]?.value
        }
      },
      providesTags: ['rdot360', 'Interactions'],
      keepUnusedDataFor: cacheTime
    }),
    getEmailAttachment: builder.query<
      ActivityPointerActivityMimeAttachment | undefined,
      string
    >({
      queryFn: async (attachementId, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<
          ActivityPointerActivityMimeAttachment,
          Error
        >

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: `activitymimeattachments(${attachementId})`,
          paramsSerializer: arrayCommaParamsSerializer
        }

        const result = (await baseQuery({
          ...baseApiArgs
        })) as Response
        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    getInteractionAttachment: builder.query<
      InteractionAttachment | undefined,
      string
    >({
      queryFn: async (interactionId, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<InteractionAttachment, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: `aka_meetingnotes(${interactionId})/rcm_attachment`,
          paramsSerializer: arrayCommaParamsSerializer
        }

        const result = (await baseQuery({
          ...baseApiArgs
        })) as Response
        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    getEmailAttachments: builder.query<
      ActivityPointerActivityMimeAttachment[] | undefined,
      string[]
    >({
      queryFn: async (ids, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<
          IDynamicsApiResult<ActivityPointerActivityMimeAttachment>,
          Error
        >

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: `activitymimeattachments`,
          params: {
            $filter: ids
              .map((id) => `activitymimeattachmentid eq '${id}'`)
              .join(' or ')
          },
          paramsSerializer: arrayCommaParamsSerializer
        }

        const result = (await baseQuery({
          ...baseApiArgs
        })) as Response
        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data?.value
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    getInteractionsByContacts: builder.query<
      IInteraction[] | undefined,
      {
        contactIds: string[]
        type?: IInteractionType
        search?: string
        order?: 'asc' | 'desc'
      }
    >({
      queryFn: async (
        { contactIds, type, search, order },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const request = {
          method: 'GET',
          url: [
            `/api/data/v9.2/aka_meetingnotes?$filter=Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${contactIds
              .map((x) => `'${x}'`)
              .join(',')}]) ${
              type && convertInteractionTypeToFilter(type)
                ? ` and aka_type eq ${convertInteractionTypeToFilter(type)}`
                : ''
            } ${
              search
                ? `and (contains(description, '${search}') or contains(subject, '${search}'))`
                : ''
            } &$expand=regardingobjectid_contact($select=fullname)&$orderby=modifiedon ${
              order || 'desc'
            }`
          ]
            .filter(Boolean)
            .join(''),
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`,
            Accept: 'application/json, text/plain, */*'
          }
        } as ICdsBatchRequestItem
        const responses = await executeBatchRequest([request], baseQuery)
        const response = responses?.[0] as BatchResponse<IInteraction>
        return { data: response?.data?.[0]?.value }
      },
      providesTags: ['rdot360', 'Interactions'],
      keepUnusedDataFor: cacheTime
    }),
    getMostRecentInteractionByContacts: builder.query<
      IInteraction | undefined,
      string[]
    >({
      queryFn: async (contactIds: string[], _api, _extraOptions, baseQuery) => {
        const request = {
          method: 'GET',
          url: [
            `/api/data/v9.2/aka_meetingnotes?$filter=Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${contactIds
              .map((x) => `'${x}'`)
              .join(',')}])
            &$expand=regardingobjectid_contact&$orderby=createdon desc&$top=1`
          ]
            .filter(Boolean)
            .join(''),
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`,
            Accept: 'application/json, text/plain, */*'
          }
        } as ICdsBatchRequestItem
        const responses = await executeBatchRequest([request], baseQuery)
        const response = responses?.[0] as BatchResponse<IInteraction>
        return { data: response?.data?.[0]?.value?.[0] }
      },
      providesTags: ['rdot360', 'Interactions'],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_createTask: builder.mutation<
      IActivity,
      {
        taskPayload: IAddTaskPayload
        contacts: string[]
        startDate?: string
        endDate?: string
      }
    >({
      query: ({ taskPayload }) => ({
        url: `/tasks`,
        method: 'POST',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_createGroupTask: builder.mutation<
      IGroupTask,
      {
        taskPayload: IGroupTaskPayload
      }
    >({
      query: ({ taskPayload }) => ({
        url: `/faxes`,
        method: 'POST',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_editGroupTask: builder.mutation<
      IGroupTask,
      {
        taskPayload: IGroupTaskPayload
        id: string
      }
    >({
      query: ({ taskPayload, id }) => ({
        url: `/faxes(${id})`,
        method: 'PATCH',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_editTask: builder.mutation<
      IActivity,
      {
        taskPayload: IAddTaskPayload
        id: string
      }
    >({
      query: ({ taskPayload, id }) => ({
        url: `/tasks(${id})`,
        method: 'PATCH',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_reassignTask: builder.mutation<
      undefined,
      { id: string; taskPayload: IReassignTaskPayload }
    >({
      query: ({ id, taskPayload }) => ({
        url: `/tasks(${id})`,
        method: 'PATCH',
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_reassignGroupTask: builder.mutation<
      undefined,
      { id: string; taskPayload: IReassignGroupTaskPayload }
    >({
      query: ({ id, taskPayload }) => ({
        url: `/faxes(${id})`,
        method: 'PATCH',
        data: taskPayload
      }),
      invalidatesTags: ['Tasks']
    }),
    rdot360_createInteraction: builder.mutation<
      IInteraction,
      ICreateInteractionPayload
    >({
      query: (payload) => ({
        url: `/aka_meetingnotes`,
        method: 'POST',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: payload
      }),
      invalidatesTags: ['Interactions']
    }),
    rdot360_editInteraction: builder.mutation<
      IInteraction,
      { id: string; payload: ICreateInteractionPayload }
    >({
      query: ({ id, payload }) => ({
        url: `/aka_meetingnotes(${id})`,
        method: 'Patch',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: payload
      }),
      invalidatesTags: ['Interactions']
    }),
    rdot360_interactionAttachment: builder.mutation<
      string | undefined,
      { id: string; name: string; payload?: File }
    >({
      query: ({ id, name, payload }) => ({
        url: `/aka_meetingnotes(${id})/rcm_attachment?x-ms-file-name=${name}`,
        method: 'Patch',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        data: payload
      }),
      invalidatesTags: ['Interactions']
    }),
    rdot360_updateKeyContact: builder.mutation<
      IContact,
      {
        keyContact: boolean
        id: string
        householdId: string
      }
    >({
      query: ({ keyContact, id }) => ({
        url: `/contacts(${id})`,
        method: 'PATCH',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: { rpm_headofhousehold: keyContact }
      }),
      invalidatesTags: ['contacts']
    }),
    rdot360_getCustomAccountGroups: builder.query<
      ICustomAccountGroup[] | undefined,
      string
    >({
      query: (householdId) => ({
        url: [
          `/rcm_customaccountgroups?$filter=rcm_household/rcm_householdid eq '${householdId}'`,
          '&$expand=rcm_cag_rcm_financialaccount'
        ].join(''),
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<ICustomAccountGroup>) =>
        x.value,
      providesTags: ['rdot360', 'CAGs'],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_deleteCustomAccountGroup: builder.mutation<undefined, string>({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<undefined, Error>
        const response = (await baseQuery({
          url: `/rcm_customaccountgroups(${id})`,
          method: 'DELETE'
        })) as Response
        if (response.error) {
          return { error: response.error }
        }
        return { ...response, error: undefined }
      },
      invalidatesTags: ['CAGs']
    }),
    rdot360_createCustomAccountGroup: builder.mutation<
      any,
      {
        name: string
        householdId: string
        accountIds: string[]
      }
    >({
      queryFn: async (
        { name, householdId, accountIds },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        type Response = QueryReturnValue<string, Error>
        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: '/rcm_customaccountgroups',
          method: 'POST',
          data: {
            rcm_name: name,
            'rcm_household@odata.bind': `/aka_rollups(rcm_householdid='${householdId}')`,
            statecode: 0,
            statuscode: 1
          },
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
          }
        }

        const createdGroup = (await baseQuery({
          ...baseApiArgs
        })) as { data?: ICustomAccountGroup }
        const createdGroupId = createdGroup?.data?.rcm_customaccountgroupid

        if (!createdGroupId) {
          return { error: new Error('Failed to create group') }
        }

        const requests = accountIds?.map(
          (x) =>
            ({
              method: 'POST',
              url: `/api/data/v9.0/rcm_customaccountgroups(${createdGroupId})/rcm_cag_rcm_financialaccount/$ref`,
              payload: {
                '@odata.context': '/api/data/v9.0/$metadata#$ref',
                '@odata.id': `rcm_financialaccounts(rcm_cdmaccountid='${x}')`
              }
            } as ICdsBatchRequestItem)
        )

        const { batchRequest, boundary } = createCdsBatchPayload(requests)

        const batchResponse = (await baseQuery({
          url: '/$batch',
          method: 'POST',
          headers: {
            'Content-Type': `multipart/mixed;boundary=${boundary}`,
            Accept: 'application/json',
            'OData-MaxVersion': '4.0',
            'OData-Version': '4.0'
          },
          data: batchRequest
        })) as Response

        if (batchResponse.error) {
          await baseQuery({
            url: `/rcm_customaccountgroups(${createdGroupId})`,
            method: 'DELETE'
          })
        }

        return batchResponse
      },
      invalidatesTags: ['CAGs']
    }),
    getAdvisorTeam: builder.query<IDynamicsConsolidation | undefined, string>({
      query: (id) => ({
        url: '/aka_rollups',
        params: {
          $filter: `rcm_householdid eq '${id}'`,
          $top: 1,
          $select: [
            '_rcm_primaryadvisor_value',
            '_rcm_primaryclientassociate_value',
            '_owningbusinessunit_value'
          ],
          $expand: [
            'rcm_primaryadvisor($select=azureactivedirectoryobjectid)',
            'rcm_primaryclientassociate($select=azureactivedirectoryobjectid)'
          ]
        },
        paramsSerializer,
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IDynamicsConsolidation>) =>
        x?.value?.[0],
      providesTags: ['AdvisorTeam', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    updateAdvisorTeam: builder.mutation<
      undefined,
      {
        id: string
        payload: IAdvisorTeamPayload
      }
    >({
      query: ({ id, payload }) => ({
        url: `/aka_rollups(${id})`,
        method: 'PATCH',
        data: payload
      }),
      invalidatesTags: ['AdvisorTeam']
    }),
    updateAdvisorTeamForContacts: builder.mutation<
      undefined,
      {
        id: string
        payload: IAdvisorTeamPayload
      }
    >({
      query: ({ id, payload }) => ({
        url: `/contacts(${id})`,
        method: 'PATCH',
        data: payload
      }),
      invalidatesTags: ['contacts']
    }),
    updateCagName: builder.mutation<any, { id: string; cagName: string }>({
      query: ({ id, cagName }) => ({
        url: `rcm_customaccountgroups(${id})`,
        method: 'PATCH',
        data: { rcm_name: cagName }
      }),
      invalidatesTags: ['CAGs']
    }),
    updateCagAccounts: builder.mutation<
      any,
      { id: string; accountsToAdd?: string[]; accountsToRemove?: string[] }
    >({
      queryFn: async (
        { id, accountsToAdd, accountsToRemove },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        type Response = QueryReturnValue<any, Error>
        const add =
          accountsToAdd?.map(
            (x) =>
              ({
                method: 'POST',
                url: `/api/data/v9.0/rcm_customaccountgroups(${id})/rcm_cag_rcm_financialaccount/$ref`,
                payload: {
                  '@odata.context': '/api/data/v9.0/$metadata#$ref',
                  '@odata.id': `rcm_financialaccounts(rcm_cdmaccountid='${x}')`
                }
              } as ICdsBatchRequestItem)
          ) || []
        const remove =
          accountsToRemove?.map(
            (x) =>
              ({
                method: 'DELETE',
                url: `/api/data/v9.0/rcm_customaccountgroups(${id})/rcm_cag_rcm_financialaccount(rcm_cdmaccountid='${x}')/$ref`
              } as ICdsBatchRequestItem)
          ) || []
        const requests = [...add, ...remove]
        const { batchRequest, boundary } = createCdsBatchPayload(requests)
        const response = (await baseQuery({
          url: '/$batch',
          method: 'POST',
          headers: {
            'Content-Type': `multipart/mixed;boundary=${boundary}`,
            Accept: 'application/json',
            'OData-MaxVersion': '4.0',
            'OData-Version': '4.0'
          },
          data: batchRequest
        })) as Response
        return response
      },
      invalidatesTags: ['CAGs']
    }),
    getContactsAndOrgsByPartyIds: builder.query<
      { contacts?: IContact[]; orgs?: IOrganization[] },
      { individialPartyIds: string[]; partyIds: string[] }
    >({
      queryFn: async (
        { individialPartyIds, partyIds },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const chunkSize = 100
        const contactRequests = chunk(individialPartyIds, chunkSize).map(
          (ids): ICdsBatchRequestItem => ({
            method: 'GET',
            url: `/api/data/v9.2${[
              `/contacts?$filter=statecode eq 0 and ${`Microsoft.Dynamics.CRM.In(PropertyName='rcm_rockcodbpartyid',PropertyValues=[${ids
                .map((x) => `'${x}'`)
                .join(',')}])`}`,
              `&$select=${[
                'firstname',
                'lastname',
                'nickname',
                'birthdate',
                'mobilephone',
                'telephone1',
                'telephone2',
                'emailaddress1',
                'contactid',
                'fullname',
                'middlename',
                'suffix',
                'address1_line1',
                'address1_line2',
                'address1_line3',
                'address1_city',
                'address1_stateorprovince',
                'address1_postalcode',
                'address1_country',
                'rpm_headofhousehold',
                'modifiedon',
                'rpm_dateofbirth',
                'address1_composite',
                'rcm_contacttype',
                'jobtitle',
                'rcm_secureid',
                'rcm_taxidtype',
                'rpm_headofhousehold',
                'familystatuscode',
                'gendercode',
                'rcm_rockcodbpartyid',
                '_aka_householdid_value',
                'rpm_employer',
                '_rcm_primaryadvisor_value',
                '_rcm_primaryclientassociate_value',
                'rcm_accreditedinvestor',
                'rcm_clientsegment',
                'rcm_qualifiedpurchaser',
                'rpm_networth'
              ].join(',')}`
            ].join('')}`,
            headers: {
              Accept: 'application/json, text/plain, */*',
              Prefer:
                'odata.include-annotations="OData.Community.Display.V1.FormattedValue"'
            }
          })
        )

        const orgRequests = chunk(partyIds, chunkSize).map(
          (ids): ICdsBatchRequestItem => ({
            method: 'GET',
            url: `/api/data/v9.2${[
              `/accounts?$filter=statecode eq 0 and ${`Microsoft.Dynamics.CRM.In(PropertyName='rcm_rockcodbpartyid',PropertyValues=[${ids
                .map((x) => `'${x}'`)
                .join(',')}])`}`,
              `&$select=${[
                'name',
                'ram_investortype',
                'rcm_taxidtype',
                'rcm_taxid',
                'address1_composite',
                'accountid',
                'rcm_rockcodbpartyid',
                'telephone1',
                'emailaddress1',
                'ram_dba',
                'industrycode',
                '_primarycontactid_value'
              ].join(',')}`
            ].join('')}`,
            headers: {
              Accept: 'application/json, text/plain, */*',
              Prefer:
                'odata.include-annotations="OData.Community.Display.V1.FormattedValue"'
            }
          })
        )

        const requests = [...contactRequests, ...orgRequests]
        const responses = await executeBatchRequest<
          IDynamicsApiResult<IContact | IOrganization>[]
        >(requests, baseQuery)
        const error = responses?.find((x) => !!x.error)
        if (error) {
          return { error }
        }

        const dataResponses = responses
          .flatMap((x) => x?.data)
          .flatMap((x) => x?.value)
          .filter(isNotNullOrUndefined)

        const [contacts, orgs] = partition(
          dataResponses,
          (x: unknown): x is IContact => !!(x as IContact)?.contactid
        ) as [IContact[], IOrganization[]]

        return {
          data: {
            contacts,
            orgs
          }
        }
      },
      providesTags: ['rdot360', 'contacts']
    })
  })
})

export const {
  useGetInteractionsByContactsQuery,
  useRdot360_createTaskMutation,
  useGetTaskCountsQuery,
  useGetMostRecentInteractionByContactsQuery,
  useGetActivitiesByContactsQuery,
  useLazyGetEmailAttachmentQuery,
  useLazyGetInteractionAttachmentQuery,
  useRdot360_createInteractionMutation,
  useRdot360_editInteractionMutation,
  useRdot360_interactionAttachmentMutation,
  useRdot360_updateKeyContactMutation,
  useRdot360_getCustomAccountGroupsQuery,
  useRdot360_deleteCustomAccountGroupMutation,
  useRdot360_createCustomAccountGroupMutation,
  useGetSystemUsersQuery,
  useRdot360_reassignTaskMutation,
  useRdot360_reassignGroupTaskMutation,
  useRdot360_editTaskMutation,
  useLazyGetEmailAttachmentsQuery,
  useGetAdvisorTeamQuery,
  useUpdateAdvisorTeamMutation,
  useUpdateAdvisorTeamForContactsMutation,
  useUpdateCagNameMutation,
  useUpdateCagAccountsMutation,
  useRdot360_createGroupTaskMutation,
  useGetTasksAndGroupTasksByContactsQuery,
  useRdot360_editGroupTaskMutation,
  useGetContactsAndOrgsByPartyIdsQuery
} = rdot360DynamicsApi

export const useDynamicsApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<DynamicsApiTagType>[]) =>
      dispatch(rdot360DynamicsApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
