import { css, useTheme } from '@emotion/react'
import { Searchbox } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/Searchbox'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { useDatahubApiUtil } from 'modules/Advisory/modules/Rdot360/store/datahub'
import { memo, useCallback, useMemo } from 'react'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import AccountToggle from '../../features/Performance/AccountToggle'
import IncomeReturnTypeToggle from '../../features/Performance/IncomeReturnTypeToggle'
import PerformanceDateRangeFilter from '../../features/Performance/PerformanceDateRangeFilter'
import PerformanceTypeFilter from '../../features/Performance/PerformanceTypeFilter'
import { usePerformanceState } from '../../features/Performance/store'
import { useRdot360PerformanceContext } from '../../store/rdot360Context/useRdot360PerformanceContext'
import PerformanceDetailTable from './PerformanceDetailTable'

export const getClasses = () => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px'
  }),
  headerContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignItems: 'flex-end'
  }),
  filterContainer: css({
    display: 'flex',
    flexDirection: 'row',
    columnGap: 16
  })
})

const PerformanceDetailView: React.FC = memo(() => {
  const { searchText, setSearchText } = usePerformanceState()
  const classes = useMemo(() => getClasses(), [])
  const theme = useTheme()
  const { invalidateTags } = useDatahubApiUtil()

  const invalidateCache = useCallback(
    () => invalidateTags(['performance']),
    [invalidateTags]
  )

  const { isGroupDataAvailable } = useRdot360PerformanceContext()

  return (
    <div css={classes.container}>
      <div css={classes.headerContainer}>
        <DetailsNavigator />
        <div css={classes.filterContainer}>
          <PerformanceDateRangeFilter />
          <div css={{ width: 170 }}>
            <PerformanceTypeFilter />
          </div>
          <div css={classes.filterContainer}>
            <IncomeReturnTypeToggle />
            {isGroupDataAvailable && <AccountToggle />}
          </div>
          <Searchbox searchText={searchText} onChange={setSearchText} />
          <div
            css={{
              width: 24,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Icon
              type="Refresh"
              color={theme.colors.extraBlue2}
              onClick={invalidateCache}
            />
          </div>
        </div>
      </div>

      <PerformanceDetailTable />
    </div>
  )
})

export default PerformanceDetailView
