import { IOdataRequest } from 'api/odata.types'
import axios, { CancelTokenSource } from 'axios'
import { keyBy } from 'lodash'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { AppState } from 'store'
import { getRockefellerApiOptions } from 'store/shared'
import { call, cancelled, select } from 'typed-redux-saga'
import { IListsFilter } from '../components/AlertsList/IListsFilter'
import { convertColumnTypeToFilterType } from '../features/OdataList/common/service'
import { createOdataListWithFacetsStore } from '../features/OdataList/store/odataListWithFacetsStore'
import { alertsColumns } from './alertsColumns'
import { getEnabledArchiveFilter } from './alertsSummary'
import { getAlerts } from './datahub'

export const defaultColumnsIds: string[] = alertsColumns.map((x) => x.name)

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

export const fetchAlert = function* (request: IOdataRequest) {
  const enabledArchiveFilter = yield* select(getEnabledArchiveFilter)
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)

  request.filters = request.filters ?? []
  request.filters.push(enabledArchiveFilter)

  try {
    return yield* call(getAlerts, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.alerts.alertsList

const uiFilters = keyBy(
  alertsColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false,
        options: column.options,
        minDate: column.minDate,
        maxDate: column.maxDate
      }

      return base
    }),
  ({ id }) => id
)

const selectedColumnIds = [...defaultColumnsIds].splice(0, 17)

const store = createOdataListWithFacetsStore({
  prefix: '@modules/@advisory/@modules/@alerts/@alertsList',
  getOdataResults: fetchAlert,
  initialState: {
    data: {},
    ui: {
      columns: alertsColumns,
      selectedColumns: selectedColumnIds,
      filters: uiFilters
    },
    facets: {}
  },
  rootSelector
})

export const { actions, selectors, reducer, sagas } = store
