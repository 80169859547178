import { IOdataListColumnDefinition } from 'features/OdataList/common/types'

export type ActivityColumnName =
  | 'Activity Date'
  | 'Account Number'
  | 'Advisor ID'
  | 'Amount'
  | 'Description'
  | 'Household Id'
  | 'Household Name'
  | 'Party Name'
  | 'Party Status'
  | 'Party Type'
  | 'Price'
  | 'Quantity'
  | 'Symbol / CUSIP'
  | 'Team Name'
  | 'Type'

export interface IActivityColumnDefinition extends IOdataListColumnDefinition {
  name: ActivityColumnName
}

export const ActivityColumnDefinitions: IActivityColumnDefinition[] = [
  {
    name: 'Activity Date',
    dataPath: 'tradeDate',
    type: 'date-only',
    width: 140,
    sortable: true,
    filterable: true,
    select: ['settleDate']
  },
  {
    name: 'Account Number',
    dataPath: 'account/custodyAccount',
    type: 'string',
    width: 175,
    sortable: true,
    select: [
      'account/accountId',
      'account/categoryCode',
      'account/custodyAccount',
      'account/nickname',
      'account/number',
      'account/registeredRep',
      'account/registrationTypeCode'
    ]
  },
  {
    name: 'Advisor ID',
    dataPath: 'account/registeredRep',
    type: 'string',
    width: 60,
    facetable: true,
    filterable: true,
    sortable: true
  },
  {
    name: 'Amount',
    dataPath: 'amount',
    type: 'number',
    width: 125,
    sortable: true
  },
  {
    name: 'Description',
    dataPath: 'tranDesc',
    type: 'string',
    width: 500,
    sortable: true,
    searchFields: ['tranDesc']
  },
  {
    name: 'Household Id',
    dataPath: 'household/householdId',
    type: 'string',
    width: 120,
    sortable: true
  },
  {
    name: 'Household Name',
    dataPath: 'household/householdName',
    type: 'string',
    width: 220,
    sortable: true
  },
  {
    name: 'Party Name',
    dataPath: 'client/partyName',
    type: 'string',
    width: 180,
    sortable: true
  },
  {
    name: 'Party Status',
    dataPath: 'client/partyStatus',
    type: 'string',
    width: 100,
    sortable: true
  },
  {
    name: 'Party Type',
    dataPath: 'client/partyType',
    type: 'string',
    width: 50,
    sortable: true
  },
  {
    name: 'Price',
    dataPath: 'price',
    type: 'number',
    width: 125,
    sortable: true
  },
  {
    name: 'Quantity',
    dataPath: 'quantity',
    type: 'number',
    width: 125,
    sortable: true
  },
  {
    name: 'Symbol / CUSIP',
    dataPath: 'symbol',
    type: 'string',
    width: 160,
    sortable: true,
    searchFields: ['cusip', 'symbol'],
    select: ['cusip', 'symbol']
  },
  {
    name: 'Team Name',
    dataPath: 'client/teamName',
    type: 'string',
    width: 180,
    sortable: true
  },
  {
    name: 'Type',
    dataPath: 'rcmCatDesc',
    type: 'string',
    width: 180,
    facetable: true,
    filterable: true,
    sortable: true
  }
]
