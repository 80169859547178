import { css } from '@emotion/react'
import { perfromanceDetailTableColumnNames } from './PerformanceTableColumns'

const commonCellStyles = {
  left: css({
    textAlign: 'left'
  }),
  right: css({
    textAlign: 'right'
  }),
  ellipsis: css({
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  })
}

export const cellStyles = {
  [perfromanceDetailTableColumnNames.group]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.inceptionDate]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.startValue]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.netFlows]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.investmentEarnings]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.endingValue]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.NetTWRMTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.NetTWRQTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.NetTWRYTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.NetTWRITD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.NetIrrMTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.NetIrrQTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.NetIrrYTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [perfromanceDetailTableColumnNames.NetIrrITD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ])
}
