import { datahubApi, DatahubApiReducerState } from './datahub'
import { dynamicsApi, DynamicsApiReducerState } from './dynamics'
import type { GraphBetaApiReducerState } from './graph_beta'
import { graph_beta } from './graph_beta'
import type { GraphV1ApiReducerState } from './graph_v1'
import { graph_v1 } from './graph_v1'
import { insightsApi, InsightsApiReducerState } from './insights'
import { rdotApi, RdotApiReducerState } from './rdot'
import { rdot360Api, Rdot360ApiReducerState } from './rdot360'

export type ApiState = GraphV1ApiReducerState &
  GraphBetaApiReducerState &
  DynamicsApiReducerState &
  DatahubApiReducerState &
  RdotApiReducerState &
  Rdot360ApiReducerState &
  InsightsApiReducerState

export const apiReducers = {
  [graph_v1.reducerPath]: graph_v1.reducer,
  [graph_beta.reducerPath]: graph_beta.reducer,
  [rdot360Api.reducerPath]: rdot360Api.reducer,
  [dynamicsApi.reducerPath]: dynamicsApi.reducer,
  [datahubApi.reducerPath]: datahubApi.reducer,
  [rdotApi.reducerPath]: rdotApi.reducer,
  [insightsApi.reducerPath]: insightsApi.reducer
}

export const apiMiddlewares = [
  graph_v1.middleware,
  graph_beta.middleware,
  rdot360Api.middleware,
  dynamicsApi.middleware,
  datahubApi.middleware,
  rdotApi.middleware,
  insightsApi.middleware
]
