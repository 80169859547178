import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const D365Container = lazy(() => import('./D365Container'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/d365/*',
    private: true,
    component: D365Container
  },
  name: 'D365'
}

export default moduleDefinition
