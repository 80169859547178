import {
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  ExpandedState,
  Row,
  SortingState,
  flexRender
} from '@tanstack/react-table'
import { PerformanceData } from 'api/performance.types'
import { format } from 'date-fns'
import { sum } from 'lodash'
import { ITableHeaderColumnSize } from 'modules/Advisory/modules/Rdot360/shared/ITableHeaderColumnSize'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { useDebounce } from 'shared/hooks/useDebounce'
import { useWidthObserver } from 'shared/hooks/useResizeObserver'
import { IndeterminateProgressIndicator } from '../../components/shared/ProgressIndicator/IndeterminateProgressIndicator'
import { usePerformanceState } from '../../features/Performance/store'
import {
  rdot360TableStyles,
  useRdot360ThemedTableStyles
} from '../../shared/tableStyles'
import { useRdot360PerformanceContext } from '../../store/rdot360Context/useRdot360PerformanceContext'
import getPerformanceTableColumns, {
  perfromanceDetailTableColumnNames
} from './PerformanceTableColumns'
import PerformanceTableGroupRow from './PerformanceTableGroupRow'
import PerformanceTableHeaderRow from './PerformanceTableHeaderRow'
import PerformanceTableHeaderSizesRow from './PerformanceTableHeaderSizesRow'
import PerformanceTableRow from './PerformanceTableRow'
import { cellStyles } from './styles'

const getAdjustedHeaderSizes = (
  sizes: ITableHeaderColumnSize[],
  tableWidth: number,
  depth = 0,
  firstColumnOffset = 9,
  lastColumnOffset = 9
) => {
  const totalSize = sum(sizes.map(({ width }) => width))
  const ratio = tableWidth ? tableWidth / totalSize : 1

  return sizes.map(({ width, id }, i) => {
    const isFirst = i === 0
    const isLast = i === sizes.length - 1
    const depthAdjustment =
      isFirst || isLast
        ? depth * (isFirst ? firstColumnOffset : lastColumnOffset)
        : 0

    return { id, width: width * ratio - depthAdjustment }
  })
}

const PerformanceDetailTable: FC = () => {
  const {
    performanceData,
    isFetching,
    selectedEntityType,
    selectedEntityTypeIsSingleAccount
  } = useRdot360PerformanceContext()

  const {
    searchText,
    setSearchText,
    assetType,
    dateRange,
    performanceType,
    defaultExpanded,
    setDefaultExpanded
  } = usePerformanceState()

  const debouncedSearchText = useDebounce(searchText, 100)

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: `${perfromanceDetailTableColumnNames.endingValue} ${dateRange} ${performanceType}`,
      desc: true
    }
  ])

  useEffect(() => {
    setSorting([
      {
        id: `${perfromanceDetailTableColumnNames.endingValue} ${dateRange} ${performanceType}`,
        desc: true
      }
    ])
  }, [dateRange, performanceType])

  const columns = useMemo(
    () =>
      getPerformanceTableColumns(
        debouncedSearchText,
        assetType,
        dateRange,
        performanceType,
        selectedEntityType
      ),
    [
      debouncedSearchText,
      assetType,
      dateRange,
      selectedEntityType,
      performanceType
    ]
  )
  const [expanded, setExpanded] = useState<ExpandedState>({})

  const globalFilter = useCallback(
    (row: Row<PerformanceData>, id: string, filterValue: string): boolean => {
      const searchString = (filterValue || '').toLowerCase()
      const {
        Name = '',
        groupName = '',
        accountsData = [],
        preferredNickname,
        CustodyAccount,
        registrationtype,
        registrationDesc
      } = row.original

      return (
        [
          Name,
          groupName,
          preferredNickname,
          CustodyAccount,
          registrationtype,
          registrationDesc
        ].some((value) => value?.toLowerCase().includes(searchString)) ||
        accountsData?.some(
          ({
            Name,
            groupName,
            preferredNickname,
            CustodyAccount,
            registrationtype,
            registrationDesc
          }: {
            [key: string]: string
          }) => {
            return [
              Name,
              groupName,
              preferredNickname,
              CustodyAccount,
              registrationtype,
              registrationDesc
            ].some((value) => value?.toLowerCase().includes(searchString))
          }
        )
      )
    },
    []
  )

  const table = useReactTable({
    data: performanceData,
    columns,
    state: {
      sorting,
      expanded,
      globalFilter: debouncedSearchText
    },
    globalFilterFn: globalFilter,
    autoResetExpanded: false,
    onExpandedChange: setExpanded,
    onGlobalFilterChange: setSearchText,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSubRows: (row) => row?.accountsData
  })

  const themedStyles = useRdot360ThemedTableStyles()
  const headers = table.getFlatHeaders()
  const rows = table.getPreExpandedRowModel().rows
  const tableRefContainer = useRef<HTMLDivElement>(null)
  const containerWidth = useWidthObserver(tableRefContainer)
  const tableWidth = Math.max(1450, containerWidth || 0)

  const getHeaderSizes = useCallback(
    (depth?: number) => {
      const sizes = getAdjustedHeaderSizes(
        headers.map((x) => ({ id: x.id || '', width: x.getSize() || 0 })),
        // -2 for the border around the table
        tableWidth - 2,
        depth
      )
      return sizes
    },
    [headers, tableWidth]
  )

  useEffect(() => {
    if (!isFetching && defaultExpanded) {
      setTimeout(() => {
        setExpanded(defaultExpanded)
        setDefaultExpanded(undefined)
      }, 100)
    }
  }, [defaultExpanded, isFetching, setDefaultExpanded, setExpanded])

  return (
    <div>
      <HorizontalScrollContainer>
        <div css={{ height: 32 }}>
          {!selectedEntityTypeIsSingleAccount && (
            <div css={{ marginBottom: 16 }}>
              <a
                onClick={(e) => {
                  table.toggleAllRowsExpanded()
                  e.preventDefault()
                }}
                href="#"
                css={{
                  color: '#4C9DA8',
                  cursor: 'pointer'
                }}
              >
                {table.getIsAllRowsExpanded() ? 'Collapse All' : 'Expand All'}
              </a>
            </div>
          )}
        </div>
        {isFetching && <IndeterminateProgressIndicator />}
        <div
          css={[
            rdot360TableStyles.headerContainer,
            themedStyles.headerContainer,
            {
              border: 'none'
            }
          ]}
        >
          <table
            css={[rdot360TableStyles.table, rdot360TableStyles.tablePartition]}
          >
            <thead css={[{ verticalAlign: 'top' }]}>
              <PerformanceTableHeaderSizesRow sizes={getHeaderSizes()} />
              <PerformanceTableHeaderRow headers={headers} />
            </thead>
          </table>
        </div>
        {selectedEntityTypeIsSingleAccount && (
          <>
            <table
              css={[
                rdot360TableStyles.table,
                rdot360TableStyles.tablePartition
              ]}
            >
              <tbody>
                <PerformanceTableHeaderSizesRow sizes={getHeaderSizes()} />
                {rows.map((row) => (
                  <PerformanceTableRow key={row.id} row={row} />
                ))}
              </tbody>
              <tfoot css={{ position: 'sticky', bottom: 0 }}>
                <tr
                  css={[
                    rdot360TableStyles.bodyRow,
                    rdot360TableStyles.l2GroupRow,
                    themedStyles.totalRow
                  ]}
                >
                  {headers.map((header) => (
                    <td key={header.id} css={[cellStyles[header.column.id]]}>
                      {header.column.columnDef.footer
                        ? flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )
                        : null}
                    </td>
                  ))}
                </tr>
              </tfoot>
            </table>
          </>
        )}

        {!selectedEntityTypeIsSingleAccount &&
          rows.map((row) => {
            const subRows = row.subRows
            return (
              <table
                key={row.id}
                css={[
                  rdot360TableStyles.table,
                  { padding: 1, marginTop: 4, border: 'solid 1px #E6E6E6' }
                ]}
              >
                <tbody css={rdot360TableStyles.tablePartition}>
                  <PerformanceTableHeaderSizesRow sizes={getHeaderSizes()} />
                  <PerformanceTableGroupRow row={row} />
                  {row.getIsExpanded() &&
                    subRows.map((subRow) => (
                      <PerformanceTableRow key={subRow.id} row={subRow} />
                    ))}
                </tbody>
              </table>
            )
          })}
      </HorizontalScrollContainer>
      <div css={{ fontSize: 12, display: 'flex', columnGap: 4 }}>
        Note: Rockefeller accounts only
        {performanceData?.[0]?.LastUpdatedAt && (
          <div>
            | Values are as of{' '}
            {format(
              parseDateISOStringInLocalTimezone(
                performanceData?.[0]?.LastUpdatedAt
              ),
              'yyyy-MM-dd'
            )}
          </div>
        )}
      </div>
      <div css={{ fontSize: 10 }}>
        The information presented is based on groups/accounts imported from
        external sources and are not filtered based upon the selection in the
        account selector. Calculations and/or values may be different than other
        areas in the Client Dashboard or custodial statements.
      </div>
      <div css={{ fontSize: 10 }}>
        Partial period performance is marked with an asterisk (*)
      </div>
    </div>
  )
}

export default PerformanceDetailTable
