import { format } from 'date-fns'
import {
  ITrustDiscretionaryDistribution,
  ITrustCommitteeSignature,
  ITrustDiscretionaryDistributionEditInfoResponse,
  ITrustDistributionApprovers
} from '../api/types'
import {
  ITrustDistribution,
  IApprover,
  IValidationObject
} from '../store/types'

export const mapDistributionInfoToTrustDistribution = (
  distributionInfo?: ITrustDiscretionaryDistribution,
  distributionId?: string
): ITrustDistribution => {
  const distribution: ITrustDistribution = {
    DistributionID: distributionId ? distributionId : '',
    EntityNumber: distributionInfo?.entityNumber
      ? distributionInfo?.entityNumber
      : '',
    RequestorEmail: '',
    RequestDate: format(new Date(), 'MM/dd/yyyy'),
    ApprovedText: `${
      distributionInfo?.trustee ? distributionInfo?.trustee : ''
    }, Trustee, Through Its Trust Committee`,
    TrustTitle: distributionInfo?.legalName1
      ? distributionInfo?.legalName1
      : '',
    Grantor: distributionInfo?.grantors ? distributionInfo?.grantors : '',
    Trustees: distributionInfo?.trustee ? distributionInfo?.trustee : '',
    TrustCompanyName: distributionInfo?.trustCompanyName
      ? distributionInfo?.trustCompanyName
      : '',
    Termination: distributionInfo?.remainderProvisions
      ? distributionInfo?.remainderProvisions
      : '',
    TrusteeAction: `The Trustee approves the recommendation set forth above.`,
    DiscretionaryDistProvision: distributionInfo?.distributionProvisions
      ? distributionInfo?.distributionProvisions
      : '',
    PrimaryBeneficiaries: distributionInfo?.beneficiaryName
      ? distributionInfo?.beneficiaryName
      : '',
    CurrentValuation: distributionInfo?.currentValuation
      ? distributionInfo?.currentValuation
      : '',
    EstdAnnualNetIncome: distributionInfo?.estimatedAnnualNetIncome
      ? distributionInfo?.estimatedAnnualNetIncome
      : '',
    DistributionAmount: undefined,
    RequestedDistribution: '',
    Recommendation: '',
    Approvers: mapApprovers(distributionInfo?.trustCommitteeSignature)
  }
  return distribution
}

const mapApprovers = (
  trustSignatures: ITrustCommitteeSignature[] | undefined
): IApprover[] | undefined => {
  let approvers: IApprover[] | undefined
  if (trustSignatures && trustSignatures.length > 0) {
    approvers = trustSignatures.map(
      (appr) =>
        ({
          EmailAddress: appr?.emailAddress,
          PhoneNo: appr?.phoneNo,
          FirstName: appr?.firstName,
          MiddleName: appr?.middleName,
          LastName: appr?.lastName,
          FullName: appr?.fullName,
          IndInclude: appr?.emailAddress ? 'Y' : 'N',
          SignatureOrder: appr?.signatureOrder,
          IsTrustCompanyTrustee: appr?.isTrustCompanyTrustee
        } as IApprover)
    )
  }

  return approvers
}

export const getCommaSeparatedTrusteeApprovers = (
  approvers?: IApprover[]
): string => {
  let commaSepratedApprvers = ''
  if (approvers && approvers.length > 0) {
    commaSepratedApprvers = approvers
      .filter((appr) => appr.IsTrustCompanyTrustee === 'Y' && appr.FullName)
      .map((appr) => appr.FullName)
      .join(', ')
  }
  return commaSepratedApprvers
}

export const toUTF8Array = (str: string): number[] => {
  const utf8 = []
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i)
    if (charcode < 0x80) {
      utf8.push(charcode)
    } else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f))
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(
        0xe0 | (charcode >> 12),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      )
    }
    // surrogate pair
    else {
      i++
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charcode =
        0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff))
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      )
    }
  }
  return utf8
}

export const onFormatDate = (date?: Date | undefined): string => {
  return date ? format(date, 'MM/dd/yyyy') : ''
}

export const mapDistributionEditInfoToTrustDistrbution = (
  distribution?: ITrustDiscretionaryDistributionEditInfoResponse
) => {
  if (distribution) {
    return {
      DistributionID: distribution.distributionID
        ? distribution.distributionID
        : '',
      EntityNumber: distribution.entityNumber,
      RequestorEmail: distribution.requestorEmail,
      ApprovedText: distribution.approvedText,
      Approvers: mapApproversForEdit(distribution?.approvers),
      CurrentValuation: ToFixedAmount(distribution.currentValuation),
      DiscretionaryDistProvision: distribution.discretionaryDistProvision,
      DistributionAmount: distribution.distributionAmount,
      EstdAnnualNetIncome: ToFixedAmount(distribution.estdAnnualNetIncome),
      Grantor: distribution.grantor,
      PrimaryBeneficiaries: distribution.primaryBeneficiaries,
      Recommendation: distribution.recommendation,
      RequestDate: distribution.requestDate,
      RequestedDistribution: distribution.requestedDistribution,
      SkippingTaxableEvent: distribution.skippingTaxableEvent,
      Termination: distribution.termination,
      TrustCompanyName: distribution.trustCompanyName,
      TrustTitle: distribution.trustTitle,
      TrusteeAction: distribution.trusteeAction,
      Trustees: distribution.trustees
    } as ITrustDistribution
  } else {
    return mapDistributionInfoToTrustDistribution()
  }
}
export const formatPhoneNumber = (value: string | undefined): string => {
  if (!value) {
    return ''
  }

  const isInternational = value.startsWith('+')
  const currentValue = value.replace(/[^\d]/g, '')
  if (currentValue.length === 10 && !isInternational) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`
  }

  if (isInternational && currentValue.length > 10) {
    return `+${currentValue.slice(
      0,
      currentValue.length - 10
    )} (${currentValue.slice(
      currentValue.length - 10,
      currentValue.length - 7
    )}) ${currentValue.slice(
      currentValue.length - 7,
      currentValue.length - 4
    )}-${currentValue.slice(currentValue.length - 4, currentValue.length)}`
  }

  return isInternational ? `+${currentValue}` : currentValue
}

export const clearPhoneNumberFormat = (
  distribution: ITrustDistribution | undefined
): ITrustDistribution | undefined => {
  if (distribution?.Approvers) {
    distribution?.Approvers?.forEach(
      (app) => (app.PhoneNo = app.PhoneNo?.replace(/[^\d] + /g, ''))
    )
  }
  return distribution
}

export const validateApprovers = (
  approvers?: IApprover[]
): IValidationObject => {
  const hasAppError = approvers
    ? approvers.filter(
        (appr) =>
          appr.IsTrustCompanyTrustee === 'Y' &&
          appr.IndInclude === 'Y' &&
          !appr.PhoneNo
      ).length
      ? true
      : false
    : false
  const hasAddAppError = approvers
    ? approvers.filter(
        (appr) =>
          appr.IsTrustCompanyTrustee === 'N' &&
          appr.IndInclude === 'Y' &&
          !appr.PhoneNo
      ).length
      ? true
      : false
    : false
  const errorObj = {
    hasErrors: hasAppError || hasAddAppError,
    approverPhError: hasAppError,
    additionalAppPhError: hasAddAppError
  }
  return errorObj
}

export const mapApproversForEdit = (
  approvers?: ITrustDistributionApprovers[]
): IApprover[] | undefined => {
  let appr: IApprover[] | undefined
  if (approvers && approvers.length > 0) {
    appr = approvers.map(
      (a) =>
        ({
          EmailAddress: a.emailAddress,
          PhoneNo: a.phoneNo,
          FirstName: a.firstName,
          FullName: a.fullName,
          IsTrustCompanyTrustee: a.isTrustCompanyTrustee,
          LastName: a.lastName,
          MiddleName: a.middleName,
          SignatureOrder: a.signatureOrder,
          IndInclude: a.emailAddress ? a.indInclude : 'N'
        } as IApprover)
    )
  }

  return appr
}

export const ToFixedAmount = (amount?: string) => {
  let res = ''
  if (amount) {
    res = parseFloat(amount).toFixed(2)
  }
  return res
}

export const QueryStringParam = (search: string, parametername: string) => {
  parametername = parametername.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  const regexS = '[\\?&]' + parametername + '=([^&#]*)'
  const regex = new RegExp(regexS)
  const results = regex.exec(search || '')
  if (results == null) {
    return ''
  } else {
    return decodeURIComponent(results[1].replace(/\+/g, ' '))
  }
}
