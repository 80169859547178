import { Dropdown, IDropdownProps, IDropdownStyles } from '@fluentui/react'
import { FC, useMemo } from 'react'

export type ICustomDropdownProps = {
  customColor?: string
  height?: number
}

const DropdownWrapper: FC<IDropdownProps & ICustomDropdownProps> = ({
  ...props
}) => {
  const dropdownStyles: Partial<IDropdownStyles> = useMemo(() => {
    const { customColor, height } = props
    return {
      dropdown: {
        background: customColor ? customColor : '#FFFFFF',
        border: '1px solid #AFB1B6',
        borderRadius: 4,
        padding: '0px 12px',
        color: '#000000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: height ? `${height}px` : 34,
        columnGap: '10px',
        ':focus::after': {
          border: 'none'
        },
        width: '100%'
      },
      dropdownItemDisabled: {
        pointerEvents: 'none',
        cursor: 'not-allowed'
      },
      dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
      dropdownItem: {
        cursor: 'pointer',
        padding: '0px 12px',
        width: '100%',
        border: 'none',
        background: customColor ? customColor : '#FFFFFF',
        ':hover': {
          background: '#eaeaea'
        }
      },
      dropdownItemSelected: {
        backgroundColor: '#CBE0F0',
        padding: '0px 12px'
      },
      caretDownWrapper: {
        position: 'initial'
      },
      title: {
        background: customColor ? customColor : 'transparent',
        border: 'none',
        padding: 'none',
        lineHeight: 32,
        textAlign: 'left'
      }
    }
  }, [props])

  return <Dropdown {...props} styles={dropdownStyles} />
}

export default DropdownWrapper
