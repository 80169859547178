import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PanelType,
  PrimaryButton,
  Stack
} from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { INfsProfile } from 'api/datahub'
import { IRockDigitalInvitationRequest } from 'api/mail'
import { usePushNotification } from 'features/Notifications'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ErrorComponent } from 'shared/components/Error'
import { LoadingComponent } from 'shared/components/Loading'
import { useFetchNfsProfileByWealthscapeIdQuery } from 'store/api/datahub'
import { getRdotName, getRdotUsername } from 'store/user/selectors'
import {
  useClientInvite_inviteToRockDigitalMutation,
  useClientInvite_resendRockDigitalInvitationMutation
} from './api/datahub'
import { ClientInviteForm } from './ClientInviteForm'
import { useClientInvitePanel } from './store/clientInvitePanel'

export interface IClientInviteFormFields {
  ssn?: string
  phones?: NfsPhone[]
  email?: string
  cc?: string
  from?: string
  invitedBy?: string
  firstName?: string
  lastName?: string
  invitee?: string
  onBehalfOf?: string
}
export interface IClientInviteForm {
  invite: IClientInviteFormFields
}
export interface NfsPhone {
  Phone?: string
  telType?: string
  smscapable?: string
}

const getInvite = (
  cc: string,
  invitedBy: string,
  profile?: INfsProfile
): IClientInviteFormFields => ({
  cc,
  from: 'RCM-Onboarding@rockco.com',
  invitedBy,
  email: profile?.loginid || profile?.emailprimary || '',
  firstName: profile?.firstname || '',
  lastName: profile?.lastname || '',
  ssn: profile?.ssn?.slice(-4) || '',
  phones: JSON.parse(profile?.phones || '[]'),
  invitee: profile?.fullname || ''
})

const thirdPartyRoles = ['3rdParty', '3rdPartyMM']

export const getIsThirdParty = (role?: string) =>
  !!role && thirdPartyRoles?.includes(role)

export const ClientInvitePanel: React.FC = () => {
  const {
    close,
    isOpen,
    error: panelError,
    panelType,
    setError
  } = useClientInvitePanel()
  const currentUsername = useSelector(getRdotUsername)
  const currentUser = useSelector(getRdotName)

  const { selectedWealthscapeId } = useClientInvitePanel()

  const {
    currentData: data,
    isFetching: isFetchLoading,
    error: fetchError
  } = useFetchNfsProfileByWealthscapeIdQuery(selectedWealthscapeId || skipToken)

  const invite = useMemo(
    () =>
      getInvite(
        currentUsername || '',
        currentUser || '',
        isFetchLoading ? undefined : data
      ),
    [currentUser, currentUsername, data, isFetchLoading]
  )

  const methods = useForm<IClientInviteForm>()

  const { handleSubmit, reset } = methods

  const { pushNotification } = usePushNotification()

  const onDismiss = useCallback(() => {
    close()
  }, [close])

  useEffect(() => {
    reset({ invite })
  }, [invite, reset])

  const [showValidationError, setShowValidationError] = useState(false)
  const onError = useCallback(() => {
    setShowValidationError(true)
  }, [])
  useEffect(() => {
    if (!isOpen) {
      return
    }
    setShowValidationError(false)
  }, [isOpen])
  const [inviteProfile, inviteResult] =
    useClientInvite_inviteToRockDigitalMutation()
  const [resendInvite, resendResult] =
    useClientInvite_resendRockDigitalInvitationMutation()
  const { isLoading: isResendLoading } = resendResult
  const { isLoading: isInviteLoading } = inviteResult
  const isThirdParty = useMemo(() => getIsThirdParty(data?.role), [data?.role])

  const onSubmit = useCallback(
    async (form?: IClientInviteForm) => {
      if (!form || !data?.id) {
        return
      }

      const { email, cc, from, invitedBy, invitee, onBehalfOf } = form.invite
      const invitation: IRockDigitalInvitationRequest = {
        profileid: data?.id.toString(),
        ccRecipients: cc,
        client: {
          fullname: isThirdParty ? onBehalfOf : invitee,
          pronoun: 'their'
        },
        thirdparty: isThirdParty ? { fullname: invitee } : undefined,
        requestor: { email: from, fullname: invitedBy }
      }
      try {
        if (panelType === 'invite' || panelType === 'changeEmail') {
          await inviteProfile({
            id: data?.id.toString(),
            invite: { invitation, email: email || '' }
          }).unwrap()
        }
        if (panelType === 'resend') {
          await resendInvite({
            id: data?.id.toString(),
            invite: { invitation, email: email || '' }
          }).unwrap()
        }
      } catch (e) {
        setError(e as Error)
        return
      }

      onDismiss()
      const getMessage = () => {
        switch (panelType) {
          case 'invite':
            return 'Successfully Invited'
          case 'changeEmail':
            return 'Successfully Changed Email'
          default:
            return 'Successfully Reinvited'
        }
      }
      pushNotification({
        message: getMessage(),
        type: MessageBarType.success
      })
    },
    [
      data?.id,
      inviteProfile,
      isThirdParty,
      onDismiss,
      panelType,
      pushNotification,
      resendInvite,
      setError
    ]
  )

  const error = useMemo(
    () => (fetchError as Error | undefined) || panelError,
    [fetchError, panelError]
  )

  const isAlreadyInvited = useMemo(
    () => panelType === 'invite' && !!data?.loginid && !error,
    [data?.loginid, error, panelType]
  )

  const onRenderFooterContent = useCallback(() => {
    return (
      <>
        {(isFetchLoading || isResendLoading || isInviteLoading) && (
          <Overlay styles={{ root: { zIndex: 1 } }} />
        )}
        <Stack tokens={{ childrenGap: 10 }}>
          {error && (
            <MessageBar messageBarType={MessageBarType.error}>
              {error?.message}
            </MessageBar>
          )}
          {isAlreadyInvited && (
            <MessageBar messageBarType={MessageBarType.error}>
              The profile was already invited
            </MessageBar>
          )}
          {showValidationError && (
            <ErrorComponent errorMessage="Please fill in all required fields." />
          )}
          {panelType === 'reinvite' && (
            <MessageBar messageBarType={MessageBarType.severeWarning}>
              Are you sure you want to delete and reinvite client. All
              Collaboration folder contents will be deleted.
            </MessageBar>
          )}
          {!isFetchLoading && !data?.emailprimary && (
            <MessageBar messageBarType={MessageBarType.warning}>
              An email for this client is not configured in Wealthscape
            </MessageBar>
          )}
          {!isFetchLoading && !invite?.phones?.length && (
            <MessageBar messageBarType={MessageBarType.warning}>
              A phone number for this client is not configured in Wealthscape
            </MessageBar>
          )}
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 10 }}
            horizontalAlign="space-between"
          >
            <Stack
              horizontal={true}
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
            >
              <PrimaryButton
                type="submit"
                form="hurdleForm"
                disabled={
                  isAlreadyInvited ||
                  !data ||
                  isFetchLoading ||
                  !invite?.phones?.length
                }
              >
                Submit
              </PrimaryButton>

              <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
            </Stack>
          </Stack>
        </Stack>
      </>
    )
  }, [
    isFetchLoading,
    isResendLoading,
    isInviteLoading,
    error,
    isAlreadyInvited,
    showValidationError,
    panelType,
    data,
    invite?.phones?.length,
    onDismiss
  ])

  const headerText = useMemo(() => {
    switch (panelType) {
      case 'invite':
        return 'Client Invite'
      case 'changeEmail':
        return 'Change Email'
      case 'resend':
        return 'Resend Invitation'
    }
  }, [panelType])

  return (
    <Panel
      headerText={headerText}
      isOpen={isOpen}
      onDismiss={onDismiss}
      isLightDismiss={true}
      closeButtonAriaLabel="Close"
      type={PanelType.custom}
      customWidth="400px"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
      styles={{ content: { flexGrow: 1 } }}
    >
      <>
        {(isFetchLoading || isResendLoading || isInviteLoading) && (
          <Overlay styles={{ root: { zIndex: 1 } }}>
            <LoadingComponent />
          </Overlay>
        )}
        <FormProvider {...methods}>
          <form id="hurdleForm" onSubmit={handleSubmit(onSubmit, onError)}>
            <ClientInviteForm mode={panelType} isThirdParty={isThirdParty} />
          </form>
        </FormProvider>
      </>
    </Panel>
  )
}
