import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { IContact, IProfile } from 'api/dynamics'
import { keyBy, orderBy } from 'lodash'
import { useMemo } from 'react'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import { useGetIndividualsFromHouseholdQuery } from '../datahub'
import { useGetContactsAndOrgsByPartyIdsQuery } from '../dynamics'
import { useRdot360Context } from './rdot360Context'
import { useRdot360AccountContext } from './useRdot360AccountContext'

const stripNonDigits = (str: string) => str.replace(/\D/g, '')

export const useRdot360ClientContext = () => {
  const { selectedHouseholdId } = useRdot360Context()
  const { nonIndividualPartyIds, individualPartyIds } =
    useRdot360AccountContext()
  const skip = !selectedHouseholdId

  const {
    data: clientsData,
    isError: isClientsError,
    isFetching: isClientsFetching
  } = useGetIndividualsFromHouseholdQuery(
    skip ? skipToken : selectedHouseholdId
  )
  const clients = skip || isClientsError ? undefined : clientsData

  const clientLookup = useMemo(() => keyBy(clients, 'LegalEntityID'), [clients])

  const emails = useMemo(
    () =>
      clients
        ?.map((client) => client.contactdetails?.emailid)
        .filter(isNotNullOrUndefined)
        .sort(),
    [clients]
  )

  const skipCrmApi =
    !individualPartyIds?.length && !nonIndividualPartyIds?.length
  const {
    data,
    isError: isProfilesError,
    isFetching: isProfilesFetching,
    error: profilesError
  } = useGetContactsAndOrgsByPartyIdsQuery(
    skipCrmApi
      ? skipToken
      : {
          individialPartyIds: individualPartyIds || [],
          partyIds: nonIndividualPartyIds || []
        }
  )

  const contacts = useMemo(
    () =>
      skipCrmApi || isProfilesError
        ? []
        : orderBy(data?.contacts, (x) => x.rpm_headofhousehold, 'desc'),
    [data?.contacts, isProfilesError, skipCrmApi]
  )
  const contactSSNs = useMemo(
    () =>
      contacts
        ?.map((x) => stripNonDigits(x?.rcm_secureid || ''))
        ?.filter(isNotNullOrEmpty) || [],
    [contacts]
  )

  const headOfHousehold = useMemo(
    () =>
      contacts?.[0]?.rpm_headofhousehold
        ? clientLookup[contacts?.[0]?.rcm_rockcodbpartyid || '']
        : undefined,
    [clientLookup, contacts]
  )

  const organizations = useMemo(
    () => (skipCrmApi || isProfilesError ? [] : data?.orgs),
    [data?.orgs, isProfilesError, skipCrmApi]
  )

  const profiles = useMemo(() => {
    return [
      ...(contacts?.map((contact) => ({
        name: getContactFullName(contact),
        keyContact: contact?.rpm_headofhousehold,
        type: 'contact',
        id: contact?.contactid,
        contact
      })) || []),
      ...(organizations?.map((org) => ({
        name: org?.name,
        keyContact: false,
        type: 'org',
        id: org?.accountid,
        org
      })) || [])
    ] as IProfile[]
  }, [contacts, organizations])

  const filteredProfiles = useMemo(
    () =>
      profiles?.filter(
        (x) => !contactSSNs?.includes(stripNonDigits(x?.org?.rcm_taxid || ''))
      ),
    [contactSSNs, profiles]
  )

  const contactsIds = useMemo(
    () =>
      contacts
        ?.map((x) => x?.contactid)
        ?.filter(isNotNullOrUndefined)
        ?.sort() || [],
    [contacts]
  )
  const profileIds = useMemo(
    () =>
      profiles
        ?.map((x) => x?.id)
        ?.filter(isNotNullOrUndefined)
        ?.sort() || [],
    [profiles]
  )

  const profilesLookup = useMemo(() => keyBy(profiles, 'id'), [profiles])

  const profilesExist = useMemo(() => profiles.length, [profiles])

  return {
    clients,
    isClientsFetching,
    emails,
    profiles,
    contacts,
    isProfilesFetching,
    contactsIds,
    profilesError,
    clientLookup,
    headOfHousehold,
    profilesExist,
    organizations,
    profilesLookup,
    profileIds,
    filteredProfiles
  }
}

const getContactFullName = (contact?: IContact) => {
  const nameMasked = [
    contact?.firstname,
    contact?.middlename,
    contact?.lastname,
    contact?.suffix
  ]
    .filter(isNotNullOrEmpty)
    .join(' ')

  return nameMasked
}
